import './DetalleRen.css'

import { CloudDownloadOutlined, EyeFilled } from '@ant-design/icons'
import { Input, Modal, Select, Spin, Table, Tabs, Tooltip } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { renGetNumUrl, renRecuperarUrl } from '../../../api/apiUrl'
import { msgType } from '../../../types/msgType'
import { configType } from '../../../types/configType'
import { notifyType } from '../../../types/notifyType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate, formatDigits, formatTime } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../Msg/NotifyRed'
import { NotifyGreen } from '../../Msg/NotifyGreen'
import { NotifyYellow } from '../../Msg/NotifyYellow'
import { DetalleDoc } from './DetalleDoc'
import { DetalleOpe } from './DetalleOpe'

var jszip = require('jszip')

export const DetalleRen = forwardRef((props, ref) => {
  const { usuario, selReceptor, selRendicion: vwRendicion, dbRendicion, aprRevertir, setObservacion, validar } = props

  const { axiosTnaGet } = useAxiosTna()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const detOpeRef = useRef()
  const [loadPage, setLoadPage] = useState(true)
  const [loadGetRen, setLoadGetRen] = useState(false)
  const [camposRen, setCamposRen] = useState([])
  const [camposFlu, setCamposFlu] = useState([])
  const [camposApr, setCamposApr] = useState([])
  const [camposRep, setCamposRep] = useState([])
  const [camposAteRep, setCamposAteRep] = useState([])
  const [camposDev, setCamposDev] = useState([])
  const [ccosto, setCcosto] = useState([])
  const [flujos, setFlujos] = useState([])
  const [aprobadores, setAprobadores] = useState([])
  const [reposiciones, setReposiciones] = useState([])
  const [atencionesRep, setAtencionesRep] = useState([])
  const [devoluciones, setDevoluciones] = useState([])
  const [adjuntos, setAdjuntos] = useState([])
  const [estados, setEstados] = useState([])
  const [selRendicion, setSelRendicion] = useState(null)
  const [selFlujo, setSelFlujo] = useState(null)
  const [selAprobador, setSelAprobador] = useState(null)
  const [selReposicion, setSelReposicion] = useState(null)
  const [selAtencionRep, setSelAtencionRep] = useState(null)
  const [selDevolucion, setSelDevolucion] = useState(null)
  const [dbOperacion, setDbOperacion] = useState(null)
  const [currentAprob, setCurrentAprob] = useState(false)
  const [currentLineFI, setCurrentLineFI] = useState(null)
  const [activeTabKey, setActiveTabKey] = useState("1")
  const [openModalOpe, setOpenModalOpe] = useState(false)

  useEffect(() => {
    setLoadPage(loadGetRen)
  }, [loadGetRen])

  useEffect(() => {
    setSelRendicion(vwRendicion)
  }, [vwRendicion])

  useEffect(() => {
    setDbOperacion(selAtencionRep ? { id: selAtencionRep.operacionID } : null)
  }, [selAtencionRep])

  useEffect(() => {
    setDbOperacion(selDevolucion && selDevolucion.atencion ? { id: selDevolucion.atencion.operacionID } : null)
  }, [selDevolucion])

  useEffect(() => {
    setObservacion && setObservacion(null)
  }, [currentAprob, setObservacion])

  useEffect(() => {
    const getCampo = (rec, ren) => {
      const campos = [
        { label: 'Receptor', value: `${rec.ruc} - ${rec.name}` },
        { label: 'Grupo', value: ren.grupo },
        { label: 'Nº de rendición', value: ren.numRD },
        { label: 'Fecha de rendición', value: formatDate(ren.fechaRen) },
        { label: 'Tipo de rendición', value: `${ren.tipo}` + (ren.origen ? ` ( ${ren.origen.numSO} )` : ''), detail: (ren.reponer ? 'reponer' : '') },
        { label: 'Tipo de reembolso', value: !ren.origen && ren.tipo !== ren.rend ? `${ren.rend}` : null },
        { label: 'Área', value: ren.area },
        { label: 'Estado', value: ren.estado },
        { label: 'Fecha de registro', value: formatTime(ren.fechaReg) },
        { label: 'Solicitante', value: getUsuario(ren.solicitante) },
        { label: 'Rindente', value: getUsuario(ren.rindente) },
        { label: 'Total rendido', value: `${formatAmount(ren.totRen)} ${ren.monedaISO}` + (ren.tipoCambio ? ` (TC: ${formatDigits(ren.tipoCambio)})` : '') },
        { label: 'Total reposición', value: ren.totRep !== 0.0 ? `${formatAmount(ren.totRep)}` : null },
        { label: 'Total devuelto', value: ren.totDev !== 0.0 ? `${formatAmount(ren.totDev)}` : null },
        { label: 'Ajuste redondeo', value: ren.mtoRdo !== 0.0 ? `${formatAmount(ren.mtoRdo)}` : null },
        { label: 'Saldo', value: !ren.origen && ren.isValidated ? `${formatAmount(ren.saldo)}` : null },
        { label: 'Motivo', value: ren.motivo ? ren.motivo : null },
        { label: 'Observación', value: ren.observ ? ren.observ : null },
        { label: 'Fecha contable', value: ren.fechaRen !== ren.fechaCtb ? formatDate(ren.fechaCtb) : null },
        { label: 'Elemento de gasto', value: ren.egastoCode ? `${ren.egastoCode} - ${ren.egasto}` : null },
      ]
      return campos.filter(p => p.value)
    }

    setCamposRen(selReceptor && selRendicion ? getCampo(selReceptor, selRendicion) : [])
  }, [selReceptor, selRendicion])

  useEffect(() => {
    const getCampo = (flu) => {
      const campos = [
        { label: 'Monto rendido', value: `${formatAmount(flu.monto)} ${flu.monedaISO}` + (flu.tipoCambio ? ` (TC: ${formatDigits(flu.tipoCambio)})` : '') },
        { label: 'Revisor', value: flu.revisado ? (flu.revisor ?? 'SI') : null },
        { label: 'Fecha de registro', value: formatTime(flu.fechaReg) },
        { label: 'Estado', value: flu.aprobado ? 'APROBADO' : (flu.aprobadores.find(p => p.observ) ? 'RECHAZADO' : 'PENDIENTE') },
      ]
      return campos.filter(p => p.value)
    }

    setCamposFlu(selFlujo ? getCampo(selFlujo) : [])
  }, [selFlujo])

  useEffect(() => {
    const getCampo = (apr) => {
      const campos = [
        { label: 'Aprobador', value: getUsuario(apr.aprobador) },
        { label: 'Decisión', value: apr.aprobado ? 'APROBADO' : (apr.fechaAprob ? 'RECHAZADO' : 'POR EVALUAR') },
        { label: 'Observación', value: apr.observ },
        { label: 'Fecha de aprobación', value: apr.aprobado ? formatTime(apr.fechaAprob) : null },
        { label: 'Fecha de rechazo', value: !apr.aprobado && apr.fechaAprob ? formatTime(apr.fechaAprob) : null },
        { label: 'Reemplazo por', value: apr.reemplazo ? getUsuario(apr.reemplazo) : null },
      ]
      return campos.filter(p => p.value)
    }

    setCamposApr(selAprobador ? getCampo(selAprobador) : [])
  }, [selAprobador])

  useEffect(() => {
    const getCampo = (rep) => {
      const campos = [
        { label: 'Monto reposición', value: `${formatAmount(rep.monto)} ${rep.monedaISO}` },
        { label: 'Origen', value: rep.renDev ? `Rendición ${rep.lineRenDev + 1}` : `Devolución ${rep.lineRenDev + 1}` },
        { label: 'Fecha de registro', value: formatTime(rep.fechaReg) },
        { label: 'Estado', value: rep.atendido ? ('ATENDIDO' + (rep.atenDif > 0.0 ? ' ( exceso )' : (rep.atenDif < 0.0 ? ' ( parcial )' : ''))) : 'PENDIENTE' },
        { label: 'Monto atendido', value: rep.atenciones && rep.atenciones.length > 1 && rep.atendido && rep.atenDif !== 0.0 ? `${formatAmount(rep.monto + rep.atenDif)} ${rep.monedaISO}` : null },
      ]
      return campos.filter(p => p.value)
    }

    setCamposRep(selReposicion ? getCampo(selReposicion) : [])
  }, [selReposicion])

  useEffect(() => {
    const getCampo = (ate) => {
      const campos = [
        { label: 'Monto atendido', value: `${formatAmount(ate.monto)} ${ate.monedaISO}` },
        { label: 'Fecha de atención', value: formatTime(ate.fechaAten) },
        { label: 'Tesorero', value: getUsuario(ate.tesorero) },
      ]
      return campos.filter(p => p.value)
    }

    setCamposAteRep(selAtencionRep ? getCampo(selAtencionRep) : [])
  }, [selAtencionRep])

  useEffect(() => {
    const getCampo = (dev) => {
      const campos = [
        { label: 'Nº de cuenta', value: `${dev.banco} ; ${dev.numeroCta}` },
        { label: 'Tipo de pago', value: dev.tipoPago },
        { label: 'Nº y fecha de transacción', value: `${dev.numTran} ; ${formatDate(dev.fechaTran)}` },
        { label: 'Monto devuelto', value: `${formatAmount(dev.monto)} ${dev.monedaISO}` },
        { label: 'Fecha de registro', value: formatTime(dev.fechaReg) },
      ]
      return campos.filter(p => p.value)
    }

    setCamposDev(selDevolucion ? getCampo(selDevolucion) : [])
  }, [selDevolucion])

  useEffect(() => {
    if (selRendicion) {
      if (selRendicion.ccosto)
        setCcosto(selRendicion.ccosto.map((cco, index) => ({
          ...cco,
          key: index
        })))
      if (selRendicion.faprob) {
        const aflu = selRendicion.faprob.map((flu, index) => ({
          ...flu,
          key: index,
          label: `Flujo ${flu.line + 1}`,
          monedaISO: selRendicion.monedaISO,
        }))
        const sflu = aflu.length > 0 ? aflu[aflu.length - 1] : null
        setFlujos(aflu)
        setSelFlujo(sflu)
        if (sflu) {
          const [aapr, sapr] = getAprobador(sflu)
          setAprobadores(aapr)
          setSelAprobador(sapr)
          setCurrentAprob(getCurrentAprob(sflu, sapr, usuario))
        }
      }
      if (selRendicion.reposiciones) {
        const arep = selRendicion.reposiciones.map((rep, index) => ({
          ...rep,
          key: index,
          label: `Reposición ${rep.line + 1}`,
          monedaISO: selRendicion.monedaISO,
        }))
        const srep = arep.length > 0 ? arep[arep.length - 1] : null
        setReposiciones(arep)
        setSelReposicion(srep)
        if (srep) {
          const [aate, sate] = getAtencion(srep)
          setAtencionesRep(aate)
          setSelAtencionRep(sate)
        }
      }
      if (selRendicion.devoluciones) {
        const adev = selRendicion.devoluciones.map((dev, index) => ({
          ...dev,
          key: index,
          label: `Devolución ${dev.line + 1}`,
          monedaISO: selRendicion.monedaISO,
          adjuntos: selRendicion.attach && dev.dataOrder != null ? selRendicion.attach.filter(p => p.dataOrder === dev.dataOrder).map(p => ({ ...p, key: p.dataOrder })) : [],
        }))
        const sdev = adev.length > 0 ? adev[adev.length - 1] : null
        setDevoluciones(adev)
        setSelDevolucion(sdev)
      }
      if (selRendicion.attach)
        setAdjuntos(selRendicion.attach.filter(p => !selRendicion.devoluciones || !selRendicion.devoluciones.find(q => q.dataOrder === p.dataOrder)).map((att, index) => ({
          ...att,
          key: index
        })))
      if (selRendicion.audit)
        setEstados(selRendicion.audit.map((sto, index) => ({
          ...sto,
          key: index
        })))
    }
  }, [selRendicion, usuario])

  useEffect(() => {
    let isMounted = true

    const getRen = async () => {
      isMounted && setLoadGetRen(true)

      const url = renGetNumUrl(selReceptor.ruc, dbRendicion.numRD)
      const [data, err] = await axiosTnaGet(url)

      if (data) {
        const { success, data: ren } = data
        if (success)
          isMounted && setSelRendicion({ ...ren, key: ren.id })
      }
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadGetRen(false)
    }

    if (dbRendicion)
      getRen()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [selReceptor, dbRendicion])

  useImperativeHandle(
    ref,
    () => ({
      showNotify(err, type) {
        showNotifyRen(err, type)
      }
    })
  )

  const showNotifyRen = (err, type) => {
    if (type === notifyType.warning)
      notifyYellowRef.current.handleOpen(err, type)
    else if (type === notifyType.error)
      notifyRedRef.current.handleOpen(err, type)
    else if (type === notifyType.success)
      notifyGreenRef.current.handleOpen(err, type)
  }

  const getAprobador = (flu) => {
    if (!flu)
      return [[], null]

    const aapr = flu.aprobadores.map((apr, index) => ({
      ...apr,
      key: index,
      label: `Aprobador ${apr.subLine + 1}.${apr.aprLine + 1}`
    }))
    const sapr = aapr.length > 0 ? aapr.find(apr => apr.subLine === flu.subLine) : null
    return [aapr, sapr]
  }

  const getAtencion = (fon) => {
    if (!fon)
      return [[], null]

    const aate = fon.atenciones.map((ate, index) => ({
      ...ate,
      key: index,
      label: `Atención ${ate.subLine + 1}`,
      monedaISO: fon.monedaISO,
    }))
    const sate = aate.length > 0 ? aate.findLast(() => true) : null
    return [aate, sate]
  }

  const getCurrentAprob = (flu, apr, usu) => flu && apr && usu && flu.subLine === apr.subLine && !apr.fechaAprob && apr.aprobador && apr.aprobador.userID === usu.userID

  const getUsuario = (usu) => {
    if (!usu)
      return ''
    else if (usu.docIdentidad)
      return `${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`
    else
      return `${usu.userID} - ${usu.userName}`
  }

  const getClassName = (index, lista) => {
    if (index === 0)
      return 'sm:flex'
    else if (index === lista.length - 1)
      return 'py-0.5 sm:flex'
    else
      return 'pt-0.5 sm:flex'
  }

  const handleChangeFlujo = (value, aflu, usu) => {
    const sflu = aflu.find(p => p.line === value)
    setSelFlujo(sflu)
    const [aapr, sapr] = getAprobador(sflu)
    setAprobadores(aapr)
    setSelAprobador(sapr)
    setCurrentAprob(getCurrentAprob(sflu, sapr, usu))
  }

  const handleChangeAprobador = (value, aapr, flu, usu) => {
    const sapr = aapr.find(p => p.key === value)
    setSelAprobador(sapr)
    setCurrentAprob(getCurrentAprob(flu, sapr, usu))
  }

  const handleChangeReposicion = (value, arep) => {
    const srep = arep.find(p => p.line === value)
    setSelReposicion(srep)
    const [aate, sate] = getAtencion(srep)
    setAtencionesRep(aate)
    setSelAtencionRep(sate)
  }

  const handleChangeAtencionRep = (value, aate) => {
    const sate = aate.find(p => p.key === value)
    setSelAtencionRep(sate)
  }

  const handleChangeDevolucion = (value, adev) => {
    const sdev = adev.find(p => p.line === value)
    setSelDevolucion(sdev)
  }

  const handleDownFile = async (record, download) => {
    let err = null
    let data = null

    setCurrentLineFI(record.dataOrder)

    const url = renRecuperarUrl(selRendicion.id, record.dataOrder)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (!download && record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setCurrentLineFI(null)
    setLastAccess()
  }

  const ccColumns = [
    {
      title: 'Dimensión',
      dataIndex: 'dimName',
      width: 180,
    },
    {
      title: 'Código',
      dataIndex: 'codeName',
    },
  ]

  const fiColumns = [
    {
      title: 'Archivo',
      dataIndex: 'dataOrder',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex space-x-1 items-center'>
            <button
              className='text-blue-600 text-left'
              disabled={record.dataOrder === currentLineFI}
              onClick={() => handleDownFile(record, false)}
            >
              {`${record.fileName}`}
            </button>
          </div>
          <div className='flex justify-end items-center'>
            {
              record.dataOrder !== currentLineFI &&
              <>
                <button onClick={() => handleDownFile(record, true)}>
                  <CloudDownloadOutlined />
                </button>
              </>
            }
            {
              record.dataOrder === currentLineFI &&
              <Spin size='small' className='ml-2' />
            }
          </div>
        </div>
    },
  ]

  const stColumns = [
    {
      title: 'Estado',
      dataIndex: 'estadoName',
      width: 120,
    },
    {
      title: 'Fecha',
      dataIndex: 'fechaIni',
      width: 160,
      render: (_, record) => <span>{formatTime(record.fechaIni)}</span>
    },
    {
      title: 'Usuario',
      dataIndex: 'userID',
    },
  ]

  return (
    <>
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        openModalOpe &&
        <Modal
          open={openModalOpe}
          title={`Operación ( ${selRendicion?.numRD} )`}
          onOk={() => setOpenModalOpe(false)}
          onCancel={() => setOpenModalOpe(false)}
          footer={[]}
          width={720}>
          <DetalleOpe ref={detOpeRef} selReceptor={selReceptor} dbOperacion={dbOperacion} />
        </Modal >
      }
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <div className='detalleren-tabs'>
            <Tabs
              defaultActiveKey="1"
              activeKey={activeTabKey}
              onChange={setActiveTabKey}
              items={[
                {
                  label: `Data`,
                  key: '1',
                  children:
                    <>
                      <div>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposRen.map(({ label, value, detail }, index) => (
                              <div className={getClassName(index, camposRen)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="flex items-center space-x-1.5 text-gray-900">
                                    <span>{value}</span>
                                    {
                                      detail &&
                                      <span className='text-xs text-red-800'>{detail}</span>
                                    }
                                  </div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                      {
                        ccosto.length > 0 &&
                        <div className='mt-4'>
                          <p className="text-xs text-gray-800">
                            Centros de costo:
                          </p>
                          <div className='mt-2 detalleren-table'>
                            <Table
                              columns={ccColumns}
                              dataSource={ccosto}
                              pagination={false}
                              bordered
                              size='small'
                            />
                          </div>
                        </div>
                      }
                    </>
                },
                {
                  label: `Aprobación`,
                  key: '2',
                  disabled: flujos.length === 0,
                  children: flujos.length > 0 && selFlujo ?
                    <>
                      <div>
                        <Select placeholder='Flujo' style={{ width: 150 }} value={selFlujo.line} onChange={(v) => handleChangeFlujo(v, flujos, usuario)}>
                          {
                            flujos.map(({ key, line, label }) => (
                              <Select.Option key={key} value={line}>{label}</Select.Option>
                            ))
                          }
                        </Select>
                      </div>
                      <div className='mt-3 ml-1'>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposFlu.map(({ label, value }, index) => (
                              <div className={getClassName(index, camposFlu)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="text-gray-900">{value}</div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                      {
                        selAprobador &&
                        <>
                          <div className='mt-3'>
                            <Select placeholder='Aprobador' style={{ width: 150 }} value={selAprobador.key} onChange={(v) => handleChangeAprobador(v, aprobadores, selFlujo, usuario)}>
                              {
                                aprobadores.map(({ key, label }) => (
                                  <Select.Option key={key} value={key}>{label}</Select.Option>
                                ))
                              }
                            </Select>
                          </div>
                          <div className='mt-3 ml-1'>
                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                              {
                                camposApr.map(({ label, value }, index) => (
                                  <div className={getClassName(index, camposApr)} key={index}>
                                    <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                      <div className="text-gray-900">{value}</div>
                                    </dd>
                                  </div>
                                ))
                              }
                            </dl>
                          </div>
                        </>
                      }
                      {
                        (validar || currentAprob) && !aprRevertir && setObservacion &&
                        < div className='mt-3'>
                          <Input.TextArea
                            maxLength={500}
                            showCount
                            placeholder={validar ? "Ingrese observación" : "Ingrese motivo de rechazo"}
                            onChange={(e) => setObservacion(e.target.value)}
                          />
                        </div>
                      }
                    </> :
                    <span></span>
                },
                {
                  label: `Documento`,
                  key: '3',
                  disabled: !selRendicion?.documentos || selRendicion.documentos.length === 0,
                  children: <DetalleDoc selReceptor={selReceptor} selRendicion={selRendicion} showNotify={showNotifyRen} />
                },
                {
                  label: `Reposición`,
                  key: '4',
                  disabled: reposiciones.length === 0,
                  children: selReposicion ?
                    <>
                      <div>
                        <Select placeholder='Reposición' style={{ width: 150 }} value={selReposicion.line} onChange={(v) => handleChangeReposicion(v, reposiciones)}>
                          {
                            reposiciones.map(({ key, line, label }) => (
                              <Select.Option key={key} value={line}>{label}</Select.Option>
                            ))
                          }
                        </Select>
                      </div>
                      <div className='mt-3 ml-1'>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposRep.map(({ label, value }, index) => (
                              <div className={getClassName(index, camposRep)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="text-gray-900">{value}</div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                      {
                        selAtencionRep &&
                        <>
                          <div className='flex items-center mt-3'>
                            <Select placeholder='Atención' style={{ width: 150 }} value={selAtencionRep?.key} onChange={(v) => handleChangeAtencionRep(v, atencionesRep)}>
                              {
                                atencionesRep.map(({ key, label }) => (
                                  <Select.Option key={key} value={key}>{label}</Select.Option>
                                ))
                              }
                            </Select>
                            <Tooltip title="Detalle de operación" placement='right'>
                              <button className='ml-2' onClick={() => { setDbOperacion({ id: selAtencionRep.operacionID }); setOpenModalOpe(true) }}>
                                <EyeFilled className='text-blue-700' style={{ fontSize: '1.1rem' }} />
                              </button>
                            </Tooltip>
                          </div>
                          <div className='mt-3 ml-1'>
                            <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                              {
                                camposAteRep.map(({ label, value }, index) => (
                                  <div className={getClassName(index, camposAteRep)} key={index}>
                                    <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                      <div className="text-gray-900">{value}</div>
                                    </dd>
                                  </div>
                                ))
                              }
                            </dl>
                          </div>
                        </>
                      }
                    </> :
                    <span></span>
                },
                {
                  label: `Devolución`,
                  key: '5',
                  disabled: devoluciones.length === 0,
                  children: selDevolucion ?
                    <>
                      <div className='flex items-center'>
                        <Select placeholder='Devolución' style={{ width: 150 }} value={selDevolucion.line} onChange={(v) => handleChangeDevolucion(v, devoluciones)} >
                          {
                            devoluciones.map(({ key, line, label }) => (
                              <Select.Option key={key} value={line}>{label}</Select.Option>
                            ))
                          }
                        </Select>
                        {
                          selDevolucion.atencion &&
                          <Tooltip title="Detalle de operación" placement='right'>
                            <button className='ml-2' onClick={() => { setDbOperacion({ id: selDevolucion.atencion.operacionID }); setOpenModalOpe(true) }}>
                              <EyeFilled className='text-blue-700' style={{ fontSize: '1.1rem' }} />
                            </button>
                          </Tooltip>
                        }
                      </div>
                      <div className='mt-3 ml-1'>
                        <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                          {
                            camposDev.map(({ label, value }, index) => (
                              <div className={getClassName(index, camposDev)} key={index}>
                                <dt className="mt-1 text-xs font-medium text-justify text-gray-900 sm:w-48 sm:flex-none sm:pr-6">{label}</dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                  <div className="text-gray-900">{value}</div>
                                </dd>
                              </div>
                            ))
                          }
                        </dl>
                      </div>
                      {
                        selDevolucion.adjuntos.length > 0 &&
                        <div className='mt-3 detalleren-table'>
                          <Table
                            columns={fiColumns}
                            dataSource={selDevolucion.adjuntos}
                            pagination={false}
                            bordered
                            size='small'
                          />
                        </div>
                      }
                    </> :
                    <span></span>
                },
                {
                  label: `Adjunto`,
                  key: '6',
                  disabled: adjuntos.length === 0,
                  children:
                    <>
                      {
                        adjuntos.length > 0 &&
                        <div className='mb-3 detalleren-table'>
                          <Table
                            columns={fiColumns}
                            dataSource={adjuntos}
                            pagination={false}
                            bordered
                            size='small'
                          />
                        </div>
                      }
                    </>
                },
                {
                  label: `Estado`,
                  key: '7',
                  children:
                    <>
                      {
                        estados.length > 0 &&
                        <div className='mb-3 detalleren-table'>
                          <Table
                            columns={stColumns}
                            dataSource={estados}
                            pagination={false}
                            bordered
                            size='small'
                          />
                        </div>
                      }
                    </>
                },
              ]}
            />
          </div>
      }
    </>
  )
})
