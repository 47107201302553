import './EditRenCtb'

import { SyncOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Checkbox, Col, DatePicker, Form, InputNumber, Popconfirm, Row, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../../hooks/useAxiosCto'
import { renGetNumUrl, renModificarUrl, storageTCambioUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { configType } from '../../../../types/configType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { formatDigits } from '../../../../utils/formatUtil'

export const EditRenCtb = (props) => {
    const { selReceptor, dbRendicion, tolTCambio, editTCambioRen, showNotify, updateRen } = props

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const { axiosCtoGet } = useAxiosCto()
    const [formDta] = Form.useForm()
    const [loadData, setLoadData] = useState(false)
    const [loadTipoCambio, setLoadTipoCambio] = useState(false)
    const [loadModRen, setLoadModRen] = useState(false)
    const [monedaFun, setMonedaFun] = useState(null)
    const [fmFechaCtb, setFmFechaCtb] = useState(null)
    const [fmReponer, setFmReponer] = useState(null)
    const [fmTipoCambio, setFmTipoCambio] = useState(null)
    const [fmTipoCambioSunat, setFmTipoCambioSunat] = useState(null)

    useEffect(() => {
        setLoadData(loadTipoCambio || loadModRen)
    }, [loadTipoCambio, loadModRen])

    useEffect(() => {
        const fechaCtb = dbRendicion ? moment(dbRendicion.fechaCtb) : moment()
        const tipoCambio = dbRendicion?.tipoCambio || 0.0
        const reponer = dbRendicion?.reponer || false
        const monedaFun = dbRendicion ? dbRendicion.monedaISO === configType.monedaISOPEN : null
        setFmFechaCtb(fechaCtb)
        setFmTipoCambio(tipoCambio)
        setFmReponer(reponer)
        setMonedaFun(monedaFun)
        formDta.setFieldsValue({
            fechaCtb: fechaCtb,
            tipoCambio: tipoCambio,
            reponer: reponer,
        })
    }, [dbRendicion, formDta])

    useEffect(() => {
        let isMounted = true

        const tCambio = async () => {
            isMounted && setLoadTipoCambio(true)

            const fecha = fmFechaCtb.format().split('T')[0]
            const url = storageTCambioUrl(dbRendicion.monedaISO, fecha)
            const [data, err] = await axiosCtoGet(url)

            if (isMounted) {
                const tipoCambio = data?.tcVenta || 0.0
                setFmTipoCambio(tipoCambio)
                setFmTipoCambioSunat(tipoCambio)
                formDta.setFieldsValue({
                    tipoCambio: tipoCambio,
                })
            }
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadTipoCambio(false)
        }
        if (dbRendicion && fmFechaCtb && dbRendicion.tipoCambio === null && monedaFun === false)
            tCambio()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [dbRendicion, monedaFun, fmFechaCtb])

    const refreshRen = async (rcpt, ren) => {
        const url = renGetNumUrl(rcpt.ruc, ren.numRD)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Rendición: ${err.message}`)
        const nren = data?.success ? data.data : null
        return nren
    }

    const onValuesChangeDta = (changedValues, allValues) => {
        const fechaCtb = allValues.fechaCtb ?? null
        const reponer = allValues.reponer ?? null
        setFmFechaCtb(fechaCtb)
        setFmReponer(reponer)
    }

    const handleModRen = async () => {
        let err = null
        let data = null

        if (loadData)
            return;

        if (!selReceptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!dbRendicion)
            err = {
                message: msgType.selectRendicion,
                oops: false
            }
        else if (!fmFechaCtb)
            err = {
                message: `${msgType.registroNoField} fecha contable.`,
                oops: false
            }
        else if (fmReponer === null)
            err = {
                message: `${msgType.registroNoField} reponer.`,
                oops: false
            }
        else if (fmFechaCtb.toDate().getTime() < moment(dbRendicion.fechaRen).toDate().getTime())
            err = {
                message: `${msgType.koDataFechaCtb}`,
                oops: false
            }
        else if ((!fmTipoCambio || fmTipoCambio <= 0.0) && !monedaFun)
            err = {
                message: `${msgType.registroNoField} tipo de cambio.`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        if (tolTCambio !== null && tolTCambio !== undefined && !monedaFun) {
            const tipoSunat = fmTipoCambioSunat ?? 0.0
            if (Math.abs(fmTipoCambio - tipoSunat) > Math.abs(tolTCambio))
                err = {
                    message: `${msgType.rgDataTCambio} ${formatDigits(fmTipoCambio)} <> ${formatDigits(tipoSunat)}`,
                    oops: false
                }
        }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadModRen(true)

        const fechaCtb = fmFechaCtb.format().split('T')[0] + 'T00:00:00'
        const tipoCambio = fmTipoCambio === 0.0 ? null : fmTipoCambio

        const url = renModificarUrl(dbRendicion.id, true)
        const body = { fechaCtb, tipoCambio, reponer: fmReponer }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            showNotify({
                message: `${msgType.rendicionModificar}`,
                oops: false
            }, notifyType.success)
            const nren = await refreshRen(selReceptor, dbRendicion)
            if (nren && updateRen)
                updateRen(nren, true)
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadModRen(false)
        setLastAccess()
    }

    return (
        <div className='flex justify-between'>

            <div className='w-full'>
                <dl className="space-y-0.5 divide-y divide-gray-100 border-b border-gray-200 text-sm leading-6">
                    <Form
                        layout="vertical" requiredMark
                        onValuesChange={onValuesChangeDta}
                        disabled={loadData}
                        autoComplete="off"
                        form={formDta}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name="fechaCtb"
                                    label="Fecha contable"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <DatePicker placeholder='Ingrese fecha contable'
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        style={{
                                            width: '100%',
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    name="tipoCambio"
                                    label={`Tipo de cambio`}
                                    rules={[
                                        {
                                            required: !monedaFun,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder='Ingrese cambio'
                                        className='text-xs w-36'
                                        size='middle'
                                        maxLength={15}
                                        bordered={true} precision={5}
                                        readOnly={monedaFun || !editTCambioRen}
                                        min={0.0} max={100.0}
                                        style={{
                                            width: '100%',
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="reponer"
                                    label="Reponer rendición"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Checkbox disabled={!dbRendicion?.origen}>
                                        {fmReponer ? 'Con reposición' : 'Sin reposición'}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={8}></Col>
                        </Row>
                    </Form>
                </dl>
            </div>

            <div className="flex flex-row justify-end space-x-3 mx-1">
                {
                    loadModRen &&
                    <div className='flex items-center text-indigo-600'>
                        <SyncOutlined spin />
                    </div>
                }
                <div className="flex items-center text-sm text-indigo-700">
                    <Popconfirm
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        title={`¿Confirma la operación?`}
                        onConfirm={handleModRen}
                        placement="left"
                        okText="Sí"
                        cancelText="No"
                        disabled={loadData}
                    >
                        <div className='flex items-center cursor-pointer'>
                            <Tooltip title="Guardar rendición" placement='left'>
                                <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                            </Tooltip>
                            Guardar
                        </div>
                    </Popconfirm>
                </div>
            </div>

        </div>
    )
}