import './ListaCcc.css'

import { ZoomInOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space, Table, Tooltip } from 'antd'
import React, { forwardRef, useEffect, useRef, useState } from 'react'


export const ListaCcc = forwardRef((props, ref) => {
  const { costos: all_costos, centros, setCosto } = props

  const searchInput = useRef(null)
  const [costos, setCostos] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    setCostos(all_costos.map(p => ({ ...p, codeName: (centros?.find(q => q.dimID === p.dimID)?.codigos?.find(q => q.codeID === p.codeID)?.codeName || null) })))
  }, [all_costos, centros])

  const handleSearch = (selectedKeys, confirm, dataIndex) => confirm()
  const handleReset = (clearFilters, confirm) => { clearFilters(); confirm() }

  const handleSeleccionarCco = () => {
    const cco = selectedRows && selectedRows.length === 1 ? costos.find(p => p.key === selectedRows[0].key) : null
    if (cco && setCosto)
      setCosto(cco)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8, }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Ingrese palabra`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className='text-xs'
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Buscar
          </Button>
          <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90, }}
          >
            Reiniciar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === 'dimID') {
        const { dimID: code } = record
        return code.toLowerCase().includes(value.toLowerCase())
      }
      else if (dataIndex === 'codeID') {
        const { codeID: code } = record
        return code.toLowerCase().includes(value.toLowerCase())
      }
      else if (dataIndex === 'codeName') {
        const { codeName: code } = record
        return code.toLowerCase().includes(value.toLowerCase())
      }
      else
        return false
    },
  })

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRows(newSelectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: false,
    }),
    type: 'radio',
  }

  const coColumns = [
    {
      title: 'Costo',
      dataIndex: 'dimID',
      width: 140,
      ...getColumnSearchProps('dimID'),
      onCell: (record, rowIndex) => ({
        onClick: event => {
          if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        },
      }),
    },
    {
      title: 'Código',
      dataIndex: 'codeID',
      ...getColumnSearchProps('codeID'),
      width: 140,
    },
    {
      title: 'Nombre',
      dataIndex: 'codeName',
      ...getColumnSearchProps('codeName'),
    width: 320,
    },
  ]

  return (
    <>
      <div className="flex flex-row flex-wrap space-x-3 mb-3">
        <div className="flex items-center text-sm text-indigo-700">
          {
            selectedRows && selectedRows.length === 1 ?
              <div className='flex items-center cursor-pointer'
                onClick={handleSeleccionarCco}>
                <Tooltip title={"Seleccionar grupo"} placement='right'>
                  <ZoomInOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                </Tooltip>
                Seleccionar
              </div> :
              <div className='flex items-center cursor-not-allowed'>
                <ZoomInOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                Seleccionar
              </div>
          }
        </div>
      </div>
      <div className='ant-table-titania ant-table-rowsel-titania ant-pagination-titania'>
        <Table
          rowSelection={rowSelection}
          columns={coColumns}
          dataSource={costos}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} costos`
          }}
          showSizeChanger
          scroll={{ y: 390 }}
          bordered
          size='small'
        />
      </div>
    </>
  )
})
