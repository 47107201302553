import { ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, SaveOutlined, SearchOutlined, ReloadOutlined, ArrowRightOutlined, DeleteFilled } from '@ant-design/icons'
import { AutoComplete, Carousel, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { rctAreaUrl, rctCostoUrl, rctGrupoUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { configType } from '../../../../types/configType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { ListaAea } from '../../List/ListaAea'
import { ListaCcc } from '../../List/ListaCcc'
import { EditRctGcta } from './EditRctGcta'

const tiposAprob = [
    { label: 'Aprobación simple', key: 1, code: true },
    { label: 'Aprobación escalonada', key: 2, code: false },
]

const rolesAprob = [
    { label: 'Único rol (sol.)', key: 1, code: false },
    { label: 'Doble rol (sol. y ren.)', key: 2, code: true },
]

export const EditRctAea = (props) => {
    const { selReceptor, showNotify } = props

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const [formAea] = Form.useForm()
    const [formCco] = Form.useForm()
    const carouselRef = useRef()
    const [loadPage, setLoadPage] = useState(false)
    const [loadData, setLoadData] = useState(false)
    const [loadGrupo, setLoadGrupo] = useState(false)
    const [loadCosto, setLoadCosto] = useState(false)
    const [loadReceptor, setLoadReceptor] = useState(false)
    const [loadModReceptor, setLoadModReceptor] = useState(false)
    const [receptor, setReceptor] = useState(null)
    const [grupos, setGrupos] = useState([])
    const [centros, setCentros] = useState([])
    const [areas, setAreas] = useState([])
    const [costos, setCostos] = useState([])
    const [codigos, setCodigos] = useState([])
    const [noAreas, setNoAreas] = useState([])
    const [fmAreaCode, setFmAreaCode] = useState(null)
    const [fmAreaName, setFmAreaName] = useState(null)
    const [fmGrupoCta, setFmGrupoCta] = useState([])
    const [fmTipoAprob, setFmTipoAprob] = useState(false)
    const [fmRolAprob, setFmRolAprob] = useState(null)
    const [fmRendReview, setFmRendReview] = useState(false)
    const [fmCentro, setFmCentro] = useState(null)
    const [selArea, setSelArea] = useState(null)
    const [selCosto, setSelCosto] = useState(null)
    const [selCodigo, setSelCodigo] = useState(null)
    const [opcionesCod, setOpcionesCod] = useState([])
    const [dvSearchCod, setDvSearchCod] = useState(null)
    const [clearAea, setClearAea] = useState(false)
    const [clearCco, setClearCco] = useState(false)
    const [reload, setReload] = useState(false)
    const [guardado, setGuardado] = useState(true)
    const [confirmado_AEA, setConfirmado_AEA] = useState(true)
    const [confirmado_CCO, setConfirmado_CCO] = useState(true)
    const [openModalAea, setOpenModalAea] = useState(false)
    const [openModalCco, setOpenModalCco] = useState(false)
    const [openModalGrp, setOpenModalGrp] = useState(false)

    useEffect(() => {
        setLoadPage(loadReceptor || loadGrupo || loadCosto)
    }, [loadReceptor, loadGrupo, loadCosto])

    useEffect(() => {
        setLoadData(loadModReceptor)
    }, [loadModReceptor])

    useEffect(() => {
        const aaea = receptor?.config?.areas?.sort((a, b) => a.areaName > b.areaName ? 1 : -1)?.map((p, i) => {
            const naea = getAea(p, i, true, false)
            const maea = { ...naea, costos: naea.costos?.sort((a, b) => `${a.dimID}.${a.codeID}` > `${b.dimID}.${b.codeID}` ? 1 : -1)?.map((p, i) => getCco(p, i, true, false)) || [], noCostos: [] }
            return maea
        }) || []
        const saea = aaea.find(p => true) || null

        setAreas(aaea)
        setSelArea(saea)
        setNoAreas([])
        setGuardado(true)

        if (carouselRef?.current)
            carouselRef.current.goTo(0);
    }, [receptor])

    useEffect(() => {
        const areaCode = selArea?.areaCode || null
        const areaName = selArea?.areaName || null
        const grupoCta = selArea?.grupos || []
        const tipoAprob = selArea ? tiposAprob.find(p => p.code === selArea.singleApprover) : null
        const rolAprob = selArea ? rolesAprob.find(p => p.code === selArea.aprobSolRen) : null
        const rendReview = selArea?.rendReview || false
        const acco = selArea?.costos || []
        const scco = acco.find(p => true) || null

        setFmAreaCode(areaCode)
        setFmAreaName(areaName)
        setFmGrupoCta(grupoCta)
        setFmTipoAprob(tipoAprob)
        setFmRolAprob(rolAprob)
        setFmRendReview(rendReview)
        setConfirmado_AEA(true)
        setOpenModalAea(false)
        setOpenModalGrp(false)
        setCostos(acco)
        setSelCosto(scco)

        formAea.setFieldsValue({
            areaCode: areaCode,
            areaName: areaName,
            singleApprover: tipoAprob?.key,
            aprobSolRen: rolAprob?.key,
        })
    }, [selArea, grupos, clearAea, formAea])

    useEffect(() => {
        const centro = centros.find(p => p.dimID === selCosto?.dimID) || null
        const acodigo = centro?.codigos || []
        const codigo = acodigo.find(p => p.codeID === selCosto?.codeID) || null
        const opciones = searchResultCod(codigo?.codeID, acodigo)

        setFmCentro(centro)
        setCodigos(acodigo)
        setSelCodigo(codigo)
        setOpcionesCod(opciones)
        setDvSearchCod(codigo?.codeID)
        setConfirmado_CCO(true)
        setOpenModalCco(false)

        formCco.setFieldsValue({
            dimID: centro?.dimID,
        })
        // eslint-disable-next-line
    }, [selCosto, centros, clearCco, formCco])

    useEffect(() => {
        let isMounted = true

        const aeaReceptor = async () => {
            isMounted && setLoadReceptor(true)

            const url = rctAreaUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setReceptor(data.data)
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadReceptor(false)
        }

        const grpReceptor = async () => {
            isMounted && setLoadGrupo(true)

            const url = rctGrupoUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setGrupos(data.data?.config?.grupos?.map(p => ({ grpCode: p.grpCode, grpItem: p.grpItem })) || [])
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadGrupo(false)
        }

        const ccoReceptor = async () => {
            isMounted && setLoadCosto(true)

            const url = rctCostoUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setCentros(data.data?.config?.costos?.map(p => ({ dimID: p.dimID, dimName: p.dimName, codigos: (p.codigos?.map(q => ({ codeID: q.codeID, codeName: q.codeName })) || []) })) || [])
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadCosto(false)
        }

        if (selReceptor) {
            aeaReceptor()
            grpReceptor()
            ccoReceptor()
        }
        else {
            setReceptor(null)
            setGrupos([])
            setCentros([])
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [selReceptor, reload])

    // const refreshRct = async (ruc) => {
    //     const url = rctAreaUrl(ruc)
    //     const [data, err] = await axiosTnaGet(url)

    //     if (err)
    //         console.log(`Receptor: ${err.message}`)
    //     const nrct = data?.success ? data.data : null
    //     return nrct
    // }

    const getAea = (aea, index, db, edit) => {
        var naea = { ...aea, key: index, label: `${index + 1}: ${aea.areaName.toUpperCase()}`, db, edit }
        return naea
    }

    const getCco = (cco, index, db, edit) => {
        var ncco = { ...cco, key: index, label: `${index + 1}: ${cco.dimID} . ${cco.codeID}`, db, edit }
        return ncco
    }

    const sortData = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const compareText = (field, value) => {
        if (value === undefined)
            return false
        else {
            const nvalue = value.toUpperCase()
            const nfield = field.toUpperCase()
            return nfield.indexOf(nvalue) !== -1
        }
    }

    const searchResultCod = (value, acod) => {
        const ncod = acod.filter(p => value === configType.searchAllCode || compareText(p.codeID, value) || compareText(p.codeName, value))
        const aops = ncod.map(p => ({
            value: p.codeID,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <span>{`${p.codeName}`}</span>
                    <span>{`${p.codeID}`}</span>
                </div>
            ),
        }))
        return aops
    }

    const onValuesChangeAea = (changedValues, allValues) => {
        const areaCode = allValues.areaCode ?? null
        const areaName = allValues.areaName ?? null
        const tipoAprob = tiposAprob.find(p => p.key === allValues.singleApprover) || null
        const rolAprob = rolesAprob.find(p => p.key === allValues.aprobSolRen) || null

        setFmAreaCode(areaCode)
        setFmAreaName(areaName)
        setFmTipoAprob(tipoAprob)
        setFmRolAprob(rolAprob)
        setConfirmado_AEA(false)
        setGuardado(false)

        if (changedValues.hasOwnProperty('areaCode')) {
            const nareaCode = areaCode?.trim()?.toUpperCase()
            setFmAreaCode(nareaCode)
            formAea.setFieldsValue({ areaCode: nareaCode })
        }
    }

    const onValuesChangeCco = (changedValues, allValues) => {
        const centro = centros.find(p => p.dimID === allValues.dimID) || null
        const acodigo = centro?.codigos || []
        const codigo = acodigo.find(p => false) || null
        const opciones = searchResultCod(codigo?.codeID, acodigo)

        setFmCentro(centro)
        setCodigos(acodigo)
        setSelCodigo(codigo)
        setOpcionesCod(opciones)
        setDvSearchCod(codigo?.codeID)
        setConfirmado_CCO(false)
        setGuardado(false)
    }

    const handleSearchCod = (value, acod) => setOpcionesCod(value ? searchResultCod(value, acod) : [])
    const handleChangeCod = (value) => { handleChangeCodigo(null); setDvSearchCod(value) }
    const handleSelectCod = (value, acod) => { handleChangeCodigo(acod.find(p => p.codeID === value) || null); setOpcionesCod(value ? searchResultCod(value, acod) : []) }

    const handleChangeGrupo = (aea, agrp) => {
        const saea = { ...aea, grupos: (agrp && agrp.length > 0 ? [...agrp] : null), edit: true }
        setAreas(prev => {
            const aaea = prev.filter(p => p.key !== aea.key)
            const naea = [...aaea, saea]
            naea.sort(sortData)
            return naea
        })
        setSelArea(saea)
        setGuardado(false)
        setOpenModalGrp(false)
    }

    const handleChangeCodigo = (cod) => {
        setSelCodigo(cod)
        setConfirmado_CCO(false)
        setGuardado(false)
    }

    const handleChangeArea = (value, aaea) => {
        const saea = aaea.find(p => p.key === value) || null
        setSelArea(saea)
    }

    const handleChangeCosto = (value, acco) => {
        const scco = acco.find(p => p.key === value) || null
        setSelCosto(scco)
    }

    const handleAgregarArea = () => {
        let err = null

        if (!fmAreaCode || fmAreaCode.trim() === '')
            err = {
                message: `${msgType.registroNoField} Código de área.`,
                oops: false
            }
        else if (areas.find(p => p.areaCode === fmAreaCode && (!selArea || selArea.key !== p.key)))
            err = {
                message: `${msgType.rpDataAreaCode}`,
                oops: false
            }
        else if (!fmAreaName || fmAreaName.trim() === '')
            err = {
                message: `${msgType.registroNoField} nombre de área.`,
                oops: false
            }
        else if (!fmTipoAprob)
            err = {
                message: `${msgType.registroNoField} tipo de aprobación.`,
                oops: false
            }
        else if (!fmRolAprob)
            err = {
                message: `${msgType.registroNoField} rol de aprobación.`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        let maxKey = selArea ? selArea.key : (areas.length > 0 ? Math.max.apply(null, areas.map(p => p.key)) + 1 : 0)
        const acco = selArea?.costos || []
        const ncco = selArea?.noCostos || []
        const saea = getAea({
            ...selArea,
            areaCode: fmAreaCode.trim(),
            areaName: fmAreaName.trim(),
            singleApprover: fmTipoAprob.code,
            aprobSolRen: fmRolAprob.code,
            rendReview: fmRendReview,
            grupos: fmGrupoCta && fmGrupoCta.length > 0 ? [...fmGrupoCta] : null,
            costos: [...acco],
            noCostos: [...ncco],
        }, maxKey, (selArea?.db || false), true)
        setAreas(prev => {
            const aaea = selArea ? prev.filter(p => p.key !== selArea.key) : prev.filter(p => true)
            const naea = [...aaea, saea]
            naea.sort(sortData)
            return naea
        })
        setSelArea(saea)
        setConfirmado_AEA(true)
    }

    const handleQuitarArea = () => {
        if (selArea) {
            const aaea = areas.filter(p => p.key !== selArea.key)
            const saea = aaea.find(p => true) || null
            if (selArea.db)
                setNoAreas(prev => [...prev, { areaCode: selArea.areaCode }])
            setAreas(aaea)
            setSelArea(saea)
            setGuardado(false)
        }
    }

    const handleAgregarCosto = () => {
        if (selArea) {
            let err = null

            if (!fmCentro)
                err = {
                    message: `${msgType.registroNoField} centro de costo.`,
                    oops: false
                }
            else if (!selCodigo)
                err = {
                    message: `${msgType.registroNoField} código de costo.`,
                    oops: false
                }
            else if (costos.find(p => p.dimID === fmCentro.dimID && p.codeID === selCodigo.codeID && (!selCosto || selCosto.key !== p.key)))
                err = {
                    message: `${msgType.rpDataDimID}`,
                    oops: false
                }

            if (err) {
                showNotify(err, notifyType.warning)
                return
            }

            let maxKey = selCosto ? selCosto.key : (costos.length > 0 ? Math.max.apply(null, costos.map(p => p.key)) + 1 : 0)
            const scco = getCco({
                dimID: fmCentro.dimID,
                codeID: selCodigo.codeID,
            }, maxKey, (selCosto?.db || false), true)
            const occo = selCosto ? costos.filter(p => p.key !== selCosto.key) : costos.filter(p => true)
            const acco = [...occo, scco]
            acco.sort(sortData)

            const saea = { ...selArea, costos: [...acco], edit: true }
            const oaea = areas.filter(p => p.key !== selArea.key)
            const aaea = [...oaea, saea]
            aaea.sort(sortData)

            setCostos(acco)
            setSelCosto(scco)
            setAreas(aaea)
            setConfirmado_CCO(true)
        }
    }

    const handleQuitarCosto = () => {
        if (selArea && selCosto) {
            const acco = costos.filter(p => p.key !== selCosto.key)
            const scco = acco.find(p => true) || null
            const mcco = selArea.noCostos || []
            const ncco = selCosto.db ? [...mcco, { dimID: selCosto.dimID, codeID: selCosto.codeID }] : mcco

            const saea = { ...selArea, costos: [...acco], noCostos: ncco, edit: true }
            const oaea = areas.filter(p => p.key !== selArea.key)
            const aaea = [...oaea, saea]
            aaea.sort(sortData)

            setCostos(acco)
            setSelCosto(scco)
            setAreas(aaea)
            setGuardado(false)
        }
    }

    const handleModReceptor = async () => {
        let err = null
        let data = null

        if (!receptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!confirmado_AEA)
            err = {
                message: msgType.noConfimArea,
                oops: false
            }
        else if (!confirmado_CCO)
            err = {
                message: msgType.noConfimCosto,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadModReceptor(true)
        const addAreas = areas.length > 0 ? areas.filter(p => p.edit).map(p => ({
            areaCode: p.areaCode,
            areaName: p.areaName,
            grupos: p.grupos,
            singleApprover: p.singleApprover,
            aprobSolRen: p.aprobSolRen,
            rendReview: p.rendReview,
            addCostos: (p.costos.length > 0 ? p.costos.filter(q => q.edit).map(q => ({
                dimID: q.dimID,
                codeID: q.codeID,
                newEdit: !q.db
            })) : null),
            remCostos: p.noCostos.length > 0 ? p.noCostos.map(q => ({ dimID: q.dimID, codeID: q.codeID })) : null,
            newEdit: !p.db,
        })) : null
        const remAreas = noAreas.length > 0 ? noAreas.map(p => ({ areaCode: p.areaCode })) : null

        const url = rctAreaUrl(receptor.id)
        const body = { addAreas, remAreas }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            showNotify({
                message: `${msgType.receptorModificar} ${selReceptor.ruc}`,
                oops: false
            }, notifyType.success)
            setGuardado(true)
            const aaea = areas.map(p => ({ ...p, costos: p.costos.map(q => ({ ...q, db: true, edit: false })), noCostos: [], db: true, edit: false }))
            const saea = aaea.find(p => p.key === selArea?.key) || null
            setNoAreas([])
            setAreas(aaea)
            setSelArea(saea)
            // setReceptor(await refreshRct(selReceptor.ruc))
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadModReceptor(false)
        setLastAccess()
        if (carouselRef?.current)
            carouselRef.current.goTo(0);
    }

    return (
        <div className='flex flex-col ant-common-titania'>
            {
                openModalAea &&
                <Modal
                    open={openModalAea}
                    title={"Buscar área"}
                    onOk={() => setOpenModalAea(false)}
                    onCancel={() => setOpenModalAea(false)}
                    footer={[]}
                    width={700}>
                    <ListaAea areas={areas} setArea={(aea) => { aea && handleChangeArea(aea.key, areas) }} />
                </Modal>
            }
            {
                openModalGrp &&
                <Modal
                    open={openModalGrp}
                    title={`Área (${selArea?.areaCode})`}
                    onOk={() => setOpenModalGrp(false)}
                    onCancel={() => setOpenModalGrp(false)}
                    footer={[]}
                    width={500}>
                    <EditRctGcta grupos_sel={fmGrupoCta} grupos_all={grupos} setGrupos={param => { param && handleChangeGrupo(selArea, param) }} />
                </Modal>
            }
            {
                openModalCco &&
                <Modal
                    open={openModalCco}
                    title={`Buscar centro de costo (${selArea?.areaCode})`}
                    onOk={() => setOpenModalCco(false)}
                    onCancel={() => setOpenModalCco(false)}
                    footer={[]}
                    width={700}>
                    <ListaCcc costos={costos} centros={centros} setCosto={(cco) => { cco && handleChangeCosto(cco.key, costos) }} />
                </Modal>
            }
            {
                loadPage ?
                    <div className='flex mt-2 w-full justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div> :
                    <div className='flex items-center justify-center space-x-2'>
                        <span className='text-gray-600 text-xs'>{'Configuración de área'}</span>
                        {
                            !guardado &&
                            <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                        }
                    </div>
            }
            <div className={'ant-carousel-titania' + (loadPage ? ' hidden' : '')}>
                <Carousel ref={carouselRef}>
                    <div>
                        <div className='mt-3'>
                            <div className='mb-1'>
                                <span>Lista de áreas</span>
                            </div>
                            <div className='flex space-x-2'>
                                <Select placeholder='Nueva área' style={{ width: 280 }} value={selArea?.key}
                                    onChange={(v) => handleChangeArea(v, areas)} disabled={loadData} allowClear>
                                    {
                                        areas.map(({ key, label }) => (
                                            <Select.Option key={key} value={key}>{label}</Select.Option>
                                        ))
                                    }
                                </Select>
                                {
                                    (receptor && areas.length > 0 && confirmado_AEA) &&
                                    <Tooltip title="Buscar área" placement='top'>
                                        <button onClick={() => setOpenModalAea(true)} className='flex items-center'>
                                            <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    !selArea &&
                                    <Tooltip title="Limpiar área" placement='top'>
                                        <button onClick={() => setClearAea(prev => !prev)} className='flex items-center'>
                                            <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (!selArea && !confirmado_AEA) &&
                                    <Tooltip title="Nuevo área" placement='top'>
                                        <button onClick={handleAgregarArea} className='flex items-center'>
                                            <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (selArea && !confirmado_AEA) &&
                                    <Tooltip title="Modificar área" placement='top'>
                                        <button onClick={handleAgregarArea} className='flex items-center'>
                                            <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (selArea && confirmado_AEA) &&
                                    <Tooltip title="Quitar área" placement='top'>
                                        <button onClick={handleQuitarArea} className='flex items-center'>
                                            <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    !confirmado_AEA &&
                                    <div className='flex items-center justify-center'>
                                        <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='ant-form-titania'>
                            <Form
                                className='mt-4'
                                layout="vertical" requiredMark
                                onValuesChange={onValuesChangeAea}
                                disabled={loadData}
                                hidden={loadPage}
                                autoComplete="off"
                                form={formAea}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="areaCode"
                                            label="Código de área"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese código de área'
                                                size='middle'
                                                maxLength={25}
                                                readOnly={selArea?.db || false}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="areaName"
                                            label="Nombre de área"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese nombre de área'
                                                size='middle'
                                                maxLength={100}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="singleApprover"
                                            label="Tipo de aprobación"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Seleccione aprobación"
                                                allowClear>
                                                {
                                                    tiposAprob.map(({ key, label }) => (
                                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="aprobSolRen"
                                            label="Rol de aprobación"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Seleccione aprobación"
                                                allowClear>
                                                {
                                                    rolesAprob.map(({ key, label }) => (
                                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className='flex space-x-2 mt-1'>
                            <div className='flex flex-col'>
                                <div>
                                    <Checkbox className='text-xs' checked={fmRendReview} onChange={(e) => { setFmRendReview(e.target.checked); setConfirmado_AEA(false); setGuardado(false) }}>Revisar rendición</Checkbox>
                                </div>
                            </div>
                        </div>
                        {
                            (selArea && confirmado_AEA) &&
                            <div className='flex space-x-2 mt-4 items-center'>
                                <span className='text-blue-600 text-xs'>{'Lista de grupos'}</span>
                                <button onClick={() => setOpenModalGrp(true)} className='flex items-center'>
                                    <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                                {
                                    (fmGrupoCta && fmGrupoCta.length > 0) &&
                                    <Tooltip title="Quitar configuración de grupo" placement='top'>
                                        <button onClick={() => handleChangeGrupo(selArea, null)} className='flex items-center'>
                                            <DeleteFilled className='text-red-400' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                            </div>
                        }
                        <div className='mb-10'></div>
                    </div>
                    <div>
                        <div className='mt-3'>
                            <div className='mb-1'>
                                <span>{`Lista de costos (${selArea?.areaCode ?? ''})`}</span>
                            </div>
                            <div className='flex space-x-2'>
                                <Select placeholder='Nuevo costo' style={{ width: 280 }} value={selCosto?.key}
                                    onChange={(v) => handleChangeCosto(v, costos)} disabled={loadData || !selArea} allowClear>
                                    {
                                        costos.map(({ key, label }) => (
                                            <Select.Option key={key} value={key}>{label}</Select.Option>
                                        ))
                                    }
                                </Select>
                                {
                                    (receptor && costos.length > 0 && confirmado_CCO) &&
                                    <Tooltip title="Buscar costo" placement='top'>
                                        <button onClick={() => setOpenModalCco(true)} className='flex items-center'>
                                            <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (!selCosto && selArea) &&
                                    <Tooltip title="Limpiar costo" placement='top'>
                                        <button onClick={() => setClearCco(prev => !prev)} className='flex items-center'>
                                            <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (!selCosto && !confirmado_CCO) &&
                                    <Tooltip title="Nuevo costo" placement='top'>
                                        <button onClick={handleAgregarCosto} className='flex items-center'>
                                            <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (selCosto && !confirmado_CCO) &&
                                    <Tooltip title="Modificar costo" placement='top'>
                                        <button onClick={handleAgregarCosto} className='flex items-center'>
                                            <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    (selCosto && confirmado_CCO) &&
                                    <Tooltip title="Quitar código" placement='top'>
                                        <button onClick={handleQuitarCosto} className='flex items-center'>
                                            <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                        </button>
                                    </Tooltip>
                                }
                                {
                                    !confirmado_CCO &&
                                    <div className='flex items-center justify-center'>
                                        <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='ant-form-titania'>
                            <Form
                                className='mt-4'
                                layout="vertical" requiredMark
                                onValuesChange={onValuesChangeCco}
                                disabled={loadData || !selArea}
                                hidden={loadPage}
                                autoComplete="off"
                                form={formCco}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="dimID"
                                            label="Centro de costo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Seleccione centro de costo"
                                                disabled={selCosto?.db}
                                                allowClear>
                                                {
                                                    centros.map(({ dimID, dimName }) => (
                                                        <Select.Option key={dimID} value={dimID}>{dimName}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className='mb-2'>
                            <div className=' mb-1'>
                                <span className='titania-span-requerido'>*</span>
                                <span>Código de costo</span>
                            </div>
                            <div className='flex space-x-0.5'>
                                <AutoComplete
                                    dropdownMatchSelectWidth={500}
                                    style={{ width: 230, }}
                                    options={opcionesCod}
                                    onSearch={(v) => handleSearchCod(v, codigos)}
                                    onChange={handleChangeCod}
                                    onSelect={(v) => handleSelectCod(v, codigos)}
                                    value={dvSearchCod}
                                    disabled={loadData || !selArea || !fmCentro || selCosto?.db}
                                >
                                    <Input.Search size="middle" placeholder="Ingrese filtro" />
                                </AutoComplete>
                                <Input readOnly placeholder='Resultado de búsqueda' value={selCodigo?.codeName} />
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
            {
                !loadPage &&
                <div className="flex flex-row justify-end space-x-3 mx-1 mt-4">
                    <div className="flex items-center text-sm text-indigo-700">
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?${!guardado ? ' Configuración sin guardar.' : ''}`}
                            onConfirm={() => setReload(prev => !prev)}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Recargar configuración" placement='top'>
                                    <ReloadOutlined className="mr-1.5 text-green-600" aria-hidden="true" />
                                </Tooltip>
                            </div>
                        </Popconfirm>
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleModReceptor}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Guardar config. de área" placement='top'>
                                    <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                </Tooltip>
                                Guardar
                            </div>
                        </Popconfirm>
                    </div>
                </div>
            }
        </div>
    )
}
