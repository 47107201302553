import { UserOutlined, CheckCircleTwoTone, SyncOutlined, ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Form, Input, message, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { catGetCodUrl, segConnUrl, storagePageDNIUrl, storageRUCUrl, usuGetCurrUrl, usuGetNumUrl, usuModificarUrl, webPageDNIUrl } from '../../../api/apiUrl'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../hooks/useAxiosCto'
import { notifyType } from '../../../types/notifyType'
import { configType } from '../../../types/configType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { AppFooter } from '../../../components/AppFooter'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'

export const Usuario = () => {
    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const { axiosCtoGet } = useAxiosCto()
    const [formDta] = Form.useForm()
    const [formCta] = Form.useForm()
    const notifyRedRef = useRef()
    const notifyGreenRef = useRef()
    const notifyYellowRef = useRef()
    const [loadPage, setLoadPage] = useState(true)
    const [loadTokenConn, setLoadTokenConn] = useState(true)
    const [loadUsuario, setLoadUsuario] = useState(true)
    const [loadTipoDoc, setLoadTipoDoc] = useState(true)
    const [loadBanco, setLoadBanco] = useState(true)
    const [loadMoneda, setLoadMoneda] = useState(true)
    const [loadTipoCta, setLoadTipoCta] = useState(true)
    const [loadData, setLoadData] = useState(false)
    const [loadValUsuario, setLoadValUsuario] = useState(false)
    const [loadValEmisor, setLoadValEmisor] = useState(false)
    const [loadModUsuario, setLoadModUsuario] = useState(false)
    const [current, setCurrent] = useState(null)
    const [usuario, setUsuario] = useState(null)
    const [tiposDoc, setTiposDoc] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [bancos, setBancos] = useState([])
    const [monedas, setMonedas] = useState([])
    const [tiposCta, setTiposCta] = useState([])
    const [foUserID, setFoUserID] = useState(null)
    const [fmUserName, setFmUserName] = useState(null)
    const [fmTipoDoc, setFmTipoDoc] = useState(null)
    const [fmEmsrNumDoc, setFmEmsrNumDoc] = useState(null)
    const [fmEmisor, setFmEmisor] = useState(null)
    const [fmBanco, setFmBanco] = useState(null)
    const [fmMoneda, setFmMoneda] = useState(null)
    const [fmTipoCta, setFmTipoCta] = useState(null)
    const [fmNumeroCta, setFmNumeroCta] = useState(null)
    const [fmCCI, setFmCCI] = useState(null)
    const [selCuenta, setSelCuenta] = useState(null)
    const [clearCta, setClearCta] = useState(false)
    const [guardado, setGuardado] = useState(true)
    const [confirmado, setConfirmado] = useState(true)

    useEffect(() => {
        setLoadPage(loadTokenConn || loadUsuario || loadTipoDoc || loadBanco || loadMoneda || loadTipoCta)
    }, [loadTokenConn, loadUsuario, loadTipoDoc, loadBanco, loadMoneda, loadTipoCta])

    useEffect(() => {
        setLoadData(loadValUsuario || loadValEmisor || loadModUsuario)
    }, [loadValUsuario, loadValEmisor, loadModUsuario])

    useEffect(() => {
        if (usuario) {
            if (usuario.current)
                setCurrent({ ...usuario })
            setFoUserID(usuario.userID)
        }
    }, [usuario])

    useEffect(() => {
        const tdoc = tiposDoc.find(p => p.codigo === usuario?.docIdentidad?.tipoDocumentoID) || null
        const acta = usuario?.cuentas?.map((p, i) => getCta(p, i)) || []
        const scta = acta.find(p => true) || null

        setFmUserName(usuario?.userName)
        setFmTipoDoc(tdoc)
        setFmEmsrNumDoc(usuario?.docIdentidad?.documento)
        setFmEmisor(usuario?.docIdentidad)
        setCuentas(acta)
        setSelCuenta(scta)
        setGuardado(true)

        formDta.setFieldsValue({
            userName: usuario?.userName,
        })
    }, [usuario, tiposDoc, formDta])

    useEffect(() => {
        const banco = bancos.find(p => p.codigo === selCuenta?.bancoID) || null
        const moneda = monedas.find(p => p.codigo === selCuenta?.monedaISO) || null
        const tipoCta = tiposCta.find(p => p.codigo === selCuenta?.tipoCtaID) || null
        const numeroCta = selCuenta?.numeroCta || null
        const cci = selCuenta?.cci || null

        setFmBanco(banco)
        setFmMoneda(moneda)
        setFmTipoCta(tipoCta)
        setFmNumeroCta(numeroCta)
        setFmCCI(cci)
        setConfirmado(true)

        formCta.setFieldsValue({
            bancoID: banco?.codigo,
            monedaISO: moneda?.codigo,
            tipoCtaID: tipoCta?.codigo,
            numeroCta: numeroCta,
            cci: cci,
        })
    }, [selCuenta, clearCta, bancos, monedas, tiposCta, formCta])

    useEffect(() => {
        let isMounted = true

        const tokenConn = async () => {
            const url = segConnUrl()
            await axiosTnaGet(url)
            isMounted && setLoadTokenConn(false)
        }

        tokenConn()

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let isMounted = true

        const currUsuario = async () => {
            const url = usuGetCurrUrl()
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setUsuario(getUsu(data.data, true))
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadUsuario(false)
        }

        const catTipoDoc = async () => {
            const url = catGetCodUrl(configType.catTipoDocId)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposDoc(data.detalle.filter(dt => dt.bool1))
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoDoc(false)
        }

        const catBanco = async () => {
            const url = catGetCodUrl(configType.catBanco)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setBancos(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadBanco(false)
        }

        const catMoneda = async () => {
            const url = catGetCodUrl(configType.catMoneda)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setMonedas(data.detalle.filter(dt => dt.bool1))
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadMoneda(false)
        }

        const catTipoCta = async () => {
            const url = catGetCodUrl(configType.catTipoCtaBan)
            const [data, err] = await axiosTnaGet(url)

            if (data)
                isMounted && setTiposCta(data.detalle)
            if (err)
                notifyRedRef.current.handleOpen(err, notifyType.error)

            isMounted && setLoadTipoCta(false)
        }

        if (!loadTokenConn) {
            currUsuario()
            catTipoDoc()
            catBanco()
            catMoneda()
            catTipoCta()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [loadTokenConn])

    const refreshUsu = async (num) => {
        const url = usuGetNumUrl(num)
        const [data, err] = await axiosTnaGet(url)

        if (err)
            console.log(`Usuario: ${err.message}`)
        const nusu = data?.success ? getUsu(data.data, false) : null
        return nusu
    }

    const getUsu = (usu, current) => {
        var nusu = { ...usu, current, receptores: (usu.receptores || []) }
        return nusu
    }

    const getCta = (cta, index) => {
        var ncta = { ...cta, key: index, label: `${index + 1}: ${cta.bancoID} . ${cta.monedaISO} . ${cta.numeroCta}` }
        return ncta
    }

    const sortCta = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const onValuesChangeDta = (changedValues, allValues) => {
        const userName = allValues.userName ?? null
        setFmUserName(userName)
        setGuardado(false)
    }

    const onValuesChangeCta = (changedValues, allValues) => {
        const banco = bancos.find(p => p.codigo === allValues.bancoID) || null
        const moneda = monedas.find(p => p.codigo === allValues.monedaISO) || null
        const tipoCta = tiposCta.find(p => p.codigo === allValues.tipoCtaID) || null
        const numeroCta = allValues.numeroCta ?? null
        const cci = allValues.cci ?? null

        setFmBanco(banco)
        setFmMoneda(moneda)
        setFmTipoCta(tipoCta)
        setFmNumeroCta(numeroCta)
        setFmCCI(cci)
        setConfirmado(false)
        setGuardado(false)
    }

    const handleChangeTipoDoc = (value) => {
        const stdo = tiposDoc.find(p => p.codigo === value) || null
        setFmTipoDoc(stdo)
        setFmEmsrNumDoc(null)
        setFmEmisor(null)
        setGuardado(false)
    }

    const handleChangeCuenta = (value, acta) => {
        const scta = acta.find(p => p.key === value) || null
        setSelCuenta(scta)
    }

    const handleAgregarCuenta = () => {
        let err = null

        if (!fmBanco)
            err = {
                message: `${msgType.registroNoField} banco.`,
                oops: false
            }
        else if (!fmMoneda)
            err = {
                message: `${msgType.registroNoField} moneda.`,
                oops: false
            }
        else if (!fmTipoCta)
            err = {
                message: `${msgType.registroNoField} tipo de cuenta.`,
                oops: false
            }
        else if (!fmNumeroCta || fmNumeroCta.trim() === '')
            err = {
                message: `${msgType.registroNoField} número de cuenta.`,
                oops: false
            }
        else if (!fmCCI || fmCCI.trim() === '')
            err = {
                message: `${msgType.registroNoField} CCI.`,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        let maxKey = selCuenta ? selCuenta.key : (cuentas.length > 0 ? Math.max.apply(null, cuentas.map(p => p.key)) + 1 : 0)
        const scta = getCta({
            bancoID: fmBanco.codigo,
            monedaISO: fmMoneda.codigo,
            tipoCtaID: fmTipoCta.codigo,
            numeroCta: fmNumeroCta.trim(),
            cci: fmCCI.trim()
        }, maxKey)
        setCuentas(prev => {
            const acta = selCuenta ? prev.filter(p => p.key !== selCuenta.key) : prev.filter(p => true)
            const ncta = [...acta, scta]
            ncta.sort(sortCta)
            return ncta
        })
        setSelCuenta(scta)
        setConfirmado(true)
    }

    const handleQuitarCuenta = () => {
        if (selCuenta) {
            const acta = cuentas.filter(p => p.key !== selCuenta.key)
            const scta = acta.find(p => true) || null
            setCuentas(acta)
            setSelCuenta(scta)
        }
    }

    const handleValUsuario = async () => {
        let err = null

        if (!foUserID)
            err = {
                message: msgType.inputUserID,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadValUsuario(true)

        let nusu = await refreshUsu(foUserID)
        if (nusu) {
            if (!(nusu.receptores.find(p => current.receptores.find(q => q.ruc === p.ruc && q.admin)) && (nusu.ID === current.ID || !nusu.receptores.find(p => p.admin)))) {
                message.warning(msgType.saDataUsuario, 1)
                nusu = null
            }
        }
        else
            message.warning(msgType.noRegUsuario, 1)
        setUsuario(nusu)

        setLoadValUsuario(false)
        setLastAccess()
    }

    const handleValEmisor = async (emsr) => {
        let err = null
        let data = null

        if (!fmTipoDoc)
            err = {
                message: msgType.selectTipoDoc,
                oops: false
            }
        else if (fmTipoDoc.codigo !== configType.tipoDocIdeRUC && fmTipoDoc.codigo !== configType.tipoDocIdeDNI)
            err = {
                message: msgType.koDataTipoDoc,
                oops: false
            }
        else if (!fmEmsrNumDoc)
            err = {
                message: msgType.inputDocIdent,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadValEmisor(true)

        const webStorage = true ? storagePageDNIUrl(fmEmsrNumDoc) : webPageDNIUrl(fmEmsrNumDoc)
        const isRUC = fmTipoDoc.tipoDocIdentidadID === configType.tipoDocIdeRUC
        const url = isRUC ? storageRUCUrl(fmEmsrNumDoc) : webStorage
        const [data_api, err_api] = await axiosCtoGet(url)
        data = data_api
        err = err_api

        if (data) {
            const nemsr1 = { documento: (isRUC ? data.ruc : data.dni), denominacion: (isRUC ? data.nomRazSoc : data.apellNomb) }
            const nemsr2 = { validado: true }
            const nemsr = !emsr || !emsr.validado ? { ...nemsr1, ...nemsr2 } : { ...emsr, ...nemsr2 }
            setFmEmisor(nemsr)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadValEmisor(false)
        setLastAccess()
    }

    const handleModUsuario = async () => {
        let err = null
        let data = null

        if (!usuario)
            err = {
                message: msgType.selectUsuario,
                oops: false
            }
        else if (!fmUserName)
            err = {
                message: `${msgType.registroNoField} nombre de usuario.`,
                oops: false
            }
        else if (fmTipoDoc && !fmEmisor)
            err = {
                message: `${msgType.registroNoField} número de documento.`,
                oops: false
            }
        else if (!confirmado)
            err = {
                message: msgType.noConfimCtaBan,
                oops: false
            }

        if (err) {
            notifyYellowRef.current.handleOpen(err, notifyType.warning)
            return
        }

        setLoadModUsuario(true)

        const userName = fmUserName.trim()
        const docIdentidad = fmTipoDoc && fmEmisor ? {
            tipoDocumentoID: fmTipoDoc.codigo, documento: fmEmisor.documento, denominacion: fmEmisor.denominacion, validado: fmEmisor.validado
        } : null
        const ctaBanco = cuentas.length > 0 ? cuentas : null

        const url = usuModificarUrl(usuario.id)
        const body = { userName, docIdentidad, ctaBanco }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            notifyGreenRef.current.handleOpen({
                message: `${msgType.usuarioModificar} ${usuario.userID}`,
                oops: false
            }, notifyType.success)
            setGuardado(true)
            const nusu = await refreshUsu(usuario.userID)
            setUsuario(nusu)
        }
        if (err)
            notifyRedRef.current.handleOpen(err, notifyType.error)

        setLoadModUsuario(false)
        setLastAccess()
    }

    return (
        <div className="flex flex-col flex-grow items-center">
            <NotifyRed ref={notifyRedRef} />
            <NotifyGreen ref={notifyGreenRef} />
            <NotifyYellow ref={notifyYellowRef} />
            <div className='bg-white flex flex-col m-5 p-3 max-w-2xl min-w-[23rem] ant-common-titania'>
                {
                    loadPage &&
                    <div className='flex mt-2 w-full justify-center'>
                        <Spin tip='Loading....' size='large' />
                    </div>
                }
                {
                    !loadPage &&
                    <div className='flex items-center justify-center space-x-2'>
                        <UserOutlined />
                        {
                            (current && current.receptores.find(p => p.admin)) ?
                                <div className='flex items-center justify-center space-x-2'>
                                    <Input placeholder="Usuario" value={foUserID} style={{ width: 250 }} readOnly={loadValUsuario} onChange={(e) => setFoUserID(e.target.value)} />
                                    {
                                        !loadValUsuario ?
                                            <button onClick={handleValUsuario}>
                                                <SearchOutlined />
                                            </button> :
                                            <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />                                </svg>

                                    }
                                </div> :
                                <span className='text-blue-600'>{usuario.userID}</span>
                        }
                        {
                            !guardado &&
                            <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                        }
                    </div>
                }
                <Form
                    className='mt-4'
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeDta}
                    disabled={loadData}
                    hidden={loadPage}
                    autoComplete="off"
                    form={formDta}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="userName"
                                label="Nombre de usuario"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese nombre de usuario'
                                    size='middle'
                                    maxLength={50}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {
                    !loadPage &&
                    <div className='flex space-x-4'>
                        <div>
                            <div className='mb-1'>
                                <span>Tipo de documento</span>
                            </div>
                            <div className='flex space-x-1.5 bg-red-200'>
                                <Select placeholder='Seleccione tipo de documento' style={{ width: 280 }} value={fmTipoDoc?.codigo}
                                    onChange={handleChangeTipoDoc} allowClear disabled={loadData}>
                                    {
                                        tiposDoc.map(({ codigo, nombre, string1 }) => (
                                            <Select.Option key={codigo} value={codigo}>{string1 ?? nombre}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
                }
                {
                    !loadPage &&
                    <div className='mt-2'>
                        <div className='mb-1'>
                            <span className='titania-span-requerido'>*</span>
                            <span>Nº de documento</span>
                        </div>
                        <div className='flex-row md:flex md:space-x-0.5'>
                            <Input.Search value={fmEmsrNumDoc} onChange={(e) => { setFmEmsrNumDoc(e.target.value); setFmEmisor(null); setGuardado(false); }}
                                size="middle" placeholder={`Ingrese documento`} maxLength={15} style={{ width: '20.5rem' }}
                                onSearch={() => handleValEmisor(fmEmisor)}
                                disabled={loadData || loadValEmisor} />
                            <Input value={fmEmisor?.denominacion} readOnly={(fmEmisor && fmEmisor.validado) || !fmEmsrNumDoc}
                                onChange={(e) => {
                                    const emsr = { documento: fmEmsrNumDoc, denominacion: e.target.value, validado: false }
                                    setFmEmisor(emsr);
                                    setGuardado(false);
                                }}
                                placeholder='Resultado de búsqueda' />
                            {
                                loadValEmisor &&
                                <div className='flex items-center text-indigo-600 pl-1'>
                                    <SyncOutlined spin />
                                </div>
                            }
                            {
                                fmEmisor && fmEmisor.validado &&
                                <div className='flex items-center pl-1'>
                                    <Tooltip title="Emisor validado" placement='left'>
                                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                                    </Tooltip>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    !loadPage &&
                    <div className='mt-6'>
                        <div className='mb-1'>
                            <span>Cuenta bancaria</span>
                        </div>
                        <div className='flex space-x-2'>
                            <Select placeholder='Nueva cuenta' style={{ width: 280 }} value={selCuenta?.key}
                                onChange={(v) => handleChangeCuenta(v, cuentas)} disabled={loadData} allowClear>
                                {
                                    cuentas.map(({ key, label }) => (
                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                    ))
                                }
                            </Select>
                            {
                                !selCuenta &&
                                <Tooltip title="Limpiar cuenta" placement='top'>
                                    <button onClick={() => setClearCta(prev => !prev)} className='flex items-center'>
                                        <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                            {
                                (!selCuenta && !confirmado) &&
                                <Tooltip title="Nueva cuenta" placement='top'>
                                    <button onClick={handleAgregarCuenta} className='flex items-center'>
                                        <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                            {
                                (selCuenta && !confirmado) &&
                                <Tooltip title="Modificar cuenta" placement='top'>
                                    <button onClick={handleAgregarCuenta} className='flex items-center'>
                                        <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                            {
                                (selCuenta && confirmado) &&
                                <Tooltip title="Quitar cuenta" placement='top'>
                                    <button onClick={handleQuitarCuenta} className='flex items-center'>
                                        <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                    </button>
                                </Tooltip>
                            }
                            {
                                !confirmado &&
                                <div className='flex items-center justify-center'>
                                    <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className='ant-form-titania'>
                    <Form
                        className='mt-4'
                        layout="vertical" requiredMark
                        onValuesChange={onValuesChangeCta}
                        disabled={loadData}
                        hidden={loadPage}
                        autoComplete="off"
                        form={formCta}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="bancoID"
                                    label="Banco"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione banco"
                                        allowClear>
                                        {
                                            bancos.map(({ codigo, nombre }) => (
                                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="monedaISO"
                                    label="Moneda"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione moneda"
                                        allowClear>
                                        {
                                            monedas.map(({ codigo }) => (
                                                <Select.Option key={codigo} value={codigo}>{codigo}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="tipoCtaID"
                                    label="Tipo de cuenta"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione tipo de cuenta"
                                        allowClear>
                                        {
                                            tiposCta.map(({ codigo, nombre }) => (
                                                <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="numeroCta"
                                    label="Número de cuenta"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Ingrese número de cuenta'
                                        size='middle'
                                        maxLength={25}
                                        style={{
                                            width: '100%',
                                        }} />
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="cci"
                                    label="CCI"
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Ingrese código interbancario'
                                        size='middle'
                                        maxLength={25}
                                        style={{
                                            width: '100%',
                                        }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {
                    !loadPage &&
                    <div className="flex flex-row justify-end space-x-3 mx-1 mt-4">
                        <div className="flex items-center text-sm text-indigo-700">
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title={`¿Confirma la operación?`}
                                onConfirm={handleModUsuario}
                                placement="left"
                                okText="Sí"
                                cancelText="No"
                                disabled={loadData}
                            >
                                <div className='flex items-center cursor-pointer'>
                                    <Tooltip title="Guardar usuario" placement='left'>
                                        <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                    </Tooltip>
                                    Guardar
                                </div>
                            </Popconfirm>
                        </div>
                    </div>
                }
            </div>
            <div className='w-full'>
                <AppFooter />
            </div>
        </div>
    )
}
