import {
    ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, HomeIcon, PencilSquareIcon, ScaleIcon, NewspaperIcon, Square3Stack3DIcon, GlobeAltIcon,
    Cog6ToothIcon, UserGroupIcon, DocumentMagnifyingGlassIcon, WalletIcon, BanknotesIcon, DocumentCheckIcon, ClipboardIcon, ClipboardDocumentIcon,
    MagnifyingGlassCircleIcon, BriefcaseIcon, CalculatorIcon, UserIcon, TicketIcon,
    IdentificationIcon
} from '@heroicons/react/24/outline'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { Dashboard } from '../pages/private/Dashboard'
import { FileViewer } from '../pages/private/FileViewer'
import { ConsultarCPE } from '../pages/private/cpe/ConsultarCPE'
import { EstadoCtaCPE } from '../pages/private/cpe/EstadoCtaCPE'
import { ProgramarCPE } from '../pages/private/cpe/ProgramarCPE'
import { RegistrarCPE } from '../pages/private/cpe/RegistrarCPE'
import { ValidarCPE } from '../pages/private/cpe/ValidarCPE'
import { Panel } from '../pages/private/Panel'
import { CodigoAcceso } from '../pages/private/cfg/CodigoAcceso'
import { RegistrarSOL } from '../pages/private/enr/RegistrarSOL'
import { AprobarSOL } from '../pages/private/enr/AprobarSOL'
import { ConsultarSOL } from '../pages/private/enr/ConsultarSOL'
import { RegistrarOPE } from '../pages/private/enr/RegistrarOPE'
import { RegistrarREN } from '../pages/private/enr/RegistrarREN'
import { RevisarREN } from '../pages/private/enr/RevisarREN'
import { AprobarREN } from '../pages/private/enr/AprobarREN'
import { ValidarREN } from '../pages/private/enr/ValidarREN'
import { ConsultarREN } from '../pages/private/enr/ConsultarREN'
import { ConsultarMOV } from '../pages/private/enr/ConsultarMOV'
import { ConsultarATO } from '../pages/private/enr/ConsultarATO'
import { ValidarCPE as CtoValidarCPE } from '../pages/private/cto/ValidarCPE'
import { ConsultarCTB } from '../pages/private/enr/ConsultarCTB'
import { AccesoER } from '../pages/private/cfg/AccesoER'
import { Usuario } from '../pages/private/cfg/Usuario'
import { Receptor } from '../pages/private/cfg/Receptor'

const numPage = 20
const cfgLine = 1
const cpeLine = 2
const enrLine = 3
const ctoLine = 4

export const privatePages = [
    { path: '/cto/validarcpe', component: CtoValidarCPE, group: 'cto', code: 'cto_valcpe', pageName: 'Validar comprobante', icon: ClipboardDocumentCheckIcon, order: ctoLine * numPage + 1 },
    { path: '/enr/consultarctb', component: ConsultarCTB, group: 'enr', code: 'er_conctb', pageName: 'Consultar contabilidad', icon: CalculatorIcon, order: enrLine * numPage + 12 },
    { path: '/enr/consultarato', component: ConsultarATO, group: 'enr', code: 'er_conato', pageName: 'Consultar asiento', icon: BriefcaseIcon, order: enrLine * numPage + 11 },
    { path: '/enr/consultarmov', component: ConsultarMOV, group: 'enr', code: 'er_conmov', pageName: 'Consultar movimiento', icon: Square3Stack3DIcon, order: enrLine * numPage + 10 },
    { path: '/enr/consultarren', component: ConsultarREN, group: 'enr', code: 'er_conren', pageName: 'Consultar rendición', icon: DocumentMagnifyingGlassIcon, order: enrLine * numPage + 9 },
    { path: '/enr/validarren', component: ValidarREN, group: 'enr', code: 'er_valren', pageName: 'Validar rendición', icon: ClipboardDocumentListIcon, order: enrLine * numPage + 8 },
    { path: '/enr/aprobarren', component: AprobarREN, group: 'enr', code: 'er_aprren', pageName: 'Aprobar rendición', icon: ClipboardDocumentCheckIcon, order: enrLine * numPage + 7 },
    { path: '/enr/revisarren', component: RevisarREN, group: 'enr', code: 'er_revren', pageName: 'Revisar rendición', icon: ClipboardDocumentIcon, order: enrLine * numPage + 6 },
    { path: '/enr/registrarren', component: RegistrarREN, group: 'enr', code: 'er_regren', pageName: 'Registrar rendición', icon: ClipboardIcon, order: enrLine * numPage + 5 },
    { path: '/enr/registrarope', component: RegistrarOPE, group: 'enr', code: 'er_regope', pageName: 'Registrar operación', icon: BanknotesIcon, order: enrLine * numPage + 4 },
    { path: '/enr/consultarsol', component: ConsultarSOL, group: 'enr', code: 'er_consol', pageName: 'Consultar solicitud', icon: MagnifyingGlassCircleIcon, order: enrLine * numPage + 3 },
    { path: '/enr/aprobarsol', component: AprobarSOL, group: 'enr', code: 'er_aprsol', pageName: 'Aprobar solicitud', icon: DocumentCheckIcon, order: enrLine * numPage + 2 },
    { path: '/enr/registrarsol', component: RegistrarSOL, group: 'enr', code: 'er_regsol', pageName: 'Registrar solicitud', icon: PencilSquareIcon, order: enrLine * numPage + 1 },
    { path: '/cpe/consultar', component: ConsultarCPE, group: 'cpe', code: 'cp_concpe', pageName: 'Consultar', icon: DocumentMagnifyingGlassIcon, order: cpeLine * numPage + 5 },
    { path: '/cpe/estadocta', component: EstadoCtaCPE, group: 'cpe', code: 'cp_stdcta', pageName: 'Estado de cuenta', icon: ScaleIcon, order: cpeLine * numPage + 4 },
    { path: '/cpe/programar', component: ProgramarCPE, group: 'cpe', code: 'cp_prgcpe', pageName: 'Programar', icon: ClipboardDocumentListIcon, order: cpeLine * numPage + 3 },
    { path: '/cpe/validar', component: ValidarCPE, group: 'cpe', code: 'cp_valcpe', pageName: 'Validar', icon: ClipboardDocumentCheckIcon, order: cpeLine * numPage + 2 },
    { path: '/cpe/registrar', component: RegistrarCPE, group: 'cpe', code: 'cp_regcpe', pageName: 'Registrar', icon: PencilSquareIcon, order: cpeLine * numPage + 1 },
    { path: '/cfg/accesser', component: AccesoER, group: 'cfg', code: 'accesser', pageName: 'Acceso de rendición', icon: TicketIcon, order: cfgLine * numPage + 4 },
    { path: '/cfg/codeaccess', component: CodigoAcceso, group: 'cfg', code: 'accesscode', pageName: 'Código de acceso', icon: UserGroupIcon, order: cfgLine * numPage + 3 },
    { path: '/cfg/receptor', component: Receptor, group: 'cfg', code: 'receptor', pageName: 'Receptor', icon: IdentificationIcon, order: cfgLine * numPage + 2 },
    { path: '/cfg/usuario', component: Usuario, group: 'cfg', code: null, pageName: 'Usuario', icon: UserIcon, order: cfgLine * numPage + 1 },
    { path: '/dashboard', component: Dashboard, group: null, code: null, pageName: 'Dashboard', icon: HomeIcon, order: 1 },
    { path: '/', component: Dashboard, group: null, code: null, pageName: 'Dashboard', icon: null, order: null },
]

export const privateGroups = [
    { group: 'cto', code: null, pageName: 'Calisto', icon: GlobeAltIcon, order: ctoLine },
    { group: 'enr', code: null, pageName: 'Gestión de rendición', icon: WalletIcon, order: enrLine },
    { group: 'cpe', code: null, pageName: 'Gestión de comprobante', icon: NewspaperIcon, order: cpeLine },
    { group: 'cfg', code: null, pageName: 'Configuración', icon: Cog6ToothIcon, order: cfgLine },
]

export const PrivateRouter = () => {
    const { auth } = useAuth()

    return (
        <>
            <Routes>
                {
                    privatePages.filter(page => !page.code || (auth.ropag && auth.ropag.some(rp => rp === page.code))).map((page, index) => (
                        <Route key={index} path={page.path} element={<Panel page={page}><page.component page={page} /></Panel>} />
                    ))
                }
                <Route path='/file' element={<FileViewer />} />
                <Route path='*' element={<Navigate to='/oops' />} />
            </Routes>
        </>
    )
}

