import { CheckCircleOutlined } from '@ant-design/icons'
import { Checkbox, Col, Row, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'

export const EditRctPren = (props) => {
    const { paramRen, setParamRen } = props

    const [fmEditTCambioRen, setFmEditTCambioRen] = useState(false)
    const [fmEditTCambioOpe, setFmEditTCambioOpe] = useState(false)
    const [fmCtaNacionalOpe, setFmCtaNacionalOpe] = useState(false)

    useEffect(() => {
        setFmEditTCambioRen(paramRen?.editTCambioRen || false)
        setFmEditTCambioOpe(paramRen?.editTCambioOpe || false)
        setFmCtaNacionalOpe(paramRen?.ctaNacionalOpe || false)
    }, [paramRen])

    return (
        <div className='space-y-3'>
            <div className='flex flex-col items-start'>
                <span className='text-xs text-blue-600 mb-2'>Moneda y tipo de cambio</span>
                <Row>
                    <Col span={24}>
                        <Checkbox className='text-xs' checked={fmEditTCambioRen} onChange={(e) => setFmEditTCambioRen(e.target.checked)}>Editar tipo de cambio en rendición</Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Checkbox className='text-xs' checked={fmEditTCambioOpe} onChange={(e) => setFmEditTCambioOpe(e.target.checked)}>Editar tipo de cambio en operación</Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Checkbox className='text-xs' checked={fmCtaNacionalOpe} onChange={(e) => setFmCtaNacionalOpe(e.target.checked)}>Cuenta en moneda nacional para toda operación</Checkbox>
                    </Col>
                </Row>
            </div>
            <div className="flex flex-row flex-wrap space-x-3">
                <div className="flex items-center text-[0.8rem] text-indigo-700">
                    <div className='flex items-center cursor-pointer'
                        onClick={() => { setParamRen && setParamRen({ editTCambioRen: fmEditTCambioRen, editTCambioOpe: fmEditTCambioOpe, ctaNacionalOpe: fmCtaNacionalOpe }) }}>
                        <Tooltip title={"Aceptar configuración"} placement='right'>
                            <CheckCircleOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                        </Tooltip>
                        Aceptar
                    </div>
                </div>
            </div>
        </div>
    )
}
