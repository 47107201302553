import { ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Alert, Col, Form, Input, Row, Select, Tabs, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { msgType } from '../../../../types/msgType'

export const EditRctTcta = (props) => {
    const { tipoCta, tiposTrib, tiposDoc, monedas, setTipoCta } = props

    const [formDta] = Form.useForm()
    const [formImp] = Form.useForm()
    const [formCpe] = Form.useForm()
    const [formDoc] = Form.useForm()
    const [fmCtaCPE, setFmCtaCPE] = useState(null)
    const [fmCtaPRD, setFmCtaPRD] = useState(null)
    const [fmCtaGRD, setFmCtaGRD] = useState(null)
    const [fmCtaPDC, setFmCtaPDC] = useState(null)
    const [fmCtaGDC, setFmCtaGDC] = useState(null)
    const [fmCtaCGO, setFmCtaCGO] = useState(null)
    const [fmCtaDTO, setFmCtaDTO] = useState(null)
    const [fmCtaPTE, setFmCtaPTE] = useState(null)
    const [fmTipoTrib_IMP, setFmTipoTrib_IMP] = useState(null)
    const [fmTipoCta_IMP, setFmTipoCta_IMP] = useState(null)
    const [fmMoneda_CPE, setFmMoneda_CPE] = useState(null)
    const [fmTipoCta_CPE, setFmTipoCta_CPE] = useState(null)
    const [fmTipoDoc_DOC, setFmTipoDoc_DOC] = useState(null)
    const [fmMoneda_DOC, setFmMoneda_DOC] = useState(null)
    const [fmTipoCta_DOC, setFmTipoCta_DOC] = useState(null)
    const [impuestos, setImpuestos] = useState([])
    const [comprobantes, setComprobantes] = useState([])
    const [documentos, setDocumentos] = useState([])
    const [selImpuesto, setSelImpuesto] = useState(null)
    const [selComprobante, setSelComprobante] = useState(null)
    const [selDocumento, setSelDocumento] = useState(null)
    const [clearCta_IMP, setClearCta_IMP] = useState(false)
    const [clearCta_CPE, setClearCta_CPE] = useState(false)
    const [clearCta_DOC, setClearCta_DOC] = useState(false)
    const [confirmado_IMP, setConfirmado_IMP] = useState(true)
    const [confirmado_CPE, setConfirmado_CPE] = useState(true)
    const [confirmado_DOC, setConfirmado_DOC] = useState(true)
    const [messageApp, setMessageApp] = useState(null)

    useEffect(() => {
        const ctaCPE = tipoCta?.ctaCPE ?? null
        const ctaPRD = tipoCta?.ctaPRD ?? null
        const ctaGRD = tipoCta?.ctaGRD ?? null
        const ctaPDC = tipoCta?.ctaPDC ?? null
        const ctaGDC = tipoCta?.ctaGDC ?? null
        const ctaCGO = tipoCta?.ctaCGO ?? null
        const ctaDTO = tipoCta?.ctaDTO ?? null
        const ctaPTE = tipoCta?.ctaPTE ?? null
        const aimp = tipoCta?.tipoCtaIMP?.map((p, i) => getImp(p, i)) || []
        const simp = aimp.find(p => true) || null
        const acpe = tipoCta?.tipoCtaCPE?.map((p, i) => getCpe(p, i)) || []
        const scpe = acpe.find(p => true) || null
        const adoc = tipoCta?.tipoCtaDOC?.map((p, i) => getDoc(p, i)) || []
        const sdoc = adoc.find(p => true) || null

        setFmCtaCPE(ctaCPE)
        setFmCtaPRD(ctaPRD)
        setFmCtaGRD(ctaGRD)
        setFmCtaPDC(ctaPDC)
        setFmCtaGDC(ctaGDC)
        setFmCtaCGO(ctaCGO)
        setFmCtaDTO(ctaDTO)
        setFmCtaPTE(ctaPTE)
        setImpuestos(aimp)
        setSelImpuesto(simp)
        setComprobantes(acpe)
        setSelComprobante(scpe)
        setDocumentos(adoc)
        setSelDocumento(sdoc)

        formDta.setFieldsValue({
            ctaCPE: ctaCPE,
            ctaPRD: ctaPRD,
            ctaGRD: ctaGRD,
            ctaPDC: ctaPDC,
            ctaGDC: ctaGDC,
            ctaCGO: ctaCGO,
            ctaDTO: ctaDTO,
            ctaPTE: ctaPTE,
        })
    }, [tipoCta, formDta])

    useEffect(() => {
        const tipoTrib = tiposTrib.find(p => p.codigo === selImpuesto?.tributo) || null
        const tipoCta = selImpuesto?.tipoCta || null

        setFmTipoTrib_IMP(tipoTrib)
        setFmTipoCta_IMP(tipoCta)
        setConfirmado_IMP(true)

        formImp.setFieldsValue({
            tipoTrib: tipoTrib?.codigo,
            tipoCta: tipoCta,
        })
    }, [selImpuesto, tiposTrib, clearCta_IMP, formImp])

    useEffect(() => {
        const moneda = monedas.find(p => p.codigo === selComprobante?.monedaISO) || null
        const tipoCta = selComprobante?.tipoCta || null

        setFmMoneda_CPE(moneda)
        setFmTipoCta_CPE(tipoCta)
        setConfirmado_CPE(true)

        formCpe.setFieldsValue({
            monedaISO: moneda?.codigo,
            tipoCta: tipoCta,
        })
    }, [selComprobante, monedas, clearCta_CPE, formCpe])

    useEffect(() => {
        const tipoDoc = tiposDoc.find(p => p.codigo === selDocumento?.tipoDO) || null
        const moneda = monedas.find(p => p.codigo === selDocumento?.monedaISO) || null
        const tipoCta = selDocumento?.tipoCta || null

        setFmTipoDoc_DOC(tipoDoc)
        setFmMoneda_DOC(moneda)
        setFmTipoCta_DOC(tipoCta)
        setConfirmado_DOC(true)

        formDoc.setFieldsValue({
            tipoDoc: tipoDoc?.codigo,
            monedaISO: moneda?.codigo,
            tipoCta: tipoCta,
        })
    }, [selDocumento, tiposDoc, monedas, clearCta_DOC, formDoc])

    const getImp = (imp, index) => {
        var nimp = { ...imp, key: index, label: `${index + 1}: ${imp.tributo}` }
        return nimp
    }

    const getCpe = (cpe, index) => {
        var ncpe = { ...cpe, key: index, label: `${index + 1}: ${cpe.monedaISO}` }
        return ncpe
    }

    const getDoc = (doc, index) => {
        var ndoc = { ...doc, key: index, label: `${index + 1}: ${doc.tipoDO} . ${doc.monedaISO}` }
        return ndoc
    }

    const sortData = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const showAlert = (msg) => {
        setMessageApp(msg)
        setTimeout(() => { setMessageApp(null) }, 5000)
    }

    const onValuesChangeDta = (changedValues, allValues) => {
        const ctaCPE = allValues.ctaCPE ?? null
        const ctaPRD = allValues.ctaPRD ?? null
        const ctaGRD = allValues.ctaGRD ?? null
        const ctaPDC = allValues.ctaPDC ?? null
        const ctaGDC = allValues.ctaGDC ?? null
        const ctaCGO = allValues.ctaCGO ?? null
        const ctaDTO = allValues.ctaDTO ?? null
        const ctaPTE = allValues.ctaPTE ?? null

        setFmCtaCPE(ctaCPE)
        setFmCtaPRD(ctaPRD)
        setFmCtaGRD(ctaGRD)
        setFmCtaPDC(ctaPDC)
        setFmCtaGDC(ctaGDC)
        setFmCtaCGO(ctaCGO)
        setFmCtaDTO(ctaDTO)
        setFmCtaPTE(ctaPTE)
    }

    const onValuesChangeImp = (changedValues, allValues) => {
        const tipoTrib = tiposTrib.find(p => p.codigo === allValues.tipoTrib) || null
        const tipoCta = allValues.tipoCta ?? null

        setFmTipoTrib_IMP(tipoTrib)
        setFmTipoCta_IMP(tipoCta)
        setConfirmado_IMP(false)
    }

    const onValuesChangeCpe = (changedValues, allValues) => {
        const moneda = monedas.find(p => p.codigo === allValues?.monedaISO) || null
        const tipoCta = allValues.tipoCta ?? null

        setFmMoneda_CPE(moneda)
        setFmTipoCta_CPE(tipoCta)
        setConfirmado_CPE(false)
    }

    const onValuesChangeDoc = (changedValues, allValues) => {
        const tipoDoc = tiposDoc.find(p => p.codigo === allValues.tipoDoc) || null
        const moneda = monedas.find(p => p.codigo === allValues?.monedaISO) || null
        const tipoCta = allValues.tipoCta ?? null

        setFmTipoDoc_DOC(tipoDoc)
        setFmMoneda_DOC(moneda)
        setFmTipoCta_DOC(tipoCta)
        setConfirmado_DOC(false)
    }

    const handleChangeImpuesto = (value, aimp) => {
        const simp = aimp.find(p => p.key === value) || null
        setSelImpuesto(simp)
    }

    const handleChangeComprobante = (value, acpe) => {
        const scpe = acpe.find(p => p.key === value) || null
        setSelComprobante(scpe)
    }

    const handleChangeDocumento = (value, adoc) => {
        const sdoc = adoc.find(p => p.key === value) || null
        setSelDocumento(sdoc)
    }

    const handleAgregarImpuesto = () => {
        let err = null

        if (!fmTipoTrib_IMP)
            err = {
                message: `${msgType.registroNoField} tipo de tributo.`,
                oops: false
            }
        else if (!fmTipoCta_IMP || fmTipoCta_IMP.trim() === '')
            err = {
                message: `${msgType.registroNoField} número de cuenta.`,
                oops: false
            }
        else if (impuestos.find(p => p.tributo === fmTipoTrib_IMP.codigo && (!selImpuesto || selImpuesto.key !== p.key)))
            err = {
                message: `${msgType.rpDataTipoTrib}`,
                oops: false
            }

        if (err) {
            showAlert(err.message)
            return
        }

        let maxKey = selImpuesto ? selImpuesto.key : (impuestos.length > 0 ? Math.max.apply(null, impuestos.map(p => p.key)) + 1 : 0)
        const simp = getImp({
            tributo: fmTipoTrib_IMP.codigo,
            tipoCta: fmTipoCta_IMP.trim(),
        }, maxKey)
        setImpuestos(prev => {
            const aimp = selImpuesto ? prev.filter(p => p.key !== selImpuesto.key) : prev.filter(p => true)
            const nimp = [...aimp, simp]
            nimp.sort(sortData)
            return nimp
        })
        setSelImpuesto(simp)
        setConfirmado_IMP(true)
    }

    const handleQuitarImpuesto = () => {
        if (selImpuesto) {
            const aimp = impuestos.filter(p => p.key !== selImpuesto.key)
            const simp = aimp.find(p => true) || null
            setImpuestos(aimp)
            setSelImpuesto(simp)
        }
    }

    const handleAgregarComprobante = () => {
        let err = null

        if (!fmMoneda_CPE)
            err = {
                message: `${msgType.registroNoField} moneda.`,
                oops: false
            }
        else if (!fmTipoCta_CPE || fmTipoCta_CPE.trim() === '')
            err = {
                message: `${msgType.registroNoField} número de cuenta.`,
                oops: false
            }
        else if (comprobantes.find(p => p.monedaISO === fmMoneda_CPE.codigo && (!selComprobante || selComprobante.key !== p.key)))
            err = {
                message: `${msgType.rpDataMoneda}`,
                oops: false
            }

        if (err) {
            showAlert(err.message)
            return
        }

        let maxKey = selComprobante ? selComprobante.key : (comprobantes.length > 0 ? Math.max.apply(null, comprobantes.map(p => p.key)) + 1 : 0)
        const scpe = getCpe({
            monedaISO: fmMoneda_CPE.codigo,
            tipoCta: fmTipoCta_CPE.trim(),
        }, maxKey)
        setComprobantes(prev => {
            const acpe = selComprobante ? prev.filter(p => p.key !== selComprobante.key) : prev.filter(p => true)
            const ncpe = [...acpe, scpe]
            ncpe.sort(sortData)
            return ncpe
        })
        setSelComprobante(scpe)
        setConfirmado_CPE(true)
    }

    const handleQuitarComprobante = () => {
        if (selComprobante) {
            const acpe = comprobantes.filter(p => p.key !== selComprobante.key)
            const scpe = acpe.find(p => true) || null
            setComprobantes(acpe)
            setSelComprobante(scpe)
        }
    }

    const handleAgregarDocumento = () => {
        let err = null

        if (!fmTipoDoc_DOC)
            err = {
                message: `${msgType.registroNoField} tipo de documento.`,
                oops: false
            }
        if (!fmMoneda_DOC)
            err = {
                message: `${msgType.registroNoField} moneda.`,
                oops: false
            }
        else if (!fmTipoCta_DOC || fmTipoCta_DOC.trim() === '')
            err = {
                message: `${msgType.registroNoField} número de cuenta.`,
                oops: false
            }
        else if (documentos.find(p => p.tipoDO === fmTipoDoc_DOC.codigo && p.monedaISO === fmMoneda_DOC.codigo && (!selDocumento || selDocumento.key !== p.key)))
            err = {
                message: `${msgType.rpDataTDocMon}`,
                oops: false
            }

        if (err) {
            showAlert(err.message)
            return
        }

        let maxKey = selDocumento ? selDocumento.key : (documentos.length > 0 ? Math.max.apply(null, documentos.map(p => p.key)) + 1 : 0)
        const sdoc = getDoc({
            tipoDO: fmTipoDoc_DOC.codigo,
            monedaISO: fmMoneda_DOC.codigo,
            tipoCta: fmTipoCta_DOC.trim(),
        }, maxKey)
        setDocumentos(prev => {
            const adoc = selDocumento ? prev.filter(p => p.key !== selDocumento.key) : prev.filter(p => true)
            const ndoc = [...adoc, sdoc]
            ndoc.sort(sortData)
            return ndoc
        })
        setSelDocumento(sdoc)
        setConfirmado_DOC(true)
    }

    const handleQuitarDocumento = () => {
        if (selDocumento) {
            const adoc = documentos.filter(p => p.key !== selDocumento.key)
            const sdoc = adoc.find(p => true) || null
            setDocumentos(adoc)
            setSelDocumento(sdoc)
        }
    }

    const handleConfirmar = () => {
        let err = null

        if (!fmCtaCPE || fmCtaCPE.trim() === '')
            err = {
                message: `${msgType.registroNoField} cuenta comprobante.`,
                oops: false
            }
        else if (!fmCtaPRD || fmCtaPRD.trim() === '')
            err = {
                message: `${msgType.registroNoField} cuenta pérdida por redondeo.`,
                oops: false
            }
        else if (!fmCtaGRD || fmCtaGRD.trim() === '')
            err = {
                message: `${msgType.registroNoField} cuenta ganancia por redondeo.`,
                oops: false
            }
        else if (!fmCtaPDC || fmCtaPDC.trim() === '')
            err = {
                message: `${msgType.registroNoField} cuenta pérdida por cambio.`,
                oops: false
            }
        else if (!fmCtaGDC || fmCtaGDC.trim() === '')
            err = {
                message: `${msgType.registroNoField} cuenta ganancia por cambio.`,
                oops: false
            }
        else if (!fmCtaCGO || fmCtaCGO.trim() === '')
            err = {
                message: `${msgType.registroNoField} cuenta otros cargos.`,
                oops: false
            }
        else if (!fmCtaDTO || fmCtaDTO.trim() === '')
            err = {
                message: `${msgType.registroNoField} cuenta otros descuentos.`,
                oops: false
            }
        else if (!fmCtaPTE || fmCtaPTE.trim() === '')
            err = {
                message: `${msgType.registroNoField} cuenta puente.`,
                oops: false
            }
        else if (!confirmado_IMP)
            err = {
                message: msgType.noConfimCfgImp,
                oops: false
            }
        else if (!confirmado_CPE)
            err = {
                message: msgType.noConfimCfgCpe,
                oops: false
            }
        else if (!confirmado_DOC)
            err = {
                message: msgType.noConfimCfgDoc,
                oops: false
            }

        if (err) {
            showAlert(err.message)
            return
        }

        const nTipoCta = {
            ctaCPE: fmCtaCPE,
            ctaPRD: fmCtaPRD,
            ctaGRD: fmCtaGRD,
            ctaPDC: fmCtaPDC,
            ctaGDC: fmCtaGDC,
            ctaCGO: fmCtaCGO,
            ctaDTO: fmCtaDTO,
            ctaPTE: fmCtaPTE,
            tipoCtaIMP: impuestos.map(p => ({ ...p })),
            tipoCtaCPE: comprobantes.map(p => ({ ...p })),
            tipoCtaDOC: documentos.map(p => ({ ...p })),
        }
        if (setTipoCta)
            setTipoCta(nTipoCta)
    }

    return (
        <>
            <div className="flex flex-col ant-tabs-titania">
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Cuenta`,
                            key: '1',
                            children:
                                <div className='ant-form-titania'>
                                    <Form
                                        layout="vertical" requiredMark
                                        onValuesChange={onValuesChangeDta}
                                        autoComplete="off"
                                        form={formDta}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="ctaCPE"
                                                    label="Comprobante"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese cuenta'
                                                        size='middle'
                                                        maxLength={15}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>

                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="ctaPTE"
                                                    label="Puente"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese cuenta'
                                                        size='middle'
                                                        maxLength={15}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="ctaPRD"
                                                    label="Pérdida por redondeo"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese cuenta'
                                                        size='middle'
                                                        maxLength={15}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>

                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="ctaGRD"
                                                    label="Ganancia por redondeo"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese cuenta'
                                                        size='middle'
                                                        maxLength={15}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="ctaPDC"
                                                    label="Pérdida por cambio"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese cuenta'
                                                        size='middle'
                                                        maxLength={15}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>

                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="ctaGDC"
                                                    label="Ganancia por cambio"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese cuenta'
                                                        size='middle'
                                                        maxLength={15}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="ctaCGO"
                                                    label="Otros cargos"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese cuenta'
                                                        size='middle'
                                                        maxLength={15}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>

                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="ctaDTO"
                                                    label="Otros descuentos"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder='Ingrese cuenta'
                                                        size='middle'
                                                        maxLength={15}
                                                        style={{
                                                            width: '100%',
                                                        }} />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                        },
                        {
                            label: `Impuesto`,
                            key: '2',
                            forceRender: true,
                            children:
                                <div className='ant-common-titania'>
                                    <div className='mt-1'>
                                        <div className='mb-1'>
                                            <span>Configuración de impuesto</span>
                                        </div>
                                        <div className='flex space-x-2'>
                                            <Select placeholder='Nueva configuración' style={{ width: 260 }} value={selImpuesto?.key}
                                                onChange={(v) => handleChangeImpuesto(v, impuestos)} allowClear>
                                                {
                                                    impuestos.map(({ key, label }) => (
                                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                            {
                                                !selImpuesto &&
                                                <Tooltip title="Limpiar configuración" placement='top'>
                                                    <button onClick={() => setClearCta_IMP(prev => !prev)} className='flex items-center'>
                                                        <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (!selImpuesto && !confirmado_IMP) &&
                                                <Tooltip title="Nueva configuración" placement='top'>
                                                    <button onClick={handleAgregarImpuesto} className='flex items-center'>
                                                        <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (selImpuesto && !confirmado_IMP) &&
                                                <Tooltip title="Modificar configuración" placement='top'>
                                                    <button onClick={handleAgregarImpuesto} className='flex items-center'>
                                                        <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (selImpuesto && confirmado_IMP) &&
                                                <Tooltip title="Quitar configuración" placement='top'>
                                                    <button onClick={handleQuitarImpuesto} className='flex items-center'>
                                                        <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                !confirmado_IMP &&
                                                <div className='flex items-center justify-center'>
                                                    <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='ant-form-titania'>
                                        <Form
                                            className='mt-4'
                                            layout="vertical" requiredMark
                                            onValuesChange={onValuesChangeImp}
                                            autoComplete="off"
                                            form={formImp}>
                                            <Row gutter={16}>
                                                <Col span={15}>
                                                    <Form.Item
                                                        name="tipoTrib"
                                                        label="Tipo de tributo"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Seleccione tipo de tributo"
                                                            allowClear>
                                                            {
                                                                tiposTrib.map(({ codigo, nombre }) => (
                                                                    <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="tipoCta"
                                                        label="Número de cuenta"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder='Ingrese cuenta'
                                                            size='middle'
                                                            maxLength={15}
                                                            style={{
                                                                width: '100%',
                                                            }} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                        },
                        {
                            label: `Comprobante`,
                            key: '3',
                            forceRender: true,
                            children:
                                <div className='ant-common-titania'>
                                    <div className='mt-1'>
                                        <div className='mb-1'>
                                            <span>Configuración de comprobante</span>
                                        </div>
                                        <div className='flex space-x-2'>
                                            <Select placeholder='Nueva configuración' style={{ width: 260 }} value={selComprobante?.key}
                                                onChange={(v) => handleChangeComprobante(v, comprobantes)} allowClear>
                                                {
                                                    comprobantes.map(({ key, label }) => (
                                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                            {
                                                !selComprobante &&
                                                <Tooltip title="Limpiar configuración" placement='top'>
                                                    <button onClick={() => setClearCta_CPE(prev => !prev)} className='flex items-center'>
                                                        <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (!selComprobante && !confirmado_CPE) &&
                                                <Tooltip title="Nueva configuración" placement='top'>
                                                    <button onClick={handleAgregarComprobante} className='flex items-center'>
                                                        <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (selComprobante && !confirmado_CPE) &&
                                                <Tooltip title="Modificar configuración" placement='top'>
                                                    <button onClick={handleAgregarComprobante} className='flex items-center'>
                                                        <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (selComprobante && confirmado_CPE) &&
                                                <Tooltip title="Quitar configuración" placement='top'>
                                                    <button onClick={handleQuitarComprobante} className='flex items-center'>
                                                        <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                !confirmado_CPE &&
                                                <div className='flex items-center justify-center'>
                                                    <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='ant-form-titania'>
                                        <Form
                                            className='mt-4'
                                            layout="vertical" requiredMark
                                            onValuesChange={onValuesChangeCpe}
                                            autoComplete="off"
                                            form={formCpe}>
                                            <Row gutter={16}>
                                                <Col span={15}>
                                                    <Form.Item
                                                        name="monedaISO"
                                                        label="Moneda"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Seleccione moneda"
                                                            allowClear>
                                                            {
                                                                monedas.map(({ codigo, nombre }) => (
                                                                    <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="tipoCta"
                                                        label="Número de cuenta"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder='Ingrese cuenta'
                                                            size='middle'
                                                            maxLength={15}
                                                            style={{
                                                                width: '100%',
                                                            }} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                        },
                        {
                            label: `Documento`,
                            key: '4',
                            forceRender: true,
                            children:
                                <div className='ant-common-titania'>
                                    <div className='mt-1'>
                                        <div className='mb-1'>
                                            <span>Configuración de documento</span>
                                        </div>
                                        <div className='flex space-x-2'>
                                            <Select placeholder='Nueva configuración' style={{ width: 260 }} value={selDocumento?.key}
                                                onChange={(v) => handleChangeDocumento(v, documentos)} allowClear>
                                                {
                                                    documentos.map(({ key, label }) => (
                                                        <Select.Option key={key} value={key}>{label}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                            {
                                                !selDocumento &&
                                                <Tooltip title="Limpiar configuración" placement='top'>
                                                    <button onClick={() => setClearCta_DOC(prev => !prev)} className='flex items-center'>
                                                        <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (!selDocumento && !confirmado_DOC) &&
                                                <Tooltip title="Nueva configuración" placement='top'>
                                                    <button onClick={handleAgregarDocumento} className='flex items-center'>
                                                        <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (selDocumento && !confirmado_DOC) &&
                                                <Tooltip title="Modificar configuración" placement='top'>
                                                    <button onClick={handleAgregarDocumento} className='flex items-center'>
                                                        <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                (selDocumento && confirmado_DOC) &&
                                                <Tooltip title="Quitar configuración" placement='top'>
                                                    <button onClick={handleQuitarDocumento} className='flex items-center'>
                                                        <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                                    </button>
                                                </Tooltip>
                                            }
                                            {
                                                !confirmado_DOC &&
                                                <div className='flex items-center justify-center'>
                                                    <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='ant-form-titania'>
                                        <Form
                                            className='mt-4'
                                            layout="vertical" requiredMark
                                            onValuesChange={onValuesChangeDoc}
                                            autoComplete="off"
                                            form={formDoc}>
                                            <Row gutter={16}>
                                                <Col span={15}>
                                                    <Form.Item
                                                        name="tipoDoc"
                                                        label="Tipo de documento"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Seleccione tipo de documento"
                                                            allowClear>
                                                            {
                                                                tiposDoc.map(({ codigo, nombre }) => (
                                                                    <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={15}>
                                                    <Form.Item
                                                        name="monedaISO"
                                                        label="Moneda"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <Select placeholder="Seleccione moneda"
                                                            allowClear>
                                                            {
                                                                monedas.map(({ codigo, nombre }) => (
                                                                    <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="tipoCta"
                                                        label="Número de cuenta"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder='Ingrese cuenta'
                                                            size='middle'
                                                            maxLength={15}
                                                            style={{
                                                                width: '100%',
                                                            }} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                        },
                    ]}
                >
                </Tabs>
            </div>
            <div className="flex flex-row flex-wrap space-x-3 mt-3">
                <div className="flex items-center text-[0.8rem] text-indigo-700">
                    <div className='flex items-center cursor-pointer'
                        onClick={handleConfirmar}>
                        <Tooltip title={"Aceptar configuración"} placement='right'>
                            <CheckCircleOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                        </Tooltip>
                        Aceptar
                    </div>
                </div>
            </div>
            {
                messageApp &&
                <div className='mt-4'>
                    <Alert className='text-xs' message={messageApp} type="warning" showIcon closable afterClose={() => setMessageApp(null)} />
                </div>
            }
        </>
    )
}
