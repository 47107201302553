import './ListaGto.css'

import { ZoomInOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, Select, Space, Table, Tooltip } from 'antd'
import React, { forwardRef, useEffect, useRef, useState } from 'react'


export const ListaGto = forwardRef((props, ref) => {
    const { gastos: all_gastos, setGasto } = props

    const searchInput = useRef(null)
    const [gastos, setGastos] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])

    useEffect(() => {
        setGastos(all_gastos.map(p => ({ ...p })))
    }, [all_gastos])

    const handleSearch = (selectedKeys, confirm, dataIndex) => confirm()
    const handleReset = (clearFilters, confirm) => { clearFilters(); confirm() }

    const handleSeleccionarGto = () => {
        const gto = selectedRows && selectedRows.length === 1 ? gastos.find(p => p.key === selectedRows[0].key) : null
        if (gto && setGasto)
            setGasto(gto)
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8, }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Ingrese palabra`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    className='text-xs'
                    style={{ marginBottom: 8, display: 'block', }}
                />
                <Space>
                    <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, }}
                    >
                        Buscar
                    </Button>
                    <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90, }}
                    >
                        Reiniciar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />
        ),
        onFilter: (value, record) => {
            if (dataIndex === 'gtoCode') {
                const { gtoCode: code } = record
                return code.toLowerCase().includes(value.toLowerCase())
            }
            else if (dataIndex === 'gtoName') {
                const { gtoName: code } = record
                return code.toLowerCase().includes(value.toLowerCase())
            }
            else if (dataIndex === 'grpCode') {
                const { grupos } = record
                return grupos && grupos.find(p => p.grpCode.toLowerCase().includes(value.toLowerCase()))
            }
            else if (dataIndex === 'tipoCta') {
                const { tipoCta: code } = record
                return code && code.toLowerCase().includes(value.toLowerCase())
            }
            else
                return false
        },
    })

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys)
            setSelectedRows(newSelectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: false,
        }),
        type: 'radio',
    }

    const gtColumns = [
        {
            title: 'Código',
            dataIndex: 'gtoCode',
            width: 90,
            ...getColumnSearchProps('gtoCode'),
            onCell: (record, rowIndex) => ({
                onClick: event => {
                    if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.key) {
                        setSelectedRowKeys([])
                        setSelectedRows([])
                    }
                },
            }),
        },
        {
            title: 'Nombre',
            dataIndex: 'gtoName',
            ...getColumnSearchProps('gtoName'),
            width: 260,
        },
        {
            title: 'Cta. Contable',
            dataIndex: 'tipoCta',
            width: 120,
            ...getColumnSearchProps('tipoCta'),
        },
        {
            title: 'Grupo',
            dataIndex: 'grpCode',
            width: 100,
            render: (_, record) => record.grupos && record.grupos.length > 0 ?
                <div className='flex items-center'>
                    <Select defaultValue={record.grupos[0].grpCode} className='w-full' size='small' style={{ fontSize: '0.65rem' }}>
                        {
                            record.grupos.map((grp, index) => (
                                <Select.Option key={index} value={grp.grpCode}>{grp.grpCode}</Select.Option>
                            ))
                        }
                    </Select>
                </div> :
                <div className='flex items-center'>
                    <Select className='w-full' size='small' style={{ fontSize: '0.65rem' }} />
                </div>,
            ...getColumnSearchProps('grpCode'),
        },
    ]

    return (
        <>
            <div className="flex flex-row flex-wrap space-x-3 mb-3">
                <div className="flex items-center text-sm text-indigo-700">
                    {
                        selectedRows && selectedRows.length === 1 ?
                            <div className='flex items-center cursor-pointer'
                                onClick={handleSeleccionarGto}>
                                <Tooltip title={"Seleccionar grupo"} placement='right'>
                                    <ZoomInOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                </Tooltip>
                                Seleccionar
                            </div> :
                            <div className='flex items-center cursor-not-allowed'>
                                <ZoomInOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                Seleccionar
                            </div>
                    }
                </div>
            </div>
            <div className='ant-table-titania ant-table-rowsel-titania ant-pagination-titania  ant-table-listagto'>
                <Table
                    rowSelection={rowSelection}
                    columns={gtColumns}
                    dataSource={gastos}
                    pagination={{
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} gastos`
                    }}
                    showSizeChanger
                    scroll={{ y: 390 }}
                    bordered
                    size='small'
                />
            </div>
        </>
    )
})
