import './ValidarREN.css'

import { CloseCircleOutlined, QuestionCircleOutlined, SearchOutlined, ZoomOutOutlined, RetweetOutlined, FileTextOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip, message } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { useAxiosCto } from '../../../hooks/useAxiosCto'
import { catGetCodUrl, rctGrpRecurUrl, renGetNvalUrl, renGetValUrl, renObservarUrl, renValidarUrl, segConnUrl, storageTCambioUrl, usuGetCurrUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { configType } from '../../../types/configType'
import { notifyType } from '../../../types/notifyType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate } from '../../../utils/formatUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'
import { NewRen } from '../../../components/Panel/enr/RegRen/NewRen'
import { DetalleRen } from '../../../components/Panel/Detail/DetalleRen'
import { DetalleSol } from '../../../components/Panel/Detail/DetalleSol'

export const ValidarREN = ({ page: { code: resource } }) => {
  const dvFechaIni = moment().startOf('month')
  const dvFechaFin = moment().endOf('month')

  const { axiosTnaGet, axiosTnaPost, axiosTnaPut } = useAxiosTna()
  const { axiosCtoGet } = useAxiosCto()
  const notifyRedRef = useRef()
  const notifyYellowRef = useRef()
  const newRenRef = useRef()
  const detRenRef = useRef()
  const detSolRef = useRef()
  const [disablePage, setDisablePage] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
  const [loadTipoRen, setLoadTipoRen] = useState(true)
  const [loadMoneda, setLoadMoneda] = useState(true)
  const [loadUsuario, setLoadUsuario] = useState(true)
  const [loadConfigRT, setLoadConfigRT] = useState(false)
  const [loadBusqRen, setLoadBusqRen] = useState(false)
  const [loadValRen, setLoadValRen] = useState(false)
  const [loadObsRen, setLoadObsRen] = useState(false)
  const [allGroup, setAllGroup] = useState(false)
  const [receptores, setReceptores] = useState([])
  const [grupos, setGrupos] = useState([])
  const [tiposRen, setTiposRen] = useState([])
  const [areas, setAreas] = useState([])
  const [monedas, setMonedas] = useState([])
  const [usuario, setUsuario] = useState(null)
  const [selReceptor, setSelReceptor] = useState(null)
  const [selGrupo, setSelGrupo] = useState(null)
  const [dbSolicitud, setDbSolicitud] = useState(null)
  const [pendiente, setPendiente] = useState(false)
  const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
  const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
  const [foTipoRen, setFoTipoRen] = useState(null)
  const [foArea, setFoArea] = useState(null)
  const [foNumSO, setFoNumSO] = useState(null)
  const [foNumRD, setFoNumRD] = useState(null)
  const [foSave, setFoSave] = useState(null)
  const [foRevertir, setFoRevertir] = useState(null)
  const [rendiciones, setRendiciones] = useState([])
  const [selRendicion, setSelRendicion] = useState(null)
  const [observacion, setObservacion] = useState(null)
  const [rdTotalDocuments, setRdTotalDocuments] = useState(0)
  const [rdPageSize, setRdPageSize] = useState(10)
  const [rdCurrentPage, setRdCurrentPage] = useState(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    setLoadPage(loadTokenConn || loadRcptEmsr || loadTipoRen || loadMoneda || loadUsuario)
  }, [loadTokenConn, loadRcptEmsr, loadTipoRen, loadUsuario, loadMoneda])

  useEffect(() => {
    setDisablePage(loadConfigRT || loadBusqRen || loadValRen || loadObsRen)
  }, [loadConfigRT, loadBusqRen, loadValRen, loadObsRen])

  useEffect(() => {
    setDbSolicitud(selRendicion ? { numSO: selRendicion.numSO } : null)
  }, [selRendicion])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const rcptEmsr = async () => {
      const url = usuRcptEmsrUrl(resource)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setReceptores(data.rcpt)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadRcptEmsr(false)
    }

    const catMoneda = async () => {
      const url = catGetCodUrl(configType.catMoneda)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setMonedas(data.detalle.filter(dt => dt.bool1))
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadMoneda(false)
    }

    const catTipoRen = async () => {
      const url = catGetCodUrl(configType.catTipoRen)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposRen(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadTipoRen(false)
    }

    const currUsuario = async () => {
      const url = usuGetCurrUrl()
      const [data, err] = await axiosTnaGet(url)

      if (data && data.success)
        isMounted && setUsuario(data.data)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadUsuario(false)
    }

    if (!loadTokenConn) {
      rcptEmsr()
      catMoneda()
      catTipoRen()
      currUsuario()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  const setConfigRT = useCallback(async (person) => {
    setFoArea(null)

    if (!person) {
      setAreas([])
      return
    }

    setLoadConfigRT(true)

    let err = null
    let data = null

    const url = rctGrpRecurUrl(person.rcptRUC, allGroup ? '' : person.ruc, resource)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { success, data: config } = data
      if (success)
        setAreas(config.areas || [])
    }
    if (err) {
      setAreas([])
      notifyRedRef.current.handleOpen(err, notifyType.error)
    }

    setLoadConfigRT(false)

    // eslint-disable-next-line
  }, [setAreas, setFoArea])

  const sortRen = (a, b) => {
    if (a.index > b.index)
      return 1
    else
      return -1
  }

  const getUsu = (usu) => {
    if (!usu)
      return ''
    else if (usu.docIdentidad)
      return (`${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`).toUpperCase()
    else
      return `${usu.userID} - ${usu.userName}`
  }

  const getRen = (ren, index = null) => {
    var nren = { ...ren, key: ren.id, index, renSol: true, numSO: (ren.origen?.numSO ?? ren.numSO) }
    if (index === null)
      delete nren.index
    return nren
  }

  const getTitleDrawer = (ren) => {
    if (!ren || ren.isRen === null || ren.isRen === undefined || ren.isDet === null || ren.isDet === undefined)
      return ''
    else if (!ren.isRen)
      return `Solicitud ( ${ren.numSO} )`
    else if (ren.isDet === 1)
      return `Rendición ( ${ren.numRD} )`
    else if (ren.isDet === 0)
      return `Editar Rendición ( ${ren.numRD} )`
    else
      return ''
  }

  const updateRen = (ren, sel = false) => {
    setRendiciones(prev => {
      const aren = prev.filter(p => p.id !== ren.id)
      const nren = [...aren, ren]
      nren.sort(sortRen)
      return nren
    })
    if (sel)
      setSelRendicion(ren)
  }

  const clearFiltroRD = (all = true) => {
    if (all) {
      setFoSave(null)
      setRendiciones([])
      setRdTotalDocuments(0)
    }
    setSelRendicion(null)
    setSelectedRows([])
    setSelectedRowKeys([])
  }

  const handleSetRendicion = useCallback((ren, sel = true) => {
    const uren = getRen(ren)
    setRendiciones(prev => {
      const oren = prev.find(p => p.id === uren.id)
      const aren = prev.filter(p => p.id !== uren.id)
      const nren = oren ? [...aren, { ...oren, ...uren }] : [...aren]
      nren.sort(sortRen)
      return nren
    })
    if (sel)
      setSelRendicion(prev => ({ ...prev, ...uren }))
  }, [setSelRendicion])

  const handlePendiente = (value) => {
    setPendiente(value)
    setFoFechaIni(value ? null : dvFechaIni)
    setFoFechaFin(value ? null : dvFechaFin)
    setFoTipoRen(null)
    setFoArea(null)
    setFoNumSO(null)
    clearFiltroRD()
  }

  const handleObservacion = useCallback((obs) => setObservacion(obs), [setObservacion])

  const handleOpenDrawer = () => {
    setFoRevertir(!pendiente)
    setOpenDrawer(true)
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
    setSelRendicion(null)
    setFoRevertir(false)
  }

  const handleSelReceptor = useCallback((person) => {
    setSelReceptor(person)
    let a_grupos = []
    if (person && person.grupo.length > 0)
      a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, rcptRUC: person.ruc }))
    setGrupos(a_grupos)
    clearFiltroRD()
  }, [setSelReceptor])

  const handleSelGrupo = useCallback((person) => {
    setSelGrupo(person)
    setConfigRT(person)
    clearFiltroRD()
  }, [setSelGrupo, setConfigRT])

  const handleBuscarRDFecha = async () => {
    let err = null
    let data = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selGrupo && !allGroup)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    else if (!pendiente && (!foFechaIni || !foFechaFin))
      err = {
        message: msgType.selectFecha,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqRen(true)

    const grupo = allGroup ? '' : selGrupo.ruc
    const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
    const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
    const tipo = foTipoRen || ''
    const area = foArea || ''
    const numSO = foNumSO || ''

    const url = renGetValUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, pendiente, tipo, area, numSO, resource, 1, rdPageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: rens } = data
      setRendiciones(rens.map((ren, index) => getRen(ren, index)))
      setRdTotalDocuments(totalDocuments)
      setRdCurrentPage(1)
      clearFiltroRD(false)
      setFoSave({
        svSelReceptor: selReceptor,
        svGrupo: grupo,
        svFechaIni: fechaIni,
        svFechaFin: fechaFin,
        svPendiente: pendiente,
        svTipo: tipo,
        svArea: area,
        svNumSO: numSO,
      })
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqRen(false)
    setLastAccess()
  }

  const handleChangePagRD = async (page, pageSize) => {
    if (!foSave)
      return

    const { svSelReceptor, svGrupo, svFechaIni, svFechaFin, svPendiente, svTipo, svArea, svNumSO } = foSave

    let err = null
    let data = null

    setLoadBusqRen(true)

    const url = renGetValUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svPendiente, svTipo, svArea, svNumSO, resource, page, pageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: rens } = data
      setRendiciones(rens.map((ren, index) => getRen(ren, index)))
      setRdTotalDocuments(totalDocuments)
      setRdCurrentPage(page)
      clearFiltroRD(false)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqRen(false)
    setLastAccess()
  }

  const handleBuscarRDNum = async () => {
    let err = null
    let data = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selGrupo && !allGroup)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    if (!foNumRD || foNumRD.trim().length === 0)
      err = {
        message: msgType.inputNumRD,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqRen(true)

    const url = renGetNvalUrl(selReceptor.ruc, foNumRD, pendiente)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { success, data: ren } = data
      if (success && (allGroup || selGrupo.ruc === ren.grpCode)) {
        setRendiciones([getRen(ren, 0)])
        setRdTotalDocuments(1)
      }
      else {
        setRendiciones([])
        setRdTotalDocuments(0)
      }
      clearFiltroRD(false)
      setFoSave(null)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqRen(false)
    setLastAccess()
  }

  const handleEditarRen = () => {
    const ren = (selectedRows && selectedRows.length > 0) ? (rendiciones.find(p => p.id === selectedRows[0].id) || {}) : {}
    let err = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!ren.id)
      err = {
        message: msgType.registroNoSel,
        oops: false
      }
    else if (selectedRows.length > 1)
      err = {
        message: msgType.registroNoOne,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setSelRendicion({ ...ren, isRen: true, isDet: 0 })
    handleOpenDrawer()
  }

  const handleDetalleRen = (record, isRen, isDet) => {
    const ope = { ...record, isRen, isDet }
    setSelRendicion(ope)
    handleOpenDrawer()
  }


  const handleValidarRen = async () => {
    let err = null
    let data = null

    if (!usuario)
      err = {
        message: msgType.noDataUser,
        oops: false
      }
    else if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }
    else if (selRendicion.allowPut)
      err = {
        message: foRevertir ? msgType.rendicionNoValRev : msgType.rendicionNoVal,
        oops: false
      }

    if (err) {
      detRenRef.current.showNotify(err, notifyType.warning)
      return
    }

    setLoadValRen(true)

    let tipoCambio = null
    if (selRendicion.tipoCambio === null) {
      if (!foRevertir && selRendicion.monedaISO !== configType.monedaISOPEN) {
        const fecha = selRendicion.fechaCtb.split('T')[0]
        const url = storageTCambioUrl(selRendicion.monedaISO, fecha)
        const [data_api, err_api] = await axiosCtoGet(url)
        if (data_api)
          tipoCambio = data_api.tcVenta
        if (err_api)
          console.log(`Tipo de cambio: ${err_api}`)
      }
    }
    else
      tipoCambio = selRendicion.tipoCambio

    const body = !foRevertir ? { tipoCambio } : {}
    const url = renValidarUrl(selRendicion.id)
    const [data_api, err_api] = foRevertir ? await axiosTnaPut(url, body) : await axiosTnaPost(url, body)
    data = data_api
    err = err_api

    if (data) {
      setRendiciones(prev => prev.filter(ren => ren.id !== selRendicion.id))
      handleCloseDrawer()
      message.success(foRevertir ? msgType.rendicionValidarRev : msgType.rendicionValidar);
    }
    if (err)
      detRenRef.current.showNotify(err, notifyType.error)

    setLoadValRen(false)
    setLastAccess()
  }

  const handleObservarRen = async () => {
    let err = null
    let data = null

    if (!usuario)
      err = {
        message: msgType.noDataUser,
        oops: false
      }
    else if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selRendicion)
      err = {
        message: msgType.selectRendicion,
        oops: false
      }
    else if (!foRevertir && selRendicion.allowPut)
      err = {
        message: msgType.rendicionNoObs,
        oops: false
      }
    else if (foRevertir && !selRendicion.allowPut)
      err = {
        message: msgType.rendicionNoObsRev,
        oops: false
      }
    else if (!foRevertir && (!observacion || observacion.length === 0))
      err = {
        message: msgType.inputObserv,
        oops: false
      }

    if (err) {
      detRenRef.current.showNotify(err, notifyType.warning)
      return
    }

    setLoadObsRen(true)

    const url = renObservarUrl(selRendicion.id)
    const [data_api, err_api] = foRevertir ? await axiosTnaPut(url, {}) : await axiosTnaPost(url, { observacion })
    data = data_api
    err = err_api

    if (data) {
      setRendiciones(prev => prev.filter(ren => ren.id !== selRendicion.id))
      handleCloseDrawer()
      message.success(foRevertir ? msgType.rendicionObservarRev : msgType.rendicionObservar);
    }
    if (err)
      detRenRef.current.showNotify(err, notifyType.error)

    setLoadObsRen(false)
    setLastAccess()
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRows(newSelectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: false,
    }),
    type: 'radio',
  }

  const rdColumns = [
    {
      title: 'Nº Rendición',
      dataIndex: 'numRD',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex items-center'>
            <button
              className='text-blue-600 w-full text-left'
              onClick={() => handleDetalleRen(record, record.renSol, 1)}
            >
              <span>{`${!record.renSol ? record.numSO : record.numRD}`}</span>
            </button>
          </div>
          {
            record.numSO &&
            <div className='flex justify-end space-x-1'>
              <Tooltip title={`${record.renSol ? 'Mostrar nº de solicitud' : 'Mostrar nº de rendición'}`} placement="right">
                <button onClick={() => updateRen({ ...record, renSol: !record.renSol })}>
                  <RetweetOutlined />
                </button>
              </Tooltip>
            </div>
          }
        </div>,
    },
    {
      title: 'Tipo Ren.',
      dataIndex: 'tipo',
      width: 120,
    },
    {
      title: 'Fecha Ren.',
      dataIndex: 'fechaRen',
      width: 92,
      render: (_, record) => <span>{formatDate(record.fechaRen)}</span>
    },
    {
      title: '-$-',
      dataIndex: 'monedaISO',
      width: 50,
      align: 'center',
      onCell: (record, rowIndex) => ({
        onClick: event => {
          if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        },
      })
    },
    {
      title: 'Monto',
      dataIndex: 'totRen',
      width: 90,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.totRen)}</span>
    },
    {
      title: 'Area',
      dataIndex: 'area',
      width: 250,
      responsive: ['lg'],
      render: (_, record) => allGroup ? <span>{`${record.grpCode} ${record.area}`}</span> : <span>{record.area}</span>
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      width: 110,
    },
    {
      title: 'Rindente',
      dataIndex: 'rindente',
      width: 320,
      responsive: ['xl'],
      render: (_, record) => <span>{getUsu(record.rindente ?? record.solicitante)}</span>
    },
  ]

  return (
    <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
      <Drawer
        title={getTitleDrawer(selRendicion)}
        placement="right"
        onClose={handleCloseDrawer}
        open={openDrawer}
        getContainer={false}
        style={{ position: 'absolute' }}
        bodyStyle={{ padding: 20, }}
        headerStyle={{ padding: 15, }}
        destroyOnClose={true}
        maskClosable={false}
        zIndex={5}
        width={720}
        closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
        extra={
          <Space>
            {
              (selRendicion && selRendicion.isRen && selRendicion.isDet === 1) ?
                <>
                  <Popconfirm
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    title={`¿Confirma la operación?`}
                    onConfirm={handleValidarRen}
                    placement="left"
                    okText="Sí"
                    cancelText="No"
                  >
                    <Button shape="round" type="primary">
                      {foRevertir ? `Revertir validación` : `Validar`}
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    title={`¿Confirma la operación?`}
                    onConfirm={handleObservarRen}
                    placement="left"
                    okText="Sí"
                    cancelText="No"
                  >
                    <Button shape="round" type="primary">
                      {foRevertir ? `Revertir observación` : `Observar`}
                    </Button>
                  </Popconfirm>
                  {
                    !disablePage &&
                    <Switch checkedChildren="REV" unCheckedChildren="REV" checked={foRevertir} onChange={setFoRevertir} />
                  }
                </> :
                <></>
            }
          </Space>
        }
      >
        {
          selRendicion ?
            <>
              {
                !selRendicion.isRen ?
                  <DetalleSol ref={detSolRef} selReceptor={selReceptor} dbSolicitud={dbSolicitud} /> :
                  <>
                    {
                      selRendicion.isDet === 0 ?
                        <NewRen ref={newRenRef} usuario={usuario} selReceptor={selReceptor} selGrupo={selGrupo} selRendicion={selRendicion}
                          tiposRen={tiposRen} monedas={monedas} updateRen={handleSetRendicion} validar={true} /> :
                        <>
                          {
                            selRendicion.isDet === 1 ?
                              <DetalleRen ref={detRenRef} usuario={usuario} selReceptor={selReceptor} selRendicion={selRendicion}
                                aprRevertir={foRevertir} setObservacion={handleObservacion} validar={true} /> :
                              <></>
                          }
                        </>
                    }
                  </>
              }
            </> :
            <></>
        }
      </Drawer>
      <NotifyRed ref={notifyRedRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <div className='flex-grow'>
            <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
              <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
              {
                !allGroup &&
                <SelRcptEmsr comboLabel={selReceptor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
              }
              <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
            </div>

            <div className='p-3 flex flex-col ant-common-titania'>
              {
                pendiente &&
                <div className="flex flex-row flex-wrap space-x-3 mb-3">
                  <div className="flex items-center text-sm text-indigo-700">
                    {
                      !disablePage ?
                        <div className='flex items-center cursor-pointer' onClick={handleEditarRen}>
                          <Tooltip title={"Editar rendición"} placement='right'>
                            <FileTextOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                          </Tooltip>
                          Editar
                        </div>
                        :
                        <div className='flex items-center cursor-not-allowed'>
                          <FileTextOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                          Editar
                        </div>
                    }
                  </div>
                </div>
              }

              <p className="text-xs text-gray-700 mb-2">
                <span>Busca una rendición usando los filtros de fecha y tipo de rendición.</span>
              </p>
              <div className='flex space-x-10'>
                <div className='flex items-center space-x-2'>
                  <DatePicker placeholder='Fecha inicio' value={foFechaIni} onChange={(d,) => setFoFechaIni(d ?? null)} />
                  <DatePicker placeholder='Fecha fin' value={foFechaFin} onChange={(d,) => setFoFechaFin(d ?? null)} />
                  <Switch checkedChildren="PEND" unCheckedChildren="PEND" checked={pendiente} onChange={handlePendiente} />
                  <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                    <Select placeholder='Tipo' value={foTipoRen} allowClear style={{ width: 150 }} onChange={(v) => setFoTipoRen(v || null)}>
                      {
                        tiposRen.map(({ codigo, nombre }) => (
                          <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                        ))
                      }
                    </Select>
                    {
                      loadConfigRT ?
                        <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                        </svg> :
                        <>
                          <Select placeholder='Area' value={foArea} allowClear style={{ width: 200 }} onChange={(v) => setFoArea(v || null)}>
                            {
                              areas.map(({ areaCode, areaName }) => (
                                <Select.Option key={areaCode} value={areaCode}>{areaName}</Select.Option>
                              ))
                            }
                          </Select>
                        </>
                    }
                    <Input placeholder="Nº de solicitud" value={foNumSO} style={{ width: 135 }} onChange={(e) => setFoNumSO(e.target.value)} />
                  </div>
                  {
                    !disablePage ?
                      <Tooltip title={"Buscar rendición"}>
                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarRDFecha} />
                      </Tooltip> :
                      <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                  }
                </div>
                <div className='flex items-center space-x-2'>
                  <Input placeholder="Nº de rendición" style={{ width: 135 }} onChange={(e) => setFoNumRD(e.target.value)} />
                  {
                    !disablePage ?
                      <Tooltip title="Buscar rendición">
                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarRDNum} />
                      </Tooltip> :
                      <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                  }
                </div>
              </div>

              <div className='lg:max-w-7xl'>
                {
                  loadBusqRen ?
                    <div className='my-5 flex justify-center'>
                      <Spin tip='Loading....' size='large' />
                    </div> :
                    <>
                      <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-fnvalren'>
                        <Table
                          rowSelection={rowSelection}
                          columns={rdColumns}
                          dataSource={rendiciones}
                          pagination={false}
                          scroll={{ y: 400 }}
                          bordered
                          size='small'
                        />
                      </div>
                      <div className='mt-2 flex justify-end ant-pagination-titania'>
                        <Pagination
                          defaultCurrent={rdCurrentPage}
                          total={rdTotalDocuments}
                          showSizeChanger
                          showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} ${'rendiciones'}`}
                          defaultPageSize={rdPageSize}
                          onChange={handleChangePagRD}
                          onShowSizeChange={(_, size) => setRdPageSize(size)}
                          disabled={disablePage} />
                      </div>
                    </>
                }
              </div>
            </div>
          </div>
      }
      <div className='w-full'>
        <AppFooter />
      </div>
    </div>
  )
}
