import './ConsultarATO.css'

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import { CloseCircleOutlined, SearchOutlined, ZoomOutOutlined, EyeOutlined, QuestionCircleOutlined, SyncOutlined, AndroidOutlined, AppleOutlined } from '@ant-design/icons'
import { Button, DatePicker, Drawer, Input, Pagination, Popconfirm, Select, Space, Spin, Switch, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { catGetCodUrl, atoGetConUrl, atoGetNumUrl, atoGetSdocUrl, atoGetSrinUrl, atoGetStpoUrl, segConnUrl, tsk12Url, tskRecuperarUrl, usuRcptEmsrUrl, tsk19Url } from '../../../api/apiUrl'
import { configType } from '../../../types/configType'
import { notifyType } from '../../../types/notifyType'
import { msgType } from '../../../types/msgType'
import { setLastAccess } from '../../../utils/lstorageUtil'
import { formatAmount, formatDate, formatTime } from '../../../utils/formatUtil'
import { downloadFile, viewerFile } from '../../../utils/fileUtil'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { AppFooter } from '../../../components/AppFooter'
import { DetalleRen } from '../../../components/Panel/Detail/DetalleRen'
import { DetalleSol } from '../../../components/Panel/Detail/DetalleSol'
import { DetalleOpe } from '../../../components/Panel/Detail/DetalleOpe'

var jszip = require('jszip')

export const ConsultarATO = ({ page: { code: resource } }) => {
  const dvFechaIni = moment().startOf('month')
  const dvFechaFin = moment().endOf('month')
  const dvFecha = moment()

  const { axiosTnaGet, axiosTnaPost } = useAxiosTna()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const detRenRef = useRef()
  const detSolRef = useRef()
  const detOpeRef = useRef()
  const searchInput = useRef(null)
  const [loadPage, setLoadPage] = useState(true)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
  const [loadTipoCta, setLoadTipoCta] = useState(true)
  const [loadTipoMov, setLoadTipoMov] = useState(true)
  const [loadData, setLoadData] = useState(false)
  const [loadBusqAto, setLoadBusqAto] = useState(false)
  const [loadTask12Add, setLoadTask12Add] = useState(false)
  const [loadTask12Down, setLoadTask12Down] = useState(false)
  const [loadTask19Add, setLoadTask19Add] = useState(false)
  const [loadTask19Down, setLoadTask19Down] = useState(false)
  const [allGroup, setAllGroup] = useState(false)
  const [receptores, setReceptores] = useState([])
  const [grupos, setGrupos] = useState([])
  const [tiposCta, setTiposCta] = useState([])
  const [tiposMov, setTiposMov] = useState([])
  const [tiposSal, setTiposSal] = useState([])
  const [selReceptor, setSelReceptor] = useState(null)
  const [selGrupo, setSelGrupo] = useState(null)
  const [dbSolicitud, setDbSolicitud] = useState(null)
  const [dbRendicion, setDbRendicion] = useState(null)
  const [dbOperacion, setDbOperacion] = useState(null)
  const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
  const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
  const [foFecha, setFoFecha] = useState(dvFecha)
  const [foTipoCta, setFoTipoCta] = useState(null)
  const [foTipoMov, setFoTipoMov] = useState(null)
  const [foTipoSal, setFoTipoSal] = useState(null)
  const [foRindente, setFoRindente] = useState(null)
  const [foNumMV, setFoNumMV] = useState(null)
  const [foSave, setFoSave] = useState(null)
  const [asientos, setAsientos] = useState([])
  const [saldosDoc, setSaldosDoc] = useState([])
  const [saldosRin, setSaldosRin] = useState([])
  const [saldosTpo, setSaldosTpo] = useState([])
  const [selAsiento, setSelAsiento] = useState(null)
  const [ordenAto, setOrdenAto] = useState(false)
  const [atTotalDocuments, setAtTotalDocuments] = useState(0)
  const [atPageSize, setAtPageSize] = useState(10)
  const [atCurrentPage, setAtCurrentPage] = useState(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [, setSelectedRows] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)
  const [taskInterval, setTaskInterval] = useState(false)
  const [task12, setTask12] = useState({ task: null, id: null, first: true })
  const [task19, setTask19] = useState({ task: null, id: null, first: true })

  useEffect(() => {
    setLoadPage(loadTokenConn || loadRcptEmsr || loadTipoCta || loadTipoMov)
  }, [loadTokenConn, loadRcptEmsr, loadTipoCta, loadTipoMov])

  useEffect(() => {
    setLoadData(loadBusqAto || loadTask12Add || loadTask12Down || loadTask19Add || loadTask19Down)
  }, [loadBusqAto, loadTask12Add, loadTask12Down, loadTask19Add, loadTask19Down])

  useEffect(() => {
    setDbSolicitud(selAsiento && selAsiento.solRen ? { numSO: selAsiento.numMV } : null)
    setDbRendicion(selAsiento && (!selAsiento.solRen || (selAsiento.origen && !selAsiento.origen.opeRen)) ? { numRD: (!selAsiento.solRen ? selAsiento.numMV : selAsiento.origen.numRD) } : null)
    setDbOperacion(selAsiento && selAsiento.origen && selAsiento.origen.opeRen ? { id: selAsiento.origen.origenID } : null)
  }, [selAsiento])

  useEffect(() => {
    const tipos = [
      { codigo: '1', nombre: 'Asiento' },
      { codigo: '2', nombre: 'S. Documento' },
      { codigo: '3', nombre: 'S. Rindente' },
      { codigo: '4', nombre: 'S. Tipo Cta' }]
    const tipo = tipos.find(() => true)?.codigo
    setTiposSal(tipos)
    setFoTipoSal(tipo)
  }, [])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const rcptEmsr = async () => {
      const url = usuRcptEmsrUrl(resource)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setReceptores(data.rcpt)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadRcptEmsr(false)
    }

    const catTipoCta = async () => {
      const url = catGetCodUrl(configType.catTipoCta)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposCta(data.detalle?.filter(p => !p.string1) || [])
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadTipoCta(false)
    }

    const catTipoMov = async () => {
      const url = catGetCodUrl(configType.catTipoMov)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTiposMov(data.detalle?.filter(p => !p.string1) || [])
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      isMounted && setLoadTipoMov(false)
    }

    if (!loadTokenConn) {
      rcptEmsr()
      catTipoCta()
      catTipoMov()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const t12Get = async () => {
      const url = tsk12Url()
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTask12(prev => ({
          ...prev,
          task: data.data,
          id: data.data?.id ?? prev.id,
          first: false
        }))
      if (err)
        console.log(`Task: ${err.message}`)
    }

    const t19Get = async () => {
      const url = tsk19Url()
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setTask19(prev => ({
          ...prev,
          task: data.data,
          id: data.data?.id ?? prev.id,
          first: false
        }))
      if (err)
        console.log(`Task: ${err.message}`)
    }

    if (!loadTokenConn) {
      if (task12.first || (!task12.task && task12.id) || (task12.task && !task12.task.complete))
        t12Get()
      if (task19.first || (!task19.task && task19.id) || (task19.task && !task19.task.complete))
        t19Get()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [taskInterval, loadTokenConn])

  useEffect(() => {
    let isMounted = true

    const interval = setInterval(() => {
      isMounted && setTaskInterval(prev => !prev)
    }, 5000)

    return () => {
      clearInterval(interval)
      isMounted = false
    }
  }, [])

  const getUsu = (usu) => {
    if (!usu)
      return ''
    else if (usu.docIdentidad)
      return (`${usu.docIdentidad.documento} - ${usu.docIdentidad.denominacion}`).toUpperCase()
    else
      return `${usu.userID} - ${usu.userName}`
  }

  const getAto = (ato, index = null) => {
    var nato = { ...ato, key: `${ato.id}-${ato.linea}`, index, debe: Math.abs(Math.max(ato.monto, 0.0)), haber: Math.abs(Math.min(ato.monto, 0.0)), tipoCtaLabel: ato.tipoCta ?? ato.tipoCtaCode }
    if (index === null)
      delete nato.index
    return nato
  }

  const getTitleDrawer = (ato) => {
    if (!ato || ato.isDet === null || ato.isDet === undefined || (ato.isDet !== 0 && !ato.origen))
      return ''
    else if (ato.isDet === 0 && ato.solRen)
      return `Solicitud ( ${ato.numMV} )`
    else if (ato.isDet === 0 && !ato.solRen)
      return `Rendición ( ${ato.numMV} )`
    else if (ato.isDet === 1 && ato.origen.opeRen)
      return `Operación ( ${ato.numMV} )`
    else if (ato.isDet === 1 && !ato.origen.opeRen)
      return `Rendición ( ${ato.origen.numRD ?? ato.numMV} )`
    else
      return ''
  }

  const clearFiltroAT = (all = true) => {
    if (all) {
      setFoSave(null)
      setAsientos([])
      setAtTotalDocuments(0)
    }
    setSelAsiento(null)
    setSelectedRows([])
    setSelectedRowKeys([])
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
    setSelAsiento(null)
  }

  const handleSelReceptor = useCallback((person) => {
    setSelReceptor(person)
    let a_grupos = []
    if (person && person.grupo.length > 0)
      a_grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem, rcptRUC: person.ruc }))
    setGrupos(a_grupos)
    clearFiltroAT()
  }, [setSelReceptor])

  const handleSelGrupo = useCallback((person) => {
    setSelGrupo(person)
    clearFiltroAT()
  }, [setSelGrupo])

  const handleBuscarAT = async (isCon) => {
    let err = null
    let data = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selGrupo && !allGroup)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    else if (isCon && (!foFechaIni || !foFechaFin))
      err = {
        message: msgType.selectFecha,
        oops: false
      }
    else if (!isCon && !foNumMV)
      err = {
        message: msgType.inputNumMV,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqAto(true)

    const grupo = allGroup ? '' : selGrupo.ruc
    const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
    const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
    const tipoCta = foTipoCta || ''
    const tipoMov = foTipoMov || ''
    const rindente = foRindente || ''
    const numMV = foNumMV || ''

    const url = isCon ?
      atoGetConUrl(selReceptor.ruc, grupo, fechaIni, fechaFin, tipoCta, tipoMov, rindente, resource, ordenAto, 1, atPageSize) :
      atoGetNumUrl(selReceptor.ruc, numMV, 1, atPageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: atos } = data
      if (!isCon && !allGroup && atos.find(p => p.grpCode !== selGrupo.ruc)) {
        setAsientos([])
        setAtTotalDocuments(0)
      }
      else {
        setAsientos(atos.map((ato, index) => getAto(ato, index)))
        setAtTotalDocuments(totalDocuments)
      }
      setAtCurrentPage(1)
      clearFiltroAT(false)
      setFoSave({
        svSelReceptor: selReceptor,
        svGrupo: grupo,
        svFechaIni: fechaIni,
        svFechaFin: fechaFin,
        svTipoCta: tipoCta,
        svTipoMov: tipoMov,
        svRindente: rindente,
        svOrdenAto: ordenAto,
        svNumMV: numMV,
        svIsCon: isCon,
      })
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqAto(false)
    setLastAccess()
  }

  const handleChangePagAT = async (page, pageSize) => {
    if (!foSave)
      return

    const { svSelReceptor, svGrupo, svFechaIni, svFechaFin, svTipoCta, svTipoMov, svRindente, svOrdenAto, svNumMV, svIsCon } = foSave

    let err = null
    let data = null

    setLoadBusqAto(true)

    const url = svIsCon ?
      atoGetConUrl(svSelReceptor.ruc, svGrupo, svFechaIni, svFechaFin, svTipoCta, svTipoMov, svRindente, resource, svOrdenAto, page, pageSize) :
      atoGetNumUrl(svSelReceptor.ruc, svNumMV, page, pageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: atos } = data
      setAsientos(atos.map((ato, index) => getAto(ato, index)))
      setAtTotalDocuments(totalDocuments)
      setAtCurrentPage(page)
      clearFiltroAT(false)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqAto(false)
    setLastAccess()
  }

  const handleBuscarSD = async (tpoSal) => {
    let err = null
    let data = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selGrupo && !allGroup)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    else if (!foFecha)
      err = {
        message: msgType.selectFecha1,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqAto(true)

    const grupo = allGroup ? '' : selGrupo.ruc
    const fecha = foFecha.format().split('T')[0] + 'T00:00:00'
    const tipoCta = foTipoCta || ''
    const rindente = foRindente || ''

    const url = tpoSal === '2' ? atoGetSdocUrl(selReceptor.ruc, grupo, fecha, tipoCta, rindente, resource) :
      (tpoSal === '3' ? atoGetSrinUrl(selReceptor.ruc, grupo, fecha, rindente, resource) :
        atoGetStpoUrl(selReceptor.ruc, grupo, fecha, resource))
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { data: atos } = data
      const sdos = atos.map((ato, index) => getAto({ ...ato, id: index }, index))
      tpoSal === '2' && setSaldosDoc(sdos)
      tpoSal === '3' && setSaldosRin(sdos)
      tpoSal === '4' && setSaldosTpo(sdos)
      clearFiltroAT(false)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqAto(false)
    setLastAccess()
  }

  const handleDetalleAto = (record, isDet) => {
    const ato = { ...record, isDet }
    setSelAsiento(ato)
    setOpenDrawer(true)
  }

  const handleTask12_Add = async () => {
    let err = null
    let data = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selGrupo && !allGroup)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    else if (!foFechaIni || !foFechaFin)
      err = {
        message: msgType.selectFecha,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadTask12Add(true)

    const grupo = allGroup ? '' : selGrupo.ruc
    const fechaIni = foFechaIni.format().split('T')[0] + 'T00:00:00'
    const fechaFin = foFechaFin.format().split('T')[0] + 'T00:00:00'
    const tipoCta = foTipoCta || ''
    const tipoMov = foTipoMov || ''
    const rindente = foRindente || ''

    const url = tsk12Url()
    const [data_api, err_api] = await axiosTnaPost(url, { rcptNumDoc: selReceptor.ruc, grupo, fechaIni, fechaFin, tipoCta, tipoMov, rindente, resource, sort: ordenAto })
    data = data_api
    err = err_api

    if (data) {
      setTask12(prev => ({ ...prev, task: null, id: data.id }))
      notifyGreenRef.current.handleOpen({
        message: msgType.fileRequest,
        oops: false
      }, notifyType.success)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadTask12Add(false)
    setLastAccess()
  }

  const handleTask12_Down = async ({ id, file: record }) => {
    let err = null

    setLoadTask12Down(true)

    const url = tskRecuperarUrl(id)
    const [data, err_api] = await axiosTnaGet(url)
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
        setTask12(prev => ({ ...prev, task: null, id: null }))
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadTask12Down(false)
    setLastAccess()
  }

  const handleTask19_Add = async (tpoSal) => {
    let err = null
    let data = null

    if (!selReceptor)
      err = {
        message: msgType.selectReceptor,
        oops: false
      }
    else if (!selGrupo && !allGroup)
      err = {
        message: msgType.selectGrupo,
        oops: false
      }
    else if (!foFecha)
      err = {
        message: msgType.selectFecha1,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadTask19Add(true)

    const grupo = allGroup ? '' : selGrupo.ruc
    const fecha = foFecha.format().split('T')[0] + 'T00:00:00'
    const tipoCta = foTipoCta || ''
    const rindente = foRindente || ''
    const tipoSal = tpoSal === '2' ? configType.tipoSal_NumDoc : (tpoSal === '3' ? configType.tipoSal_Rendidor : configType.tipoSal_CtaDoc)

    const url = tsk19Url()
    const [data_api, err_api] = await axiosTnaPost(url, { rcptNumDoc: selReceptor.ruc, grupo, fecha, tipoSal, tipoCta, rindente, resource })
    data = data_api
    err = err_api

    if (data) {
      setTask19(prev => ({ ...prev, task: null, id: data.id }))
      notifyGreenRef.current.handleOpen({
        message: msgType.fileRequest,
        oops: false
      }, notifyType.success)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadTask19Add(false)
    setLastAccess()
  }

  const handleTask19_Down = async ({ id, file: record }) => {
    let err = null

    setLoadTask19Down(true)

    const url = tskRecuperarUrl(id)
    const [data, err_api] = await axiosTnaGet(url)
    err = err_api

    if (data) {
      try {
        const { document } = data
        const zip = new jszip()
        const zresult = await zip.loadAsync(document, { base64: true })
        const unzip = await zresult.file(record.fileName).async('base64')
        if (record.contentType === configType.contentTypePDF)
          viewerFile(unzip, record.contentType, record.fileName)
        else
          downloadFile(unzip, record.contentType, record.fileName)
        setTask19(prev => ({ ...prev, task: null, id: null }))
      }
      catch (error) {
        err = {
          message: msgType.apiError,
          detail: error.message,
          oops: true
        }
      }
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadTask19Down(false)
    setLastAccess()
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => confirm()
  const handleReset = (clearFilters, confirm) => { clearFilters(); confirm() }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8, }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Ingrese palabra`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className='text-xs'
          style={{ marginBottom: 8, display: 'block', }}
        />
        <Space>
          <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, }}
          >
            Buscar
          </Button>
          <Button className='flex items-center justify-center text-xs bg-indigo-600 border-indigo-600 text-white hover:bg-indigo-500 hover:border-indigo-600 hover:text-white focus:hover:bg-indigo-500 focus:hover:border-indigo-600 focus:hover:text-white'
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90, }}
          >
            Reiniciar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex === 'numMV') {
        const { numMV } = record
        return numMV.toLowerCase().includes(value.toLowerCase())
      }
      else if (dataIndex === 'rindente') {
        const rindente = getUsu(record.rindente ?? record.solicitante)
        return rindente && rindente.toLowerCase().includes(value.toLowerCase())
      }
      else if (dataIndex === 'fechaMov') {
        const { fechaMov } = record
        const fecha = moment(fechaMov).format('DD/MM/yyyy')
        return fecha.includes(value.toLowerCase())
      }
      else
        return false
    },
  })

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRows(newSelectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: false,
    }),
    type: 'radio',
  }

  const atColumns = [
    {
      title: 'Nº Movimiento',
      dataIndex: 'numMV',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex items-center'>
            <button
              className='text-blue-600 w-full text-left'
              onClick={() => handleDetalleAto(record, 0)}
            >
              <span>{record.numMV}</span>
            </button>
          </div>
          {
            record.origen && (record.solRen || record.origen.opeRen) &&
            <div className='flex justify-end space-x-1'>
              <Tooltip title={record.origen.opeRen ? 'Detalle de operación' : 'Detalle de rendición'} placement="right">
                <button onClick={() => handleDetalleAto(record, 1)}>
                  <EyeOutlined />
                </button>
              </Tooltip>
            </div>
          }
        </div>,
    },
    {
      title: 'Tipo Mov.',
      dataIndex: 'tipoMov',
      width: 90,
    },
    {
      title: 'Fecha Mov.',
      dataIndex: 'fechaMov',
      width: 92,
      render: (_, record) => <span>{formatDate(record.fechaMov)}</span>
    },
    {
      title: '-$-',
      dataIndex: 'monedaISO',
      width: 50,
      align: 'center',
      onCell: (record, rowIndex) => ({
        onClick: event => {
          if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        },
      })
    },
    {
      title: 'Tipo Cta.',
      dataIndex: 'tipoCtaLabel',
      width: 120,
    },
    {
      title: 'Debe',
      dataIndex: 'debe',
      width: 90,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.debe)}</span>
    },
    {
      title: 'Haber',
      dataIndex: 'haber',
      width: 90,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.haber)}</span>
    },
    {
      title: 'Fecha Reg.',
      dataIndex: 'fechaReg',
      width: 150,
      responsive: ['lg'],
      render: (_, record) => <span>{formatTime(record.fechaReg)}</span>
    },
    {
      title: 'Rindente',
      dataIndex: 'rindente',
      width: 340,
      responsive: ['xl'],
      render: (_, record) => <span>{`${allGroup ? (record.grpCode + ' ') : ''} ${getUsu(record.rindente ?? record.solicitante)}`}</span>
    },
  ]

  const sdColumns = [
    {
      title: 'Nº Movimiento',
      dataIndex: 'numMV',
      render: (_, record) =>
        <div className='flex justify-between'>
          <div className='flex items-center'>
            <button
              className='text-blue-600 w-full text-left'
              onClick={() => handleDetalleAto(record, 0)}
            >
              <span>{record.numMV}</span>
            </button>
          </div>
        </div>,
      ...getColumnSearchProps('numMV'),
    },
    {
      title: 'Rindente',
      dataIndex: 'rindente',
      width: 340,
      responsive: ['xl'],
      render: (_, record) => <span>{`${allGroup ? (record.grpCode + ' ') : ''} ${getUsu(record.rindente ?? record.solicitante)}`}</span>,
      ...getColumnSearchProps('rindente'),
    },
    {
      title: 'Fecha Mov.',
      dataIndex: 'fechaMov',
      width: 110,
      render: (_, record) => <span>{formatDate(record.fechaMov)}</span>,
      ...getColumnSearchProps('fechaMov'),
    },
    {
      title: '-$-',
      dataIndex: 'monedaISO',
      width: 60,
      align: 'center',
      onCell: (record, rowIndex) => ({
        onClick: event => {
          if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        },
      }),
      sorter: (a, b) => (a.monedaISO > b.monedaISO ? 1 : -1),
    },
    {
      title: 'Tipo Cta.',
      dataIndex: 'tipoCtaLabel',
      width: 120,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a.tipoCtaLabel.length > b.tipoCtaLabel.length ? 1 : -1),
    },
    {
      title: 'Debe',
      dataIndex: 'debe',
      width: 100,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.debe)}</span>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.debe - b.debe,
    },
    {
      title: 'Haber',
      dataIndex: 'haber',
      width: 100,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.haber)}</span>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.haber - b.haber,
    },
  ]

  const srColumns = [
    {
      title: 'Rindente',
      dataIndex: 'rindente',
      render: (_, record) => <span>{`${allGroup ? (record.grpCode + ' ') : ''} ${getUsu(record.rindente ?? record.solicitante)}`}</span>,
      ...getColumnSearchProps('rindente'),
    },
    {
      title: 'Tipo Doc.',
      dataIndex: 'tipoDoc',
      width: 120,
      responsive: ['xl'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.tipoDoc.length - b.tipoDoc.length,
    },
    {
      title: 'Fecha Mov.',
      dataIndex: 'fechaMov',
      width: 110,
      responsive: ['xl'],
      render: (_, record) => <span>{formatDate(record.fechaMov)}</span>,
      ...getColumnSearchProps('fechaMov'),
    },
    {
      title: '-$-',
      dataIndex: 'monedaISO',
      width: 60,
      align: 'center',
      onCell: (record, rowIndex) => ({
        onClick: event => {
          if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        },
      }),
      sorter: (a, b) => (a.monedaISO > b.monedaISO ? 1 : -1),
    },
    {
      title: 'Tipo Cta.',
      dataIndex: 'tipoCtaLabel',
      width: 120,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a.tipoCtaLabel.length > b.tipoCtaLabel.length ? 1 : -1),
    },
    {
      title: 'Debe',
      dataIndex: 'debe',
      width: 100,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.debe)}</span>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.debe - b.debe,
    },
    {
      title: 'Haber',
      dataIndex: 'haber',
      width: 100,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.haber)}</span>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.haber - b.haber,
    },
  ]

  const stColumns = [
    {
      title: 'Tipo Doc.',
      dataIndex: 'tipoDoc',
      render: (_, record) => <span>{`${allGroup ? (record.grpCode + ' ') : ''} ${record.tipoDoc}`}</span>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.tipoDoc.length - b.tipoDoc.length,
    },
    {
      title: 'Fecha Mov.',
      dataIndex: 'fechaMov',
      width: 110,
      render: (_, record) => <span>{formatDate(record.fechaMov)}</span>,
      ...getColumnSearchProps('fechaMov'),
    },
    {
      title: '-$-',
      dataIndex: 'monedaISO',
      width: 60,
      align: 'center',
      onCell: (record, rowIndex) => ({
        onClick: event => {
          if (selectedRowKeys && selectedRowKeys.length > 0 && selectedRowKeys[0] === record.id) {
            setSelectedRowKeys([])
            setSelectedRows([])
          }
        },
      }),
      sorter: (a, b) => (a.monedaISO > b.monedaISO ? 1 : -1),
    },
    {
      title: 'Tipo Cta.',
      dataIndex: 'tipoCtaLabel',
      render: (_, record) => <span>{`${record.tipoCtaLabel}`}</span>,
      width: 120,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => (a.tipoCtaLabel.length > b.tipoCtaLabel.length ? 1 : -1),
    },
    {
      title: 'Debe',
      dataIndex: 'debe',
      width: 120,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.debe)}</span>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.debe - b.debe,
    },
    {
      title: 'Haber',
      dataIndex: 'haber',
      width: 120,
      align: 'right',
      render: (_, record) => <span>{formatAmount(record.haber)}</span>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.haber - b.haber,
    },
  ]

  return (
    <div className="bg-white flex flex-col flex-grow site-drawer-render-in-current-wrapper">
      <Drawer
        title={getTitleDrawer(selAsiento)}
        placement="right"
        onClose={handleCloseDrawer}
        open={openDrawer}
        getContainer={false}
        style={{ position: 'absolute' }}
        bodyStyle={{ padding: 20, }}
        headerStyle={{ padding: 15, }}
        destroyOnClose={true}
        maskClosable={false}
        zIndex={5}
        width={720}
        closeIcon={<CloseCircleOutlined aria-hidden="true" className='text-blue-900' />}
        extra={
          <Space>
            <></>
          </Space>
        }
      >
        {
          selAsiento ?
            <>
              {
                selAsiento.isDet === 0 ?
                  <>
                    {
                      selAsiento.solRen ?
                        <DetalleSol ref={detSolRef} selReceptor={selReceptor} dbSolicitud={dbSolicitud} /> :
                        <DetalleRen ref={detRenRef} selReceptor={selReceptor} dbRendicion={dbRendicion} />
                    }
                  </> :
                  <>
                    {
                      selAsiento.isDet === 1 && selAsiento.origen ?
                        <>
                          {
                            selAsiento.origen.opeRen ?
                              <DetalleOpe ref={detOpeRef} selReceptor={selReceptor} dbOperacion={dbOperacion} /> :
                              <DetalleRen ref={detRenRef} selReceptor={selReceptor} dbRendicion={dbRendicion} />
                          }
                        </> :
                        <></>
                    }
                  </>
              }
            </> :
            <></>
        }
      </Drawer>
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <div className='flex-grow'>
            <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
              <SelRcptEmsr comboLabel="RECEPTOR" people={receptores} setPerson={handleSelReceptor} flex_basis='basis-96' />
              {
                !allGroup &&
                <SelRcptEmsr comboLabel={selReceptor?.grpName ?? 'Grupo'} people={grupos} setPerson={handleSelGrupo} flex_basis='basis-96' />
              }
              <Switch checkedChildren="ALL" unCheckedChildren="ALL" checked={allGroup} onChange={setAllGroup} />
            </div>

            <div className='p-3 flex flex-col ant-common-titania'>
              <div className="flex flex-row flex-wrap space-x-3 mb-3">
                <div className="flex items-center text-sm text-indigo-700">
                  {
                    ((!task12.id || task12.task?.message) && (!task19.id || task19.task?.message)) ?
                      <>
                        {
                          !loadData ?
                            <Popconfirm
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              title={`¿Confirma la operación?`}
                              onConfirm={() => { foTipoSal === '1' ? handleTask12_Add() : handleTask19_Add(foTipoSal) }}
                              placement="right"
                              okText="Sí"
                              cancelText="No"
                            >
                              <div className='flex items-center cursor-pointer'>
                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                                {`Descargar`}
                              </div>
                            </Popconfirm> :
                            <div className='flex items-center cursor-not-allowed'>
                              <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              {
                                (loadTask12Add || loadTask19Add) &&
                                <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                              }
                              {`Descargar`}
                            </div>
                        }
                      </> :
                      <>
                        {
                          (task12.task?.file || task19.task?.file) ?
                            <div className='flex items-center text-xs'>
                              <Tooltip title="Descargar archivo" placement='right'>
                                <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              </Tooltip>
                              {
                                (loadTask12Down || loadTask19Down) &&
                                <SyncOutlined spin className='mr-2' />
                              }
                              <button
                                className='text-blue-600'
                                disabled={loadData}
                                onClick={() => task12.task?.file ? handleTask12_Down(task12.task) : handleTask19_Down(task19.task)}
                              >
                                {task12.task?.file?.fileName ?? task19.task?.file?.fileName}
                              </button>
                            </div> :
                            <div className='flex items-center text-xs'>
                              <ArrowDownTrayIcon className="mr-1.5 h-5 w-5 text-gray-600" aria-hidden="true" />
                              <SyncOutlined spin className='mr-2' />
                              {`Preparando archivo`}
                            </div>
                        }
                      </>
                  }
                </div>
                {
                  (task12.task?.message || task19.task?.message) &&
                  <div className='flex items-center'>
                    <p className="text-xs text-red-700">
                      {task12.task?.message ?? task19.task?.message}
                    </p>
                  </div>
                }
              </div>

              <p className="text-xs text-gray-700 mb-2">
                <span>{`Busca un ${foTipoSal === '1' ? 'asiento' : 'saldo'} de solicitud de fondo o reembolso usando los filtros de fecha o número de movimiento.`}</span>
              </p>
              <div className='flex space-x-10'>
                <div className='flex items-center space-x-2'>
                  <Select placeholder='Saldo' value={foTipoSal} style={{ width: 140 }} onChange={(v) => setFoTipoSal(v || null)}>
                    {
                      tiposSal.map(({ codigo, nombre }) => (
                        <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                      ))
                    }
                  </Select>
                  {
                    foTipoSal === '1' &&
                    <Tooltip title={ordenAto ? "Ordenar por número" : "Ordenar por fecha"}>
                      <div className='ant-switch-titania'>
                        <Switch checkedChildren={<AppleOutlined />} unCheckedChildren={<AndroidOutlined />}
                          checked={ordenAto} onChange={setOrdenAto} />
                      </div>
                    </Tooltip>
                  }
                  {foTipoSal === '1' && <DatePicker placeholder='Fecha inicio' value={foFechaIni} onChange={(d,) => setFoFechaIni(d || null)} />}
                  {foTipoSal === '1' && <DatePicker placeholder='Fecha fin' value={foFechaFin} onChange={(d,) => setFoFechaFin(d || null)} />}
                  {foTipoSal !== '1' && <DatePicker placeholder='Fecha' value={foFecha} onChange={(d,) => setFoFecha(d || null)} />}
                  {
                    foTipoSal !== '4' &&
                    <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                      {
                        (foTipoSal === '1' || foTipoSal === '2') &&
                        <Select placeholder='Tipo Cta.' value={foTipoCta} allowClear style={{ width: 170 }} onChange={(v) => setFoTipoCta(v || null)}>
                          {
                            tiposCta.map(({ codigo, nombre }) => (
                              <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                            ))
                          }
                        </Select>
                      }
                      {
                        foTipoSal === '1' &&
                        <Select placeholder='Tipo Mov.' value={foTipoMov} allowClear style={{ width: 130 }} onChange={(v) => setFoTipoMov(v || null)}>
                          {
                            tiposMov.map(({ codigo, nombre }) => (
                              <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                            ))
                          }
                        </Select>
                      }
                      <Input placeholder="Rindente" value={foRindente} style={{ width: 200 }} onChange={(e) => setFoRindente(e.target.value)} />
                    </div>
                  }
                  {
                    !loadData ?
                      <Tooltip title={foTipoSal === '1' ? "Buscar asiento" : "Buscar saldo"}>
                        <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => { if (foTipoSal === '1') handleBuscarAT(true); else handleBuscarSD(foTipoSal); }} />
                      </Tooltip> :
                      <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                  }
                </div>
                {
                  foTipoSal === '1' &&
                  <div className='flex items-center space-x-2'>
                    <Input placeholder="Nº de movimiento" style={{ width: 150 }} onChange={(e) => setFoNumMV(e.target.value)} />
                    {
                      !loadData ?
                        <Tooltip title="Buscar asiento">
                          <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={() => handleBuscarAT(false)} />
                        </Tooltip> :
                        <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                    }
                  </div>
                }
              </div>

              <div className='lg:max-w-7xl'>
                {
                  loadBusqAto ?
                    <div className='my-5 flex justify-center'>
                      <Spin tip='Loading....' size='large' />
                    </div> :
                    foTipoSal === '1' ?
                      <>
                        <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-fnconato'>
                          <Table
                            rowSelection={rowSelection}
                            columns={atColumns}
                            dataSource={asientos}
                            pagination={false}
                            scroll={{ y: 400 }}
                            bordered
                            size='small'
                          />
                        </div>
                        <div className='mt-2 flex justify-end ant-pagination-titania'>
                          <Pagination
                            defaultCurrent={atCurrentPage}
                            total={atTotalDocuments}
                            showSizeChanger
                            showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} movimiento`}
                            defaultPageSize={atPageSize}
                            onChange={handleChangePagAT}
                            onShowSizeChange={(_, size) => setAtPageSize(size)}
                            disabled={loadData} />
                        </div>
                      </> :
                      <div className={'ant-table-titania ant-table-rowsel-titania ant-pagination-titania mt-3' + (foTipoSal === '2' ? ' w-[65rem]' : (foTipoSal === '3' ? ' w-[65rem]' : (allGroup ? ' w-[48rem]' : ' w-[45rem]')))}>
                        <Table
                          rowSelection={rowSelection}
                          columns={foTipoSal === '2' ? sdColumns : (foTipoSal === '3' ? srColumns : stColumns)}
                          dataSource={foTipoSal === '2' ? saldosDoc : (foTipoSal === '3' ? saldosRin : saldosTpo)}
                          pagination={{
                            showSizeChanger: true,
                            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros`
                          }}
                          showSizeChanger
                          scroll={{ y: 400 }}
                          bordered
                          size='small'
                        />
                      </div>
                }
              </div>
            </div>
          </div>
      }
      <div className='w-full'>
        <AppFooter />
      </div>
    </div>
  )
}
