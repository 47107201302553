import { ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, SaveOutlined, SearchOutlined, ReloadOutlined, DeleteFilled, ArrowRightOutlined } from '@ant-design/icons'
import { Col, Form, Input, Modal, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { rctGastoUrl, rctGrupoUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { ListaGto } from '../../List/ListaGto'
import { EditRctGcta } from './EditRctGcta'

export const EditRctGto = (props) => {
    const { selReceptor, showNotify } = props

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const [formGto] = Form.useForm()
    const [loadPage, setLoadPage] = useState(false)
    const [loadData, setLoadData] = useState(false)
    const [loadGrupo, setLoadGrupo] = useState(false)
    const [loadReceptor, setLoadReceptor] = useState(false)
    const [loadModReceptor, setLoadModReceptor] = useState(false)
    const [receptor, setReceptor] = useState(null)
    const [grupos, setGrupos] = useState([])
    const [gastos, setGastos] = useState([])
    const [noGastos, setNoGastos] = useState([])
    const [fmGtoCode, setFmGtoCode] = useState(null)
    const [fmGtoName, setFmGtoName] = useState(null)
    const [fmTipoCta, setFmTipoCta] = useState(null)
    const [fmGrupoCta, setFmGrupoCta] = useState([])
    const [selGasto, setSelGasto] = useState(null)
    const [clearGto, setClearGto] = useState(false)
    const [reload, setReload] = useState(false)
    const [guardado, setGuardado] = useState(true)
    const [confirmado, setConfirmado] = useState(true)
    const [openModalGto, setOpenModalGto] = useState(false)
    const [openModalGrp, setOpenModalGrp] = useState(false)

    useEffect(() => {
        setLoadPage(loadReceptor || loadGrupo)
    }, [loadReceptor, loadGrupo])

    useEffect(() => {
        setLoadData(loadModReceptor)
    }, [loadModReceptor])

    useEffect(() => {
        const agto = receptor?.config?.gastos?.sort((a, b) => a.gtoName > b.gtoName ? 1 : -1)?.map((p, i) => getGto(p, i, true, false)) || []
        const sgto = agto.find(p => true) || null

        setGastos(agto)
        setSelGasto(sgto)
        setNoGastos([])
        setGuardado(true)
    }, [receptor])

    useEffect(() => {
        const gtoCode = selGasto?.gtoCode || null
        const gtoName = selGasto?.gtoName || null
        const tipoCta = selGasto?.tipoCta || null
        const grupoCta = selGasto?.grupos || []

        setFmGtoCode(gtoCode)
        setFmGtoName(gtoName)
        setFmTipoCta(tipoCta)
        setFmGrupoCta(grupoCta)
        setConfirmado(true)
        setOpenModalGto(false)
        setOpenModalGrp(false)

        formGto.setFieldsValue({
            gtoCode: gtoCode,
            gtoName: gtoName,
            tipoCta: tipoCta,
        })
    }, [selGasto, grupos, clearGto, formGto])

    useEffect(() => {
        let isMounted = true

        const gtoReceptor = async () => {
            isMounted && setLoadReceptor(true)

            const url = rctGastoUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setReceptor(data.data)
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadReceptor(false)
        }

        const grpReceptor = async () => {
            isMounted && setLoadGrupo(true)

            const url = rctGrupoUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setGrupos(data.data?.config?.grupos?.map(p => ({ grpCode: p.grpCode, grpItem: p.grpItem })) || [])
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadGrupo(false)
        }

        if (selReceptor) {
            gtoReceptor()
            grpReceptor()
        }
        else {
            setReceptor(null)
            setGrupos([])
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [selReceptor, reload])

    // const refreshRct = async (ruc) => {
    //     const url = rctGastoUrl(ruc)
    //     const [data, err] = await axiosTnaGet(url)

    //     if (err)
    //         console.log(`Receptor: ${err.message}`)
    //     const nrct = data?.success ? data.data : null
    //     return nrct
    // }

    const getGto = (gto, index, db, edit) => {
        var ngto = { ...gto, key: index, label: `${index + 1}: ${gto.gtoName.toUpperCase()}`, db, edit }
        return ngto
    }

    const sortGto = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const onValuesChangeGto = (changedValues, allValues) => {
        const gtoCode = allValues.gtoCode ?? null
        const gtoName = allValues.gtoName ?? null
        const tipoCta = allValues.tipoCta ?? null

        setFmGtoCode(gtoCode)
        setFmGtoName(gtoName)
        setFmTipoCta(tipoCta)
        setConfirmado(false)
        setGuardado(false)

        if (changedValues.hasOwnProperty('gtoCode')) {
            const nGtoCode = gtoCode?.trim()?.toUpperCase()
            setFmGtoCode(nGtoCode)
            formGto.setFieldsValue({ gtoCode: nGtoCode })
        }
    }

    const handleChangeGrupo = (gto, agrp) => {
        const sgto = { ...gto, grupos: (agrp && agrp.length > 0 ? [...agrp] : null), edit: true }
        setGastos(prev => {
            const agto = prev.filter(p => p.key !== gto.key)
            const ngto = [...agto, sgto]
            ngto.sort(sortGto)
            return ngto
        })
        setSelGasto(sgto)
        setGuardado(false)
        setOpenModalGrp(false)
    }

    const handleChangeGasto = (value, agto) => {
        const sgto = agto.find(p => p.key === value) || null
        setSelGasto(sgto)
    }

    const handleAgregarGasto = () => {
        let err = null

        if (!fmGtoCode || fmGtoCode.trim() === '')
            err = {
                message: `${msgType.registroNoField} código de gasto.`,
                oops: false
            }
        else if (gastos.find(p => p.gtoCode === fmGtoCode && (!selGasto || selGasto.key !== p.key)))
            err = {
                message: `${msgType.rpDataGtoCode}`,
                oops: false
            }
        else if (!fmGtoName || fmGtoName.trim() === '')
            err = {
                message: `${msgType.registroNoField} nombre de gasto.`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        let maxKey = selGasto ? selGasto.key : (gastos.length > 0 ? Math.max.apply(null, gastos.map(p => p.key)) + 1 : 0)
        const sgto = getGto({
            gtoCode: fmGtoCode.trim(),
            gtoName: fmGtoName.trim(),
            tipoCta: fmTipoCta && fmTipoCta.trim().length !== 0 ? fmTipoCta.trim() : null,
            grupos: fmGrupoCta && fmGrupoCta.length > 0 ? [...fmGrupoCta] : null,
        }, maxKey, (selGasto?.db || false), true)
        setGastos(prev => {
            const agto = selGasto ? prev.filter(p => p.key !== selGasto.key) : prev.filter(p => true)
            const ngto = [...agto, sgto]
            ngto.sort(sortGto)
            return ngto
        })
        setSelGasto(sgto)
        setConfirmado(true)
    }

    const handleQuitarGasto = () => {
        if (selGasto) {
            const agto = gastos.filter(p => p.key !== selGasto.key)
            const sgto = agto.find(p => true) || null
            if (selGasto.db)
                setNoGastos(prev => [...prev, { gtoCode: selGasto.gtoCode }])
            setGastos(agto)
            setSelGasto(sgto)
            setGuardado(false)
        }
    }

    const handleModReceptor = async () => {
        let err = null
        let data = null

        if (!receptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!confirmado)
            err = {
                message: msgType.noConfimGasto,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadModReceptor(true)

        const addGastos = gastos.length > 0 ? gastos.filter(p => p.edit).map(p => ({
            gtoCode: p.gtoCode,
            gtoName: p.gtoName,
            tipoCta: p.tipoCta,
            grupos: p.grupos,
            newEdit: !p.db
        })) : null
        const remGastos = noGastos.length > 0 ? noGastos.map(p => ({ gtoCode: p.gtoCode })) : null

        const url = rctGastoUrl(receptor.id)
        const body = { addGastos, remGastos }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            showNotify({
                message: `${msgType.receptorModificar} ${selReceptor.ruc}`,
                oops: false
            }, notifyType.success)
            setGuardado(true)
            const agto = gastos.map(p => ({ ...p, db: true, edit: false }))
            const sgto = agto.find(p => p.key === selGasto?.key) || null
            setNoGastos([])
            setGastos(agto)
            setSelGasto(sgto)
            //setReceptor(await refreshRct(selReceptor.ruc))
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadModReceptor(false)
        setLastAccess()
    }

    return (
        <div className='flex flex-col ant-common-titania'>
            {
                openModalGto &&
                <Modal
                    open={openModalGto}
                    title={"Buscar gasto"}
                    onOk={() => setOpenModalGto(false)}
                    onCancel={() => setOpenModalGto(false)}
                    footer={[]}
                    width={700}>
                    <ListaGto gastos={gastos} setGasto={(gto) => { gto && handleChangeGasto(gto.key, gastos) }} />
                </Modal>
            }
            {
                openModalGrp &&
                <Modal
                    open={openModalGrp}
                    title={`Elemento de gasto (${selGasto?.gtoCode})`}
                    onOk={() => setOpenModalGrp(false)}
                    onCancel={() => setOpenModalGrp(false)}
                    footer={[]}
                    width={500}>
                    <EditRctGcta grupos_sel={fmGrupoCta} grupos_all={grupos} onTipoCta={true} setGrupos={param => { param && handleChangeGrupo(selGasto, param) }} />
                </Modal>
            }
            {
                loadPage &&
                <div className='flex mt-2 w-full justify-center'>
                    <Spin tip='Loading....' size='large' />
                </div>
            }
            {
                !loadPage &&
                <div className='flex items-center justify-center space-x-2'>
                    <span className='text-gray-600 text-xs'>{'Configuración de elemento de gasto'}</span>
                    {
                        !guardado &&
                        <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                    }
                </div>
            }
            {
                !loadPage &&
                <div className='mt-3'>
                    <div className='mb-1'>
                        <span>Lista de gastos</span>
                    </div>
                    <div className='flex space-x-2'>
                        <Select placeholder='Nuevo gasto' style={{ width: 280 }} value={selGasto?.key}
                            onChange={(v) => handleChangeGasto(v, gastos)} disabled={loadData} allowClear>
                            {
                                gastos.map(({ key, label }) => (
                                    <Select.Option key={key} value={key}>{label}</Select.Option>
                                ))
                            }
                        </Select>
                        {
                            (receptor && gastos.length > 0 && confirmado) &&
                            <Tooltip title="Buscar gasto" placement='top'>
                                <button onClick={() => setOpenModalGto(true)} className='flex items-center'>
                                    <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            !selGasto &&
                            <Tooltip title="Limpiar gasto" placement='top'>
                                <button onClick={() => setClearGto(prev => !prev)} className='flex items-center'>
                                    <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (!selGasto && !confirmado) &&
                            <Tooltip title="Nuevo gasto" placement='top'>
                                <button onClick={handleAgregarGasto} className='flex items-center'>
                                    <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (selGasto && !confirmado) &&
                            <Tooltip title="Modificar gasto" placement='top'>
                                <button onClick={handleAgregarGasto} className='flex items-center'>
                                    <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (selGasto && confirmado) &&
                            <Tooltip title="Quitar gasto" placement='top'>
                                <button onClick={handleQuitarGasto} className='flex items-center'>
                                    <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            !confirmado &&
                            <div className='flex items-center justify-center'>
                                <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className='ant-form-titania'>
                <Form
                    className='mt-4'
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeGto}
                    disabled={loadData}
                    hidden={loadPage}
                    autoComplete="off"
                    form={formGto}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="gtoCode"
                                label="Código de gasto"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese código de gasto'
                                    size='middle'
                                    maxLength={25}
                                    readOnly={selGasto?.db || false}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="tipoCta"
                                label="Cuenta contable"
                                rules={[
                                    {
                                        required: false,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese cuenta contable'
                                    size='middle'
                                    maxLength={15}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="gtoName"
                                label="Nombre de gasto"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese nombre de gasto'
                                    size='middle'
                                    maxLength={100}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            {
                (!loadPage && selGasto && confirmado) &&
                <div className='flex space-x-2 mt-1 items-center'>
                    <span className='text-blue-600 text-xs'>{'Lista de grupos'}</span>
                    <button onClick={() => setOpenModalGrp(true)} className='flex items-center'>
                        <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                    </button>
                    {
                        (fmGrupoCta && fmGrupoCta.length > 0) &&
                        <Tooltip title="Quitar configuración de grupo" placement='top'>
                            <button onClick={() => handleChangeGrupo(selGasto, null)} className='flex items-center'>
                                <DeleteFilled className='text-red-400' style={{ fontSize: '0.9rem' }} />
                            </button>
                        </Tooltip>
                    }
                </div>
            }
            {
                !loadPage &&
                <div className="flex flex-row justify-end space-x-3 mx-1 mt-4">
                    <div className="flex items-center text-sm text-indigo-700">
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?${!guardado ? ' Configuración sin guardar.' : ''}`}
                            onConfirm={() => setReload(prev => !prev)}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Recargar configuración" placement='top'>
                                    <ReloadOutlined className="mr-1.5 text-green-600" aria-hidden="true" />
                                </Tooltip>
                            </div>
                        </Popconfirm>
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleModReceptor}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Guardar config. de gasto" placement='top'>
                                    <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                </Tooltip>
                                Guardar
                            </div>
                        </Popconfirm>
                    </div>
                </div>
            }
        </div>
    )
}
