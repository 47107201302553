export const msgType = {
    apiError: 'Oops, algo salió mal, intente más tarde!',

    loNoEmail: 'Ingrese un correo electrónico.',
    loNoPwd: 'Ingrese una contraseña.',
    loNoUser: 'Ingrese un nombre de usuario.',
    loNoCode: 'Ingrese el código de verificación.',
    loValEmail: 'Correo electrónico inválido.',
    loValPwd: 'Contraseña inválida.',
    loValUser: 'Nombre de usuario inválido.',
    loIdleTime: 'La sesión expiro.',
    loNewToken: 'Oops, sin token!',
    loOKCode: 'Código de verificación enviado, revise su correo.',
    loOKSignUp: 'Cuenta registrada, ya puede iniciar sesión.',
    loOKChangePwd: 'Contraseña actualizada, ya puede iniciar sesión.',

    dabrdNoCodigo: 'Ingrese un código.',
    dabrdAssignCodeOK: 'Còdigo de acceso asignado.',
    dabrdNoRcpt: 'Seleccione un receptor.',
    dabrdNoGroup: 'Seleccione un grupo.',
    dabrdNoEmsr: 'Seleccione un emisor.',
    dabrdNoAnho: 'Seleccione un año.',

    accCodeNoRcpt: 'Seleccione un receptor.',
    accCodeNoGroup: 'Seleccione un grupo.',
    accCodeNoFecha: 'Seleccione una fecha de inicio y fin.',
    accCodeNoRol: 'Seleccione un rol.',
    accCodeBloqueado: 'Acceso bloqueado.',
    accCodeNoRUC: 'Ingrese un número de RUC.',
    accCodeNoUsuario: 'Ingrese una cuenta de usuario.',
    accCodeDelOK: 'Código de acceso eliminado.',
    accCodeRegOK: 'Código de acceso registrado.',
    accCodeMsgCode: 'Código copiado!',
    accCodeMsgUser1: 'Usuario registrado!',
    accCodeMsgUser2: 'Usuario no registrado!',

    regCpeNoRcpt: 'Seleccione un receptor.',
    regCpeNoGroup: 'Seleccione un grupo.',
    regCpeNoEmsr: 'Seleccione un emisor.',
    regCpeNoFecha: 'Seleccione una fecha de inicio y fin.',
    regCpeNoNumOC: 'Ingrese el número de documento.',
    regCpeNoFileS: 'Peso de archivo no permitido.',
    regCpeNoFileE: 'Tipo de archivo no permitido.',
    regCpeNoTag: 'Propiedad requerida:',
    regCpeNoRUC: 'Ingrese un número de RUC.',
    regCpeNoEmisor: 'Emisor de comprobante incorrecto.',
    regCpeNoReceptor: 'Receptor de comprobante incorrecto.',
    regCpeRcpNoSaldo: 'Monto de recepción incorrecto.',
    regCpeRcpMonto: 'Monto de comprobante incorrecto.',
    regCpeFNoNumOC: 'Número de orden de compra incorrecto.',
    regCpeNoMonedaOC: 'Moneda no permitida.',
    regCpeValCpe: 'Comprobante de pago no validado.',
    regCpeValRUC: 'Factoring - RUC no validado.',
    regCpeDetNoNum: 'Detracción - Sin número de constancia.',
    regCpeDetNoFecha: 'Detracción - Sin fecha de pago.',
    regCpeNoEliminar: 'Estado de comprobante no permite eliminar.',
    regCpeNoAdjuntar: 'Estado de comprobante no permite adjuntar.',
    regCpeNoExcluir: 'Estado de comprobante no permite quitar adjunto.',
    regCpeNoData: 'Comprobante sin cargar.',
    regCpeSaveOK: 'Comprobante de pago registrado.',
    regCpeDeleteOK: 'Comprobante de pago eliminado.',
    regCpeNoTipoDocCpe: 'Seleccione un tipo de comprobante.',

    valCpeNoRcpt: 'Seleccione un receptor.',
    valCpeNoGroup: 'Seleccione un grupo.',
    valCpeNoFecha: 'Seleccione una fecha de inicio y fin.',
    valCpeNoMotivo: 'Ingrese un motivo de rechazo.',
    valCpeNoSelReg: 'Seleccione un comprobante.',
    valCpeAprobOK: 'Comprobante de pago aprobado.',
    valCpeRechzOK: 'Comprobante de pago rechazado.',
    valCpeAprobRevOK: 'Aprobación de comprobante revertida.',
    valCpeRechzRevOK: 'Rechazo de comprobante revertido.',

    proCpeNoRcpt: 'Seleccione un receptor.',
    proCpeNoGroup: 'Seleccione un grupo.',
    proCpeNoFecha: 'Seleccione una fecha de inicio y fin.',
    proCpeNoFechaP: 'Ingrese una fecha de programación.',
    proCpeNoMontoP: 'Ingrese un monto de programación.',
    proCpeKoFecha: 'Fecha programada incorrecta.',
    proCpeKoMonto: 'Monto programado incorrecto.',
    proCpePagada: 'Operación no permitida por pago detectado.',
    proCpeNoSel: 'Sin programación seleccionada.',
    proCpeSaveOK: 'Programación registrada.',
    proCpeUpdateOK: 'Programación actualizada.',
    proCpeDeleteOK: 'Programación eliminada.',
    proCpeTask1OK: 'Solicitud de archivo enviada.',
    proCpeTask2OK: 'Tarea de archivo enviada.',
    proCpeNoFileS: 'Peso de archivo no permitido.',
    proCpeNoFileE: 'Tipo de archivo no permitido.',

    stdCpeNoRcpt: 'Seleccione un receptor.',
    stdCpeNoGroup: 'Seleccione un grupo.',
    stdCpeNoEmsr: 'Seleccione un emisor.',
    stdCpeNoFecha: 'Seleccione una fecha de inicio y fin.',
    stdCpeNoSel: 'Sin programación seleccionada.',
    stdCpeNoObserv: 'Ingrese un motivo de rechazo.',
    stdCpePagada: 'Operación no permitida por pago detectado.',
    stdCpeAprobada: 'Operación no permitida por aprobación detectada.',
    stdCpeProgAprOK: 'Programación aprobada.',
    stdCpeProgRecOK: 'Programación rechazada.',
    stdCpeTask3OK: 'Solicitud de archivo enviada.',

    conCpeNoRcpt: 'Seleccione un receptor.',
    conCpeNoGroup: 'Seleccione un grupo.',
    conCpeNoFecha: 'Seleccione una fecha de inicio y fin.',
    conCpeTask4OK: 'Solicitud de archivo enviada.',

    // ER

    selectReceptor: 'Seleccione un receptor.',
    selectGrupo: 'Seleccione grupo.',
    selectFecha: 'Seleccione una fecha de inicio y fin.',
    selectFecha1: 'Seleccione una fecha.',
    selectArea: 'Seleccione un área.',
    selectMoneda: 'Seleccione una moneda.',
    selectBanco: 'Seleccione un banco.',
    selectBancoR: 'Seleccione un banco (rindente).',
    selectCuenta: 'Seleccione una número de cuenta.',
    selectCuentaR: 'Seleccione un número de cuenta (rindente).',
    selectTipoSol: 'Seleccione un tipo de solicitud.',
    selectTipoRen: 'Seleccione un tipo de rendición.',
    selectTipoPago: 'Seleccione un tipo de pago.',
    selectTipoOP: 'Seleccione un tipo de operación.',
    selectTipoDoc: 'Seleccione un tipo de documento.',
    selectRol: 'Seleccione un rol.',
    selectUsuario: 'Seleccione un usuario.',
    selectFondo: 'Seleccione un fondo.',
    selectFondoU: 'Seleccione el último fondo.',
    selectDevolucion: 'Seleccione una devolución.',
    selectDevolucionU: 'Seleccione la última devolución.',
    selectRindente: 'Seleccione un rindente.',
    selectSolicitud: 'Seleccione una solicitud.',
    selectOperacion: 'Seleccione una operación.',
    selectRendicion: 'Seleccione una rendición.',
    selectDocumento: 'Seleccione un documento.',
    selectAcceso: 'Seleccione un acceso.',

    inputNumSO: 'Ingrese un número de solicitud.',
    inputNumRD: 'Ingrese un número de rendición.',
    inputNumOP: 'Ingrese un número de operación.',
    inputNumTran: 'Ingrese un número de transacción.',
    inputNumMV: 'Ingrese un número de movimiento.',
    inputFechaSol: 'Ingrese una fecha de solicitud.',
    inputFechaReq: 'Ingrese una fecha requerida.',
    inputFechaRen: 'Ingrese una fecha de rendición.',
    inputFechaTran: 'Ingrese una fecha de transacción.',
    inputFechaOpe: 'Ingrese una fecha de operación.',
    inputFechaRee: 'Ingrese una fecha inicial y final de reemplazo.',
    inputMontoSol: 'Ingrese un monto solicitado.',
    inputMontoDev: 'Ingrese un monto de devolución.',
    inputMontoOpe: 'Ingrese un monto de operación.',
    inputMotivoSol: 'Ingrese un motivo de solicitud.',
    inputMotivoRec: 'Ingrese un motivo de rechazo.',
    inputObserv: 'Ingrese una observación.',
    inputEmsrDoc: 'Ingrese documento de emisor.',
    inputDocIdent: 'Ingrese documento de identidad.',
    inputTCambio: 'Ingrese tipo de cambio.',
    inputUserID: 'Ingrese una cuenta de usuario.',

    koDataNumTran: 'Número de transacción incorrecto.',
    koDataFechaCtb: 'Fecha de contable anterior a rendición.',
    koDataFechaPago: 'Fecha de pago incorrecta.',
    koDataFechaSol: 'Fecha de solicitud incorrecta.',
    koDataFechaReq: 'Fecha requerida incorrecta.',
    koDataFechaRen: 'Fecha de rendición incorrecta.',
    koDataFechaTran: 'Fecha de transacción incorrecta.',
    koDataFechaDoc: 'Fecha de documento incorrecta.',
    koDataFechaOpe: 'Fecha de operación incorrecta.',
    koDataMontoSol: 'Monto solicitado incorrecto.',
    koDataMontoTran: 'Monto devuelto incorrecto.',
    koDataMontoOpe: 'Monto de operación incorrecto.',
    koDataMotivoSol: 'Motivo de solicitud incorrecto.',
    koDataTipoDoc: 'Tipo de documento incorrecto.',

    noDataUser: 'Sin usuario o inicio de sesión.',
    noDataCcosto: 'Sin centro de costo.',
    noDataTCambioC: 'Sin tipo de cambio de conversión.',

    neDataMontoDevS: 'Monto devuelto distinto al saldo',
    neDataMontoAteT: 'Monto atendido distinto al total',

    saDataUsuario: 'Sin acceso al usuario.',
    rgDataTCambio: 'Tipo de cambio de conversión fuera de rango: ',
    nnDataTipoDoc: 'Tipo de documento no numérico.',
    rpDataTipoAdj: 'Tipo de archivo repetido.',
    rpDataGrpCode: 'Código de grupo repetido.',
    rpDataTipoTrib: 'Tipo de tributo repetido.',
    rpDataCtaBan: 'Cuenta bancaria repetida.',
    rpDataGtoCode: 'Código de gasto repetido.',
    rpDataMoneda: 'Moneda repetida.',
    rpDataTDocMon: 'Tipo de documento y moneda repetido.',
    rpDataReqCode: 'Código de tipo de solicitud repetido.',
    rpDataRendCode: 'Código de tipo de rendición repetido.',
    rpDataDimID: 'Id de costo repetido.',
    rpDataCodeID: 'Id de código repetido.',
    rpDataAreaCode: 'Código de área repetido.',

    noConfimCtaBan: 'Cuenta bancaria sin confirmar.',
    noConfimGrupo: 'Grupo sin confirmar.',
    noConfimGasto: 'Gasto sin confirmar.',
    noConfimCosto: 'Costo sin confirmar.',
    noConfimCodigo: 'Código sin confirmar.',
    noConfimArea: 'Área sin confirmar.',
    noConfimCfgImp: 'Configuración de impuesto sin confirmar.',
    noConfimCfgCpe: 'Configuración de comprobante sin confirmar.',
    noConfimCfgDoc: 'Configuración de documento sin confirmar.',
    noConfimTipoSol: 'Tipo de solicitud sin confirmar.',
    noConfimTipoRen: 'Tipo de rendición sin confirmar.',

    noRegDevolucion: 'Devolución sin registrar.',
    noRegSolicitud: 'Solicitud sin registrar.',
    noRegRendicion: 'Rendición sin registrar.',
    noRegOperacion: 'Operación sin registrar.',
    noRegUsuario: 'Usuario sin registrar.',

    usuarioModificar: 'Usuario modificado:',

    accesoRegistrar: 'Acceso registrado:',
    accesoEliminar: 'Acceso eliminado.',
    accesoModificar: 'Acceso modificado:',

    receptorModificar: 'Receptor modificado:',

    fondoRegistrar: 'Fondo registrado:',
    fondoEliminar: 'Fondo eliminado.',
    devolucionRegistrar: 'Devolución registrada:',
    devolucionEliminar: 'Devolución eliminada.',

    solicitudNoEli: 'Estado de solicitud no permite eliminar.',
    solicitudNoAdj: 'Estado de solicitud no permite adjuntar.',
    solicitudNoApr: 'Estado de solicitud no permite aprobar.',
    solicitudNoRec: 'Estado de solicitud no permite rechazar.',
    solicitudRegistrar: 'Solicitud registrada:',
    solicitudEliminar: 'Solicitud eliminada.',
    solicitudAprobar: 'Solicitud aprobada.',
    solicitudAprobarRev: 'Aprobación de solicitud revertida.',
    solicitudRechazar: 'Solicitud rechazada.',
    solicitudRechazarRev: 'Rechazo de solicitud revertido.',

    operacionRegistrar: 'Operación registrada:',
    operacionEliminar: 'Operación eliminada.',
    operacionModificar: 'Operación modificada.',

    rendicionNoMod: 'Estado de rendición no permite modificar.',
    rendicionNoEli: 'Estado de rendición no permite eliminar.',
    rendicionNoAdj: 'Estado de rendición no permite adjuntar.',
    rendicionNoExc: 'Estado de rendición no permite quitar adjunto.',
    rendicionNoEnv: 'Estado de rendición no permite enviar.',
    rendicionNoEnvRev: 'Estado de rendición no permite revertir envio.',
    rendicionNoDev: 'Estado de rendición no permite devolver.',
    rendicionNoApr: 'Estado de rendición no permite aprobar.',
    rendicionNoAprRev: 'Estado de rendición no permite revertir aprobación.',
    rendicionNoRec: 'Estado de rendición no permite rechazar.',
    rendicionNoRecRev: 'Estado de rendición no permite revertir rechazo.',
    rendicionNoVal: 'Estado de rendición no permite validar.',
    rendicionNoValRev: 'Estado de rendición no permite revertir validación.',
    rendicionNoObs: 'Estado de rendición no permite observar.',
    rendicionNoObsRev: 'Estado de rendición no permite revertir observación.',
    rendicionNoRegDoc: 'Estado de rendición no permite registrar documento.',
    rendicionNoEliDoc: 'Estado de rendición no permite eliminar documento.',
    rendicionRegistrar: 'Rendición registrada:',
    rendicionEliminar: 'Rendición eliminada.',
    rendicionModificar: 'Rendición modificada.',
    rendicionEnviar: 'Rendición enviada',
    rendicionEnviarRev: 'Envio de rendición revertido.',
    rendicionDevolver: 'Rendición devuelva',
    rendicionAprobar: 'Rendición aprobada.',
    rendicionAprobarRev: 'Aprobación de rendición revertida.',
    rendicionRechazar: 'Rendición rechazada.',
    rendicionRechazarRev: 'Rechazo de rendición revertido.',
    rendicionValidar: 'Rendición validada.',
    rendicionValidarRev: 'Validación de rendición revertida.',
    rendicionObservar: 'Rendición observada.',
    rendicionObservarRev: 'Observación de rendición revertida.',

    documentoRegistrar: 'Documento registrado:',
    documentoEliminar: 'Documento eliminado.',
    documentoModificar: 'Documento modificado.',

    apiCtoCpeNoExiste: 'Comprobante no informado.',
    apiCtoCpeAnulado: 'Comprobante comunicado en baja.',
    apiCtoSinRespuesta: 'Sin respuesta de SUNAT.',
    apiCtoOtraRespuesta: 'Respuesta alterada de SUNAT.',

    registroNoSel: 'Seleccione un registro.',
    registroNoOne: 'Seleccione solo un registro.',
    registroNoField: 'Campo requerido:',

    fileNoCPE: 'No es comprobante electrónico.',
    fileKoPeso: 'Peso de archivo no permitido.',
    fileKoXML: 'El archivo debe ser XML.',
    fileKoTXT: 'El archivo debe ser TXT.',
    fileKoReceptor: 'Receptor incorrecto',
    fileKoTipoCpe: 'Tipo de CPE incorrecto',
    fileKoTipo: 'El tipo de archivo debe ser',
    fileRequest: 'Solicitud de archivo enviada.',
}