import { ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, SaveOutlined, SearchOutlined, ReloadOutlined, ArrowRightOutlined, DeleteFilled } from '@ant-design/icons'
import { Col, Form, Input, Modal, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { rctCuentaUrl, rctGrupoUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { ListaCta } from '../../List/ListaCta'
import { EditRctGcta } from './EditRctGcta'

export const EditRctBan = (props) => {
    const { selReceptor, bancos, monedas, showNotify } = props

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const [formCta] = Form.useForm()
    const [loadPage, setLoadPage] = useState(false)
    const [loadData, setLoadData] = useState(false)
    const [loadGrupo, setLoadGrupo] = useState(false)
    const [loadReceptor, setLoadReceptor] = useState(false)
    const [loadModReceptor, setLoadModReceptor] = useState(false)
    const [receptor, setReceptor] = useState(null)
    const [grupos, setGrupos] = useState([])
    const [cuentas, setCuentas] = useState([])
    const [noCuentas, setNoCuentas] = useState([])
    const [fmBanco, setFmBanco] = useState(null)
    const [fmMoneda, setFmMoneda] = useState(null)
    const [fmNumeroCta, setFmNumeroCta] = useState(null)
    const [fmTipoCta, setFmTipoCta] = useState(null)
    const [fmGrupoCta, setFmGrupoCta] = useState([])
    const [selCuenta, setSelCuenta] = useState(null)
    const [clearCta, setClearCta] = useState(false)
    const [reload, setReload] = useState(false)
    const [guardado, setGuardado] = useState(true)
    const [confirmado, setConfirmado] = useState(true)
    const [openModalCta, setOpenModalCta] = useState(false)
    const [openModalGrp, setOpenModalGrp] = useState(false)

    useEffect(() => {
        setLoadPage(loadReceptor || loadGrupo)
    }, [loadReceptor, loadGrupo])

    useEffect(() => {
        setLoadData(loadModReceptor)
    }, [loadModReceptor])

    useEffect(() => {
        const acta = receptor?.config?.cuentas?.sort((a, b) => `${a.bancoID}.${a.monedaISO}.${a.numeroCta}` > `${b.bancoID}.${b.monedaISO}.${b.numeroCta}` ? 1 : -1)?.map((p, i) => getCta(p, i, true, false)) || []
        const scta = acta.find(p => true) || null

        setCuentas(acta)
        setSelCuenta(scta)
        setNoCuentas([])
        setGuardado(true)
    }, [receptor])

    useEffect(() => {
        const banco = bancos.find(p => p.codigo === selCuenta?.bancoID) || null
        const moneda = monedas.find(p => p.codigo === selCuenta?.monedaISO) || null
        const numeroCta = selCuenta?.numeroCta || null
        const tipoCta = selCuenta?.tipoCta || null
        const grupoCta = selCuenta?.grupos || []

        setFmBanco(banco)
        setFmMoneda(moneda)
        setFmNumeroCta(numeroCta)
        setFmTipoCta(tipoCta)
        setFmGrupoCta(grupoCta)
        setConfirmado(true)
        setOpenModalCta(false)
        setOpenModalGrp(false)

        formCta.setFieldsValue({
            bancoID: banco?.codigo,
            monedaISO: moneda?.codigo,
            numeroCta: numeroCta,
            tipoCta: tipoCta,
        })
    }, [selCuenta, bancos, monedas, grupos, clearCta, formCta])

    useEffect(() => {
        let isMounted = true

        const banReceptor = async () => {
            isMounted && setLoadReceptor(true)

            const url = rctCuentaUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setReceptor(data.data)
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadReceptor(false)
        }

        const grpReceptor = async () => {
            isMounted && setLoadGrupo(true)

            const url = rctGrupoUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setGrupos(data.data?.config?.grupos?.map(p => ({ grpCode: p.grpCode, grpItem: p.grpItem })) || [])
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadGrupo(false)
        }

        if (selReceptor) {
            banReceptor()
            grpReceptor()
        }
        else {
            setReceptor(null)
            setGrupos([])
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [selReceptor, reload])

    // const refreshRct = async (ruc) => {
    //     const url = rctCuentaUrl(ruc)
    //     const [data, err] = await axiosTnaGet(url)

    //     if (err)
    //         console.log(`Receptor: ${err.message}`)
    //     const nrct = data?.success ? data.data : null
    //     return nrct
    // }

    const getCta = (cta, index, db, edit) => {
        var ncta = { ...cta, key: index, label: `${index + 1}: ${cta.bancoID} . ${cta.monedaISO} . ${cta.numeroCta}`, db, edit }
        return ncta
    }

    const sortCta = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const onValuesChangeCta = (changedValues, allValues) => {
        const banco = bancos.find(p => p.codigo === allValues.bancoID) || null
        const moneda = monedas.find(p => p.codigo === allValues.monedaISO) || null
        const numeroCta = allValues.numeroCta ?? null
        const tipoCta = allValues.tipoCta ?? null

        setFmBanco(banco)
        setFmMoneda(moneda)
        setFmNumeroCta(numeroCta)
        setFmTipoCta(tipoCta)
        setConfirmado(false)
        setGuardado(false)
    }

    const handleChangeGrupo = (cta, agrp) => {
        const scta = { ...cta, grupos: (agrp && agrp.length > 0 ? [...agrp] : null), edit: true }
        setCuentas(prev => {
            const acta = prev.filter(p => p.key !== cta.key)
            const ncta = [...acta, scta]
            ncta.sort(sortCta)
            return ncta
        })
        setSelCuenta(scta)
        setGuardado(false)
        setOpenModalGrp(false)
    }

    const handleChangeCuenta = (value, acta) => {
        const scta = acta.find(p => p.key === value) || null
        setSelCuenta(scta)
    }

    const handleAgregarCuenta = () => {
        let err = null

        if (!fmNumeroCta || fmNumeroCta.trim() === '')
            err = {
                message: `${msgType.registroNoField} número de cuenta.`,
                oops: false
            }
        else if (cuentas.find(p => p.numeroCta === fmNumeroCta && (!selCuenta || selCuenta.key !== p.key)))
            err = {
                message: `${msgType.rpDataCtaBan}`,
                oops: false
            }
        else if (!fmBanco)
            err = {
                message: `${msgType.registroNoField} banco.`,
                oops: false
            }
        else if (!fmMoneda)
            err = {
                message: `${msgType.registroNoField} moneda.`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        let maxKey = selCuenta ? selCuenta.key : (cuentas.length > 0 ? Math.max.apply(null, cuentas.map(p => p.key)) + 1 : 0)
        const scta = getCta({
            numeroCta: fmNumeroCta.trim(),
            bancoID: fmBanco.codigo,
            monedaISO: fmMoneda.codigo,
            tipoCta: fmTipoCta && fmTipoCta.trim().length !== 0 ? fmTipoCta.trim() : null,
            grupos: fmGrupoCta && fmGrupoCta.length > 0 ? [...fmGrupoCta] : null,
        }, maxKey, (selCuenta?.db || false), true)
        setCuentas(prev => {
            const acta = selCuenta ? prev.filter(p => p.key !== selCuenta.key) : prev.filter(p => true)
            const ncta = [...acta, scta]
            ncta.sort(sortCta)
            return ncta
        })
        setSelCuenta(scta)
        setConfirmado(true)
    }

    const handleQuitarCuenta = () => {
        if (selCuenta) {
            const acta = cuentas.filter(p => p.key !== selCuenta.key)
            const scta = acta.find(p => true) || null
            if (selCuenta.db)
                setNoCuentas(prev => [...prev, { numeroCta: selCuenta.numeroCta }])
            setCuentas(acta)
            setSelCuenta(scta)
            setGuardado(false)
        }
    }

    const handleModReceptor = async () => {
        let err = null
        let data = null

        if (!receptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!confirmado)
            err = {
                message: msgType.noConfimCtaBan,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadModReceptor(true)

        const addCuentas = cuentas.length > 0 ? cuentas.filter(p => p.edit).map(p => ({
            numeroCta: p.numeroCta,
            bancoID: p.bancoID,
            monedaISO: p.monedaISO,
            tipoCta: p.tipoCta,
            grupos: p.grupos,
            newEdit: !p.db
        })) : null
        const remCuentas = noCuentas.length > 0 ? noCuentas.map(p => ({ numeroCta: p.numeroCta })) : null

        const url = rctCuentaUrl(receptor.id)
        const body = { addCuentas, remCuentas }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            showNotify({
                message: `${msgType.receptorModificar} ${selReceptor.ruc}`,
                oops: false
            }, notifyType.success)
            setGuardado(true)
            const acta = cuentas.map(p => ({ ...p, db: true, edit: false }))
            const scta = acta.find(p => p.key === selCuenta?.key) || null
            setNoCuentas([])
            setCuentas(acta)
            setSelCuenta(scta)
            //setReceptor(await refreshRct(selReceptor.ruc))
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadModReceptor(false)
        setLastAccess()
    }

    return (
        <div className='flex flex-col ant-common-titania'>
            {
                openModalCta &&
                <Modal
                    open={openModalCta}
                    title={"Buscar cuenta"}
                    onOk={() => setOpenModalCta(false)}
                    onCancel={() => setOpenModalCta(false)}
                    footer={[]}
                    width={700}>
                    <ListaCta cuentas={cuentas} setCuenta={(cta) => { cta && handleChangeCuenta(cta.key, cuentas) }} />
                </Modal>
            }
            {
                openModalGrp &&
                <Modal
                    open={openModalGrp}
                    title={`Cuenta bancaria (${selCuenta?.bancoID} . ${selCuenta?.monedaISO} . ${selCuenta?.numeroCta})`}
                    onOk={() => setOpenModalGrp(false)}
                    onCancel={() => setOpenModalGrp(false)}
                    footer={[]}
                    width={500}>
                    <EditRctGcta grupos_sel={fmGrupoCta} grupos_all={grupos} onTipoCta={true} setGrupos={param => { param && handleChangeGrupo(selCuenta, param) }} />
                </Modal>
            }
            {
                loadPage &&
                <div className='flex mt-2 w-full justify-center'>
                    <Spin tip='Loading....' size='large' />
                </div>
            }
            {
                !loadPage &&
                <div className='flex items-center justify-center space-x-2'>
                    <span className='text-gray-600 text-xs'>{'Configuración de cuenta bancaria'}</span>
                    {
                        !guardado &&
                        <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                    }
                </div>
            }
            {
                !loadPage &&
                <div className='mt-3'>
                    <div className='mb-1'>
                        <span>Lista de cuentas</span>
                    </div>
                    <div className='flex space-x-2'>
                        <Select placeholder='Nueva cuenta' style={{ width: 280 }} value={selCuenta?.key}
                            onChange={(v) => handleChangeCuenta(v, cuentas)} disabled={loadData} allowClear>
                            {
                                cuentas.map(({ key, label }) => (
                                    <Select.Option key={key} value={key}>{label}</Select.Option>
                                ))
                            }
                        </Select>
                        {
                            (receptor && cuentas.length > 0 && confirmado) &&
                            <Tooltip title="Buscar cuenta" placement='top'>
                                <button onClick={() => setOpenModalCta(true)} className='flex items-center'>
                                    <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            !selCuenta &&
                            <Tooltip title="Limpiar cuenta" placement='top'>
                                <button onClick={() => setClearCta(prev => !prev)} className='flex items-center'>
                                    <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (!selCuenta && !confirmado) &&
                            <Tooltip title="Nueva cuenta" placement='top'>
                                <button onClick={handleAgregarCuenta} className='flex items-center'>
                                    <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (selCuenta && !confirmado) &&
                            <Tooltip title="Modificar cuenta" placement='top'>
                                <button onClick={handleAgregarCuenta} className='flex items-center'>
                                    <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (selCuenta && confirmado) &&
                            <Tooltip title="Quitar cuenta" placement='top'>
                                <button onClick={handleQuitarCuenta} className='flex items-center'>
                                    <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            !confirmado &&
                            <div className='flex items-center justify-center'>
                                <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className='ant-form-titania'>
                <Form
                    className='mt-4'
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeCta}
                    disabled={loadData}
                    hidden={loadPage}
                    autoComplete="off"
                    form={formCta}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="bancoID"
                                label="Banco"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Select placeholder="Seleccione banco"
                                    allowClear>
                                    {
                                        bancos.map(({ codigo, nombre }) => (
                                            <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="monedaISO"
                                label="Moneda"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Select placeholder="Seleccione moneda"
                                    allowClear>
                                    {
                                        monedas.map(({ codigo, nombre }) => (
                                            <Select.Option key={codigo} value={codigo}>{nombre}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="numeroCta"
                                label="Cuenta bancaria"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese cuenta bancaria'
                                    size='middle'
                                    maxLength={25}
                                    readOnly={selCuenta?.db || false}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="tipoCta"
                                label="Cuenta contable"
                                rules={[
                                    {
                                        required: false,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese cuenta contable'
                                    size='middle'
                                    maxLength={15}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            {
                (!loadPage && selCuenta && confirmado) &&
                <div className='flex space-x-2 mt-1 items-center'>
                    <span className='text-blue-600 text-xs'>{'Lista de grupos'}</span>
                    <button onClick={() => setOpenModalGrp(true)} className='flex items-center'>
                        <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                    </button>
                    {
                        (fmGrupoCta && fmGrupoCta.length > 0) &&
                        <Tooltip title="Quitar configuración de grupo" placement='top'>
                            <button onClick={() => handleChangeGrupo(selCuenta, null)} className='flex items-center'>
                                <DeleteFilled className='text-red-400' style={{ fontSize: '0.9rem' }} />
                            </button>
                        </Tooltip>
                    }
                </div>
            }
            {
                !loadPage &&
                <div className="flex flex-row justify-end space-x-3 mx-1 mt-4">
                    <div className="flex items-center text-sm text-indigo-700">
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?${!guardado ? ' Configuración sin guardar.' : ''}`}
                            onConfirm={() => setReload(prev => !prev)}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Recargar configuración" placement='top'>
                                    <ReloadOutlined className="mr-1.5 text-green-600" aria-hidden="true" />
                                </Tooltip>
                            </div>
                        </Popconfirm>
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleModReceptor}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Guardar config. bancaria" placement='top'>
                                    <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                </Tooltip>
                                Guardar
                            </div>
                        </Popconfirm>
                    </div>
                </div>
            }
        </div>
    )
}
