import { ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Alert, AutoComplete, Col, Form, Input, Row, Select, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { msgType } from '../../../../types/msgType'
import { configType } from '../../../../types/configType'

export const EditRctGcta = (props) => {
    const { grupos_sel, grupos_all: grupos, onTipoCta, onCxcCxp, setGrupos } = props

    const [formCta] = Form.useForm()
    const [fmTipoCta, setFmTipoCta] = useState(null)
    const [fmCtaCXC, setFmCtaCXC] = useState(null)
    const [fmCtaCXP, setFmCtaCXP] = useState(null)
    const [registros, setRegistros] = useState([])
    const [selRegistro, setSelRegistro] = useState(null)
    const [selGrupo, setSelGrupo] = useState(null)
    const [opcionesGrp, setOpcionesGrp] = useState([])
    const [dvSearchGrp, setDvSearchGrp] = useState(null)
    const [clearReg, setClearReg] = useState(false)
    const [confirmado, setConfirmado] = useState(true)
    const [messageApp, setMessageApp] = useState(null)

    useEffect(() => {
        const areg = grupos_sel?.map(p => ({ ...p, grpItem: grupos?.find(q => q.grpCode === p.grpCode)?.grpItem }))?.sort((a, b) => a.grpItem > b.grpItem ? 1 : -1)?.map((p, i) => getReg(p, i)) || []
        const sreg = areg.find(p => true) || null

        setRegistros(areg)
        setSelRegistro(sreg)
    }, [grupos_sel, grupos])

    useEffect(() => {
        const tipoCta = onTipoCta ? (selRegistro?.tipoCta || null) : null
        const ctaCXC = onCxcCxp ? (selRegistro?.tipoCta?.ctaCXC || null) : null
        const ctaCXP = onCxcCxp ? (selRegistro?.tipoCta?.ctaCXP || null) : null
        const grupo = grupos.find(p => p.grpCode === selRegistro?.grpCode)
        const opciones = searchResultGrp(grupo?.grpCode, grupos)

        setFmTipoCta(tipoCta)
        setFmCtaCXC(ctaCXC)
        setFmCtaCXP(ctaCXP)
        setSelGrupo(grupo)
        setOpcionesGrp(opciones)
        setDvSearchGrp(grupo?.grpCode)
        setConfirmado(true)

        formCta.setFieldsValue({
            tipoCta: tipoCta,
            ctaCXC: ctaCXC,
            ctaCXP: ctaCXP,
        })
        // eslint-disable-next-line
    }, [selRegistro, grupos, onTipoCta, onCxcCxp, clearReg, formCta])

    const getReg = (reg, index) => {
        var nreg = { ...reg, key: index, label: `${index + 1}: ${reg.grpItem?.toUpperCase() ?? reg.grpCode}` }
        return nreg
    }

    const sortData = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const showAlert = (msg) => {
        setMessageApp(msg)
        setTimeout(() => { setMessageApp(null) }, 5000)
    }

    const compareText = (field, value) => {
        if (value === undefined)
            return false
        else {
            const nvalue = value.toUpperCase()
            const nfield = field.toUpperCase()
            return nfield.indexOf(nvalue) !== -1
        }
    }

    const searchResultGrp = (value, agrp) => {
        const ngrp = agrp.filter(p => value === configType.searchAllCode || compareText(p.grpCode, value) || compareText(p.grpItem, value))
        const aops = ngrp.map(p => ({
            value: p.grpCode,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <span>{`${p.grpItem}`}</span>
                    <span>{`${p.grpCode}`}</span>
                </div>
            ),
        }))
        return aops
    }

    const onValuesChangeCta = (changedValues, allValues) => {
        const tipoCta = allValues.tipoCta ?? null
        const ctaCXC = allValues?.ctaCXC || null
        const ctaCXP = allValues?.ctaCXP || null

        setFmTipoCta(tipoCta)
        setFmCtaCXC(ctaCXC)
        setFmCtaCXP(ctaCXP)
        setConfirmado(false)
    }

    const handleSearchGrp = (value, agrp) => setOpcionesGrp(value ? searchResultGrp(value, agrp) : [])
    const handleChangeGrp = (value) => { handleChangeGrupo(null); setDvSearchGrp(value) }
    const handleSelectGrp = (value, agrp) => { handleChangeGrupo(agrp.find(p => p.grpCode === value) || null); setOpcionesGrp(value ? searchResultGrp(value, agrp) : []) }

    const handleChangeGrupo = (grp) => {
        setSelGrupo(grp)
        setConfirmado(false)
    }

    const handleChangeRegistro = (value, areg) => {
        const sreg = areg.find(p => p.key === value) || null
        setSelRegistro(sreg)
    }

    const handleAgregarRegistro = () => {
        let err = null

        if (!selGrupo)
            err = {
                message: `${msgType.registroNoField} grupo.`,
                oops: false
            }
        else if (registros.find(p => p.grpCode === selGrupo.grpCode && (!selRegistro || selRegistro.key !== p.key)))
            err = {
                message: `${msgType.rpDataGrpCode}`,
                oops: false
            }
        else if (onCxcCxp && (!fmCtaCXC || fmCtaCXC.trim() === '') && fmCtaCXP && fmCtaCXP.trim() !== '')
            err = {
                message: `${msgType.registroNoField} cuenta por cobrar.`,
                oops: false
            }
        else if (onCxcCxp && (!fmCtaCXP || fmCtaCXP.trim() === '') && fmCtaCXC && fmCtaCXC.trim() !== '')
            err = {
                message: `${msgType.registroNoField} cuenta por cobrar.`,
                oops: false
            }

        if (err) {
            showAlert(err.message)
            return
        }

        let maxKey = selRegistro ? selRegistro.key : (registros.length > 0 ? Math.max.apply(null, registros.map(p => p.key)) + 1 : 0)
        const sreg = getReg({
            grpCode: selGrupo?.grpCode,
            grpItem: selGrupo?.grpItem,
            tipoCta: onCxcCxp ? ((fmCtaCXC || fmCtaCXP) ? { ctaCXC: fmCtaCXC, ctaCXP: fmCtaCXP } : null) : (onTipoCta ? (fmTipoCta && fmTipoCta.trim().length !== 0 ? fmTipoCta.trim() : null) : null),
        }, maxKey)
        setRegistros(prev => {
            const areg = selRegistro ? prev.filter(p => p.key !== selRegistro.key) : prev.filter(p => true)
            const nreg = [...areg, sreg]
            nreg.sort(sortData)
            return nreg
        })
        setSelRegistro(sreg)
        setConfirmado(true)
    }

    const handleQuitarRegistro = () => {
        if (selRegistro) {
            const areg = registros.filter(p => p.key !== selRegistro.key)
            const sreg = areg.find(p => true) || null
            setRegistros(areg)
            setSelRegistro(sreg)
        }
    }

    const handleConfirmar = () => {
        let err = null

        if (!confirmado)
            err = {
                message: msgType.noConfimGrupo,
                oops: false
            }

        if (err) {
            showAlert(err.message)
            return
        }

        const nGrupos = registros.map(p => ({ grpCode: p.grpCode, tipoCta: p.tipoCta }))
        if (setGrupos)
            setGrupos(nGrupos)
    }

    return (
        <div className='flex flex-col ant-common-titania'>
            <div>
                <div className='mb-1'>
                    <span>Lista de grupos</span>
                </div>
                <div className='flex space-x-2'>
                    <Select placeholder='Nuevo registro' style={{ width: 280 }} value={selRegistro?.key}
                        onChange={(v) => handleChangeRegistro(v, registros)} allowClear>
                        {
                            registros.map(({ key, label }) => (
                                <Select.Option key={key} value={key}>{label}</Select.Option>
                            ))
                        }
                    </Select>
                    {
                        !selRegistro &&
                        <Tooltip title="Limpiar registro" placement='top'>
                            <button onClick={() => setClearReg(prev => !prev)} className='flex items-center'>
                                <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                            </button>
                        </Tooltip>
                    }
                    {
                        (!selRegistro && !confirmado) &&
                        <Tooltip title="Nuevo registro" placement='top'>
                            <button onClick={handleAgregarRegistro} className='flex items-center'>
                                <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                            </button>
                        </Tooltip>
                    }
                    {
                        (selRegistro && !confirmado) &&
                        <Tooltip title="Modificar registro" placement='top'>
                            <button onClick={handleAgregarRegistro} className='flex items-center'>
                                <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                            </button>
                        </Tooltip>
                    }
                    {
                        (selRegistro && confirmado) &&
                        <Tooltip title="Quitar registro" placement='top'>
                            <button onClick={handleQuitarRegistro} className='flex items-center'>
                                <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                            </button>
                        </Tooltip>
                    }
                    {
                        !confirmado &&
                        <div className='flex items-center justify-center'>
                            <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                        </div>
                    }
                </div>
            </div>
            <div className='mt-3'>
                <div className=' mb-1'>
                    <span className='titania-span-requerido'>*</span>
                    <span>Grupo</span>
                </div>
                <div className='flex space-x-0.5'>
                    <AutoComplete
                        dropdownMatchSelectWidth={500}
                        style={{ width: 230, }}
                        options={opcionesGrp}
                        onSearch={(v) => handleSearchGrp(v, grupos)}
                        onChange={handleChangeGrp}
                        onSelect={(v) => handleSelectGrp(v, grupos)}
                        value={dvSearchGrp}
                    >
                        <Input.Search size="middle" placeholder="Ingrese filtro" />
                    </AutoComplete>
                    <Input readOnly placeholder='Resultado de búsqueda' value={selGrupo?.grpItem} />
                </div>
            </div>
            <div className='ant-form-titania'>
                <Form
                    className='mt-3'
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeCta}
                    autoComplete="off"
                    form={formCta}>
                    <Row gutter={16} hidden={!onTipoCta}>
                        <Col span={12}>
                            <Form.Item
                                name="tipoCta"
                                label="Cuenta contable"
                                rules={[
                                    {
                                        required: false,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese cuenta contable'
                                    size='middle'
                                    maxLength={15}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} hidden={!onCxcCxp}>
                        <Col span={12}>
                            <Form.Item
                                name="ctaCXC"
                                label="Cuenta por cobrar"
                                rules={[
                                    {
                                        required: false,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese número de cuenta'
                                    size='middle'
                                    maxLength={15}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="ctaCXP"
                                label="Cuenta por pagar"
                                rules={[
                                    {
                                        required: false,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese número de cuenta'
                                    size='middle'
                                    maxLength={15}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="flex flex-row flex-wrap space-x-3 mt-3">
                <div className="flex items-center text-[0.8rem] text-indigo-700">
                    <div className='flex items-center cursor-pointer'
                        onClick={handleConfirmar}>
                        <Tooltip title={"Aceptar configuración"} placement='right'>
                            <CheckCircleOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                        </Tooltip>
                        Aceptar
                    </div>
                </div>
            </div>
            {
                messageApp &&
                <div className='mt-4'>
                    <Alert className='text-xs' message={messageApp} type="warning" showIcon closable afterClose={() => setMessageApp(null)} />
                </div>
            }
        </div>
    )
}
