import { CheckCircleOutlined } from '@ant-design/icons'
import { Checkbox, Col, Row, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'

export const EditRctPcta = (props) => {
    const { paramCta, setParamCta } = props

    const [fmEgastoIMP, setFmEgastoIMP] = useState(false)
    const [fmEgastoCGO, setFmEgastoCGO] = useState(false)
    const [fmEgastoDTO, setFmEgastoDTO] = useState(false)
    const [fmPuenteCXP, setFmPuenteCXP] = useState(false)

    useEffect(() => {
        setFmEgastoIMP(paramCta?.egastoIMP || false)
        setFmEgastoCGO(paramCta?.egastoCGO || false)
        setFmEgastoDTO(paramCta?.egastoDTO || false)
        setFmPuenteCXP(paramCta?.puenteCXP || false)
    }, [paramCta])

    return (
        <div className='space-y-3'>
            <div className='flex flex-col items-start'>
                <span className='text-xs text-blue-600 mb-2'>Reemplazar cuenta contable</span>
                <Row>
                    <Col span={24}>
                        <Checkbox className='text-xs' checked={fmEgastoIMP} onChange={(e) => setFmEgastoIMP(e.target.checked)}>Cuenta de gasto por cuenta de impuesto</Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Checkbox className='text-xs' checked={fmEgastoCGO} onChange={(e) => setFmEgastoCGO(e.target.checked)}>Cuenta de gasto por cuenta otros cargos</Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Checkbox className='text-xs' checked={fmEgastoDTO} onChange={(e) => setFmEgastoDTO(e.target.checked)}>Cuenta de gasto por cuenta otros descuentos</Checkbox>
                    </Col>
                </Row>
            </div>
            <div className='flex flex-col items-start'>
                <span className='text-xs text-blue-600 mb-2'>Dinámica contable</span>
                <Row>
                    <Col span={24}>
                        <Checkbox className='text-xs' checked={fmPuenteCXP} onChange={(e) => setFmPuenteCXP(e.target.checked)}>Cuenta puente para cancelar documento</Checkbox>
                    </Col>
                </Row>
            </div>
            <div className="flex flex-row flex-wrap space-x-3">
                <div className="flex items-center text-[0.8rem] text-indigo-700">
                    <div className='flex items-center cursor-pointer'
                        onClick={() => { setParamCta && setParamCta({ egastoIMP: fmEgastoIMP, egastoCGO: fmEgastoCGO, egastoDTO: fmEgastoDTO, puenteCXP: fmPuenteCXP }) }}>
                        <Tooltip title={"Aceptar configuración"} placement='right'>
                            <CheckCircleOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                        </Tooltip>
                        Aceptar
                    </div>
                </div>
            </div>
        </div>
    )
}
