import { ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, SaveOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons'
import { Carousel, Checkbox, Col, Form, Input, Modal, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { rctCostoUrl } from '../../../../api/apiUrl'
import { msgType } from '../../../../types/msgType'
import { notifyType } from '../../../../types/notifyType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { ListaCco } from '../../List/ListaCco'
import { ListaCod } from '../../List/ListaCod'

export const EditRctCco = (props) => {
    const { selReceptor, showNotify } = props

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const [formCco] = Form.useForm()
    const [formCod] = Form.useForm()
    const carouselRef = useRef()
    const [loadPage, setLoadPage] = useState(false)
    const [loadData, setLoadData] = useState(false)
    const [loadReceptor, setLoadReceptor] = useState(false)
    const [loadModReceptor, setLoadModReceptor] = useState(false)
    const [receptor, setReceptor] = useState(null)
    const [costos, setCostos] = useState([])
    const [codigos, setCodigos] = useState([])
    const [noCostos, setNoCostos] = useState([])
    const [fmDimID, setFmDimID] = useState(null)
    const [fmDimName, setFmDimName] = useState(null)
    const [fmRequired, setFmRequired] = useState(false)
    const [fmCodeID, setFmCodeID] = useState(null)
    const [fmCodeName, setFmCodeName] = useState(null)
    const [selCosto, setSelCosto] = useState(null)
    const [selCodigo, setSelCodigo] = useState(null)
    const [clearCco, setClearCco] = useState(false)
    const [clearCod, setClearCod] = useState(false)
    const [reload, setReload] = useState(false)
    const [guardado, setGuardado] = useState(true)
    const [confirmado_CCO, setConfirmado_CCO] = useState(true)
    const [confirmado_COD, setConfirmado_COD] = useState(true)
    const [openModalCco, setOpenModalCco] = useState(false)
    const [openModalCod, setOpenModalCod] = useState(false)

    useEffect(() => {
        setLoadPage(loadReceptor)
    }, [loadReceptor])

    useEffect(() => {
        setLoadData(loadModReceptor)
    }, [loadModReceptor])

    useEffect(() => {
        const acco = receptor?.config?.costos?.sort((a, b) => a.dimName > b.dimName ? 1 : -1)?.map((p, i) => {
            const ncco = getCco(p, i, true, false)
            const mcco = { ...ncco, codigos: ncco.codigos?.sort((a, b) => a.codeName > b.codeName ? 1 : -1)?.map((p, i) => getCod(p, i, true, false)) || [], noCodigos: [] }
            return mcco
        }) || []
        const scco = acco.find(p => true) || null

        setCostos(acco)
        setSelCosto(scco)
        setNoCostos([])
        setGuardado(true)

        if (carouselRef?.current)
            carouselRef.current.goTo(0);
    }, [receptor])

    useEffect(() => {
        const dimID = selCosto?.dimID || null
        const dimName = selCosto?.dimName || null
        const required = selCosto?.required || false
        const acod = selCosto?.codigos || []
        const scod = acod.find(p => true) || null

        setFmDimID(dimID)
        setFmDimName(dimName)
        setFmRequired(required)
        setConfirmado_CCO(true)
        setOpenModalCco(false)
        setCodigos(acod)
        setSelCodigo(scod)

        formCco.setFieldsValue({
            dimID: dimID,
            dimName: dimName,
        })
    }, [selCosto, clearCco, formCco])

    useEffect(() => {
        const codeID = selCodigo?.codeID || null
        const codeName = selCodigo?.codeName || null

        setFmCodeID(codeID)
        setFmCodeName(codeName)
        setConfirmado_COD(true)
        setOpenModalCod(false)

        formCod.setFieldsValue({
            codeID: codeID,
            codeName: codeName,
        })
    }, [selCodigo, clearCod, formCod])

    useEffect(() => {
        let isMounted = true

        const ccoReceptor = async () => {
            isMounted && setLoadReceptor(true)

            const url = rctCostoUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setReceptor(data.data)
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadReceptor(false)
        }

        if (selReceptor)
            ccoReceptor()
        else
            setReceptor(null)

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [selReceptor, reload])

    // const refreshRct = async (ruc) => {
    //     const url = rctCostoUrl(ruc)
    //     const [data, err] = await axiosTnaGet(url)

    //     if (err)
    //         console.log(`Receptor: ${err.message}`)
    //     const nrct = data?.success ? data.data : null
    //     return nrct
    // }

    const getCco = (cco, index, db, edit) => {
        var ncco = { ...cco, key: index, label: `${index + 1}: ${cco.dimName.toUpperCase()}`, db, edit }
        return ncco
    }

    const getCod = (cod, index, db, edit) => {
        var ncod = { ...cod, key: index, label: `${index + 1}: ${cod.codeName.toUpperCase()}`, db, edit }
        return ncod
    }

    const sortData = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const onValuesChangeCco = (changedValues, allValues) => {
        const dimID = allValues.dimID ?? null
        const dimName = allValues.dimName ?? null

        setFmDimID(dimID)
        setFmDimName(dimName)
        setConfirmado_CCO(false)
        setGuardado(false)

        if (changedValues.hasOwnProperty('dimID')) {
            const nDimID = dimID?.trim()?.toUpperCase()
            setFmDimID(nDimID)
            formCco.setFieldsValue({ dimID: nDimID })
        }
    }

    const onValuesChangeCod = (changedValues, allValues) => {
        const codeID = allValues.codeID ?? null
        const codeName = allValues.codeName ?? null

        setFmCodeID(codeID)
        setFmCodeName(codeName)
        setConfirmado_COD(false)
        setGuardado(false)

        if (changedValues.hasOwnProperty('codeID')) {
            const nCodeID = codeID?.trim()?.toUpperCase()
            setFmCodeID(nCodeID)
            formCod.setFieldsValue({ codeID: nCodeID })
        }
    }

    const handleChangeCosto = (value, acco) => {
        const scco = acco.find(p => p.key === value) || null
        setSelCosto(scco)
    }

    const handleChangeCodigo = (value, acod) => {
        const scod = acod.find(p => p.key === value) || null
        setSelCodigo(scod)
    }

    const handleAgregarCosto = () => {
        let err = null

        if (!fmDimID || fmDimID.trim() === '')
            err = {
                message: `${msgType.registroNoField} ID de costo.`,
                oops: false
            }
        else if (costos.find(p => p.dimID === fmDimID && (!selCosto || selCosto.key !== p.key)))
            err = {
                message: `${msgType.rpDataDimID}`,
                oops: false
            }
        else if (!fmDimName || fmDimName.trim() === '')
            err = {
                message: `${msgType.registroNoField} nombre de costo.`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        let maxKey = selCosto ? selCosto.key : (costos.length > 0 ? Math.max.apply(null, costos.map(p => p.key)) + 1 : 0)
        const acod = selCosto?.codigos || []
        const ncod = selCosto?.noCodigos || []
        const scco = getCco({
            dimID: fmDimID.trim(),
            dimName: fmDimName.trim(),
            required: fmRequired,
            codigos: [...acod],
            noCodigos: [...ncod],
        }, maxKey, (selCosto?.db || false), true)
        setCostos(prev => {
            const acco = selCosto ? prev.filter(p => p.key !== selCosto.key) : prev.filter(p => true)
            const ncco = [...acco, scco]
            ncco.sort(sortData)
            return ncco
        })
        setSelCosto(scco)
        setConfirmado_CCO(true)
    }

    const handleQuitarCosto = () => {
        if (selCosto) {
            const acco = costos.filter(p => p.key !== selCosto.key)
            const scco = acco.find(p => true) || null
            if (selCosto.db)
                setNoCostos(prev => [...prev, { dimID: selCosto.dimID }])
            setCostos(acco)
            setSelCosto(scco)
            setGuardado(false)
        }
    }

    const handleAgregarCodigo = () => {
        if (selCosto) {
            let err = null

            if (!fmCodeID || fmCodeID.trim() === '')
                err = {
                    message: `${msgType.registroNoField} ID de código.`,
                    oops: false
                }
            else if (codigos.find(p => p.codeID === fmCodeID && (!selCodigo || selCodigo.key !== p.key)))
                err = {
                    message: `${msgType.rpDataCodeID}`,
                    oops: false
                }
            else if (!fmCodeName || fmCodeName.trim() === '')
                err = {
                    message: `${msgType.registroNoField} nombre de código.`,
                    oops: false
                }

            if (err) {
                showNotify(err, notifyType.warning)
                return
            }

            let maxKey = selCodigo ? selCodigo.key : (codigos.length > 0 ? Math.max.apply(null, codigos.map(p => p.key)) + 1 : 0)
            const scod = getCod({
                codeID: fmCodeID.trim(),
                codeName: fmCodeName.trim(),
            }, maxKey, (selCodigo?.db || false), true)
            const ocod = selCodigo ? codigos.filter(p => p.key !== selCodigo.key) : codigos.filter(p => true)
            const acod = [...ocod, scod]
            acod.sort(sortData)

            const scco = { ...selCosto, codigos: [...acod], edit: true }
            const occo = costos.filter(p => p.key !== selCosto.key)
            const acco = [...occo, scco]
            acco.sort(sortData)

            setCodigos(acod)
            setSelCodigo(scod)
            setCostos(acco)
            setConfirmado_COD(true)
        }
    }

    const handleQuitarCodigo = () => {
        if (selCosto && selCodigo) {
            const acod = codigos.filter(p => p.key !== selCodigo.key)
            const scod = acod.find(p => true) || null
            const mcod = selCosto.noCodigos || []
            const ncod = selCodigo.db ? [...mcod, { codeID: selCodigo.codeID }] : mcod

            const scco = { ...selCosto, codigos: [...acod], noCodigos: ncod, edit: true }
            const occo = costos.filter(p => p.key !== selCosto.key)
            const acco = [...occo, scco]
            acco.sort(sortData)

            setCodigos(acod)
            setSelCodigo(scod)
            setCostos(acco)
            setGuardado(false)
        }
    }

    const handleModReceptor = async () => {
        let err = null
        let data = null

        if (!receptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!confirmado_CCO)
            err = {
                message: msgType.noConfimCosto,
                oops: false
            }
        else if (!confirmado_COD)
            err = {
                message: msgType.noConfimCodigo,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadModReceptor(true)

        const addCostos = costos.length > 0 ? costos.filter(p => p.edit).map(p => ({
            dimID: p.dimID,
            dimName: p.dimName,
            required: p.required,
            addCodigos: (p.codigos.length > 0 ? p.codigos.filter(q => q.edit).map(q => ({
                codeID: q.codeID,
                codeName: q.codeName,
                newEdit: !q.db
            })) : null),
            remCodigos: p.noCodigos.length > 0 ? p.noCodigos.map(q => ({ codeID: q.codeID })) : null,
            newEdit: !p.db,
        })) : null
        const remCostos = noCostos.length > 0 ? noCostos.map(p => ({ dimID: p.dimID })) : null

        const url = rctCostoUrl(receptor.id)
        const body = { addCostos, remCostos }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            showNotify({
                message: `${msgType.receptorModificar} ${selReceptor.ruc}`,
                oops: false
            }, notifyType.success)
            setGuardado(true)
            const acco = costos.map(p => ({ ...p, codigos: p.codigos.map(q => ({ ...q, db: true, edit: false })), noCodigos: [], db: true, edit: false }))
            const scco = acco.find(p => p.key === selCosto?.key) || null
            setNoCostos([])
            setCostos(acco)
            setSelCosto(scco)
            // setReceptor(await refreshRct(selReceptor.ruc))
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadModReceptor(false)
        setLastAccess()
        if (carouselRef?.current)
            carouselRef.current.goTo(0);
    }

    return (
        <div className='flex flex-col ant-common-titania'>
            {
                openModalCco &&
                <Modal
                    open={openModalCco}
                    title={"Buscar costo"}
                    onOk={() => setOpenModalCco(false)}
                    onCancel={() => setOpenModalCco(false)}
                    footer={[]}
                    width={600}>
                    <ListaCco costos={costos} setCosto={(cco) => { cco && handleChangeCosto(cco.key, costos) }} />
                </Modal>
            }
            {
                openModalCod &&
                <Modal
                    open={openModalCod}
                    title={`Buscar código (${selCosto?.dimID})`}
                    onOk={() => setOpenModalCod(false)}
                    onCancel={() => setOpenModalCod(false)}
                    footer={[]}
                    width={600}>
                    <ListaCod codigos={codigos} setCodigo={(cod) => { cod && handleChangeCodigo(cod.key, codigos) }} />
                </Modal>
            }
            {
                loadPage &&
                <div className='flex mt-2 w-full justify-center'>
                    <Spin tip='Loading....' size='large' />
                </div>
            }
            {
                !loadPage &&
                <div className='flex items-center justify-center space-x-2'>
                    <span className='text-gray-600 text-xs'>{'Configuración de centro de costo'}</span>
                    {
                        !guardado &&
                        <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                    }
                </div>
            }
            <div className={'ant-carousel-titania' + (loadPage ? ' hidden' : '')}>
                <Carousel ref={carouselRef}>
                    <div>
                        {
                            !loadPage &&
                            <div className='mt-3'>
                                <div className='mb-1'>
                                    <span>Lista de costos</span>
                                </div>
                                <div className='flex space-x-2'>
                                    <Select placeholder='Nuevo costo' style={{ width: 280 }} value={selCosto?.key}
                                        onChange={(v) => handleChangeCosto(v, costos)} disabled={loadData} allowClear>
                                        {
                                            costos.map(({ key, label }) => (
                                                <Select.Option key={key} value={key}>{label}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                    {
                                        (receptor && costos.length > 0 && confirmado_CCO) &&
                                        <Tooltip title="Buscar costo" placement='top'>
                                            <button onClick={() => setOpenModalCco(true)} className='flex items-center'>
                                                <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        !selCosto &&
                                        <Tooltip title="Limpiar gasto" placement='top'>
                                            <button onClick={() => setClearCco(prev => !prev)} className='flex items-center'>
                                                <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        (!selCosto && !confirmado_CCO) &&
                                        <Tooltip title="Nuevo costo" placement='top'>
                                            <button onClick={handleAgregarCosto} className='flex items-center'>
                                                <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        (selCosto && !confirmado_CCO) &&
                                        <Tooltip title="Modificar costo" placement='top'>
                                            <button onClick={handleAgregarCosto} className='flex items-center'>
                                                <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        (selCosto && confirmado_CCO) &&
                                        <Tooltip title="Quitar gasto" placement='top'>
                                            <button onClick={handleQuitarCosto} className='flex items-center'>
                                                <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        !confirmado_CCO &&
                                        <div className='flex items-center justify-center'>
                                            <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <div className='ant-form-titania'>
                            <Form
                                className='mt-4'
                                layout="vertical" requiredMark
                                onValuesChange={onValuesChangeCco}
                                disabled={loadData}
                                hidden={loadPage}
                                autoComplete="off"
                                form={formCco}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="dimID"
                                            label="ID de costo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese ID de gasto'
                                                size='middle'
                                                maxLength={25}
                                                readOnly={selCosto?.db || false}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="dimName"
                                            label="Nombre de costo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese nombre de costo'
                                                size='middle'
                                                maxLength={100}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className='flex space-x-2 mt-1'>
                            <div className='flex flex-col'>
                                <div>
                                    <Checkbox className='text-xs' checked={fmRequired} onChange={(e) => { setFmRequired(e.target.checked); setConfirmado_CCO(false); setGuardado(false) }}>Centro de costo requirido</Checkbox>
                                </div>
                            </div>
                        </div>
                        <div className='mb-10'></div>
                    </div>
                    <div>
                        {
                            !loadPage &&
                            <div className='mt-3'>
                                <div className='mb-1'>
                                    <span>{`Lista de códigos (${selCosto?.dimID ?? ''})`}</span>
                                </div>
                                <div className='flex space-x-2'>
                                    <Select placeholder='Nuevo código' style={{ width: 280 }} value={selCodigo?.key}
                                        onChange={(v) => handleChangeCodigo(v, codigos)} disabled={loadData || !selCosto} allowClear>
                                        {
                                            codigos.map(({ key, label }) => (
                                                <Select.Option key={key} value={key}>{label}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                    {
                                        (receptor && codigos.length > 0 && confirmado_COD) &&
                                        <Tooltip title="Buscar código" placement='top'>
                                            <button onClick={() => setOpenModalCod(true)} className='flex items-center'>
                                                <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        (!selCodigo && selCosto) &&
                                        <Tooltip title="Limpiar código" placement='top'>
                                            <button onClick={() => setClearCod(prev => !prev)} className='flex items-center'>
                                                <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        (!selCodigo && !confirmado_COD) &&
                                        <Tooltip title="Nuevo código" placement='top'>
                                            <button onClick={handleAgregarCodigo} className='flex items-center'>
                                                <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        (selCodigo && !confirmado_COD) &&
                                        <Tooltip title="Modificar código" placement='top'>
                                            <button onClick={handleAgregarCodigo} className='flex items-center'>
                                                <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        (selCodigo && confirmado_COD) &&
                                        <Tooltip title="Quitar código" placement='top'>
                                            <button onClick={handleQuitarCodigo} className='flex items-center'>
                                                <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                            </button>
                                        </Tooltip>
                                    }
                                    {
                                        !confirmado_COD &&
                                        <div className='flex items-center justify-center'>
                                            <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <div className='ant-form-titania'>
                            <Form
                                className='mt-4'
                                layout="vertical" requiredMark
                                onValuesChange={onValuesChangeCod}
                                disabled={loadData || !selCosto}
                                hidden={loadPage}
                                autoComplete="off"
                                form={formCod}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="codeID"
                                            label="ID de código"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese ID de código'
                                                size='middle'
                                                maxLength={25}
                                                readOnly={selCodigo?.db || false}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="codeName"
                                            label="Descripción de código"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Ingrese descripción de código'
                                                size='middle'
                                                maxLength={100}
                                                style={{
                                                    width: '100%',
                                                }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Carousel>
            </div>
            {
                !loadPage &&
                <div className="flex flex-row justify-end space-x-3 mx-1 mt-4">
                    <div className="flex items-center text-sm text-indigo-700">
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?${!guardado ? ' Configuración sin guardar.' : ''}`}
                            onConfirm={() => setReload(prev => !prev)}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Recargar configuración" placement='top'>
                                    <ReloadOutlined className="mr-1.5 text-green-600" aria-hidden="true" />
                                </Tooltip>
                            </div>
                        </Popconfirm>
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleModReceptor}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Guardar config. de gasto" placement='top'>
                                    <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                </Tooltip>
                                Guardar
                            </div>
                        </Popconfirm>
                    </div>
                </div>
            }
        </div>
    )
}
