import { ClearOutlined, PlusCircleOutlined, MinusCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, SaveOutlined, SearchOutlined, ArrowRightOutlined, DeleteFilled, ReloadOutlined } from '@ant-design/icons'
import { Col, Form, Input, Modal, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAxiosTna } from '../../../../hooks/useAxiosTna'
import { rctGrupoUrl } from '../../../../api/apiUrl'
import { notifyType } from '../../../../types/notifyType'
import { msgType } from '../../../../types/msgType'
import { setLastAccess } from '../../../../utils/lstorageUtil'
import { ListaGrp } from '../../List/ListaGrp'
import { EditRctPren } from './EditRctPren'
import { EditRctPcta } from './EditRctPcta'
import { EditRctTcta } from './EditRctTcta'

export const EditRctGrp = (props) => {
    const { selReceptor, tiposTrib, tiposDoc, monedas, showNotify } = props

    const { axiosTnaGet, axiosTnaPut } = useAxiosTna()
    const [formDta] = Form.useForm()
    const [formGrp] = Form.useForm()
    const [loadPage, setLoadPage] = useState(false)
    const [loadData, setLoadData] = useState(false)
    const [loadReceptor, setLoadReceptor] = useState(false)
    const [loadModReceptor, setLoadModReceptor] = useState(false)
    const [receptor, setReceptor] = useState(null)
    const [grupos, setGrupos] = useState([])
    const [noGrupos, setNoGrupos] = useState([])
    const [fmGrpName, setFmGrpName] = useState(null)
    const [fmGrpCode, setFmGrpCode] = useState(null)
    const [fmGrpItem, setFmGrpItem] = useState(null)
    const [fmParamRen, setFmParamRen] = useState(null)
    const [fmParamCta, setFmParamCta] = useState(null)
    const [fmTipoCta, setFmTipoCta] = useState(null)
    const [selGrupo, setSelGrupo] = useState(null)
    const [clearGrp, setClearGrp] = useState(false)
    const [reload, setReload] = useState(false)
    const [guardado, setGuardado] = useState(true)
    const [confirmado, setConfirmado] = useState(true)
    const [openModalGrp, setOpenModalGrp] = useState(false)
    const [openModalPren, setOpenModalPren] = useState(false)
    const [openModalPcta, setOpenModalPcta] = useState(false)
    const [openModalTcta, setOpenModalTcta] = useState(false)

    useEffect(() => {
        setLoadPage(loadReceptor)
    }, [loadReceptor])

    useEffect(() => {
        setLoadData(loadModReceptor)
    }, [loadModReceptor])

    useEffect(() => {
        const grpName = receptor?.config?.nombre
        const agrp = receptor?.config?.grupos?.sort((a, b) => a.grpItem > b.grpItem ? 1 : -1)?.map((p, i) => getGrp(p, i, true, false)) || []
        const sgrp = agrp.find(p => true) || null

        setFmGrpName(grpName)
        setGrupos(agrp)
        setSelGrupo(sgrp)
        setNoGrupos([])
        setGuardado(true)

        formDta.setFieldsValue({
            grpName: grpName,
        })
    }, [receptor, formDta])

    useEffect(() => {
        const grpCode = selGrupo?.grpCode || null
        const grpItem = selGrupo?.grpItem || null
        const paramRen = selGrupo?.paramRen || null
        const paramCta = selGrupo?.paramCta || null
        const tipoCta = selGrupo?.tipoCta || null

        setFmGrpCode(grpCode)
        setFmGrpItem(grpItem)
        setFmParamRen(paramRen)
        setFmParamCta(paramCta)
        setFmTipoCta(tipoCta)
        setConfirmado(true)
        setOpenModalGrp(false)
        setOpenModalPcta(false)
        setOpenModalTcta(false)

        formGrp.setFieldsValue({
            grpCode: grpCode,
            grpItem: grpItem,
        })
    }, [selGrupo, clearGrp, formGrp])

    useEffect(() => {
        let isMounted = true

        const grpReceptor = async () => {
            isMounted && setLoadReceptor(true)

            const url = rctGrupoUrl(selReceptor.ruc)
            const [data, err] = await axiosTnaGet(url)

            if (data && data.success)
                isMounted && setReceptor(data.data)
            if (err)
                showNotify(err, notifyType.error)

            isMounted && setLoadReceptor(false)
        }

        if (selReceptor)
            grpReceptor()
        else
            setReceptor(null)

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [selReceptor, reload])

    // const refreshRct = async (ruc) => {
    //     const url = rctGrupoUrl(ruc)
    //     const [data, err] = await axiosTnaGet(url)

    //     if (err)
    //         console.log(`Receptor: ${err.message}`)
    //     const nrct = data?.success ? data.data : null
    //     return nrct
    // }

    const getGrp = (grp, index, db, edit) => {
        var ngrp = { ...grp, key: index, label: `${index + 1}: ${grp.grpItem.toUpperCase()}`, db, edit }
        return ngrp
    }

    const sortGrp = (a, b) => {
        if (a.key > b.key)
            return 1
        else
            return -1
    }

    const onValuesChangeDta = (changedValues, allValues) => {
        const grpName = allValues.grpName ?? null
        setFmGrpName(grpName)
        setGuardado(false)
    }

    const onValuesChangeGrp = (changedValues, allValues) => {
        const grpCode = allValues.grpCode ?? null
        const grpItem = allValues.grpItem ?? null

        setFmGrpCode(grpCode)
        setFmGrpItem(grpItem)
        setConfirmado(false)
        setGuardado(false)

        if (changedValues.hasOwnProperty('grpCode')) {
            const nGrpCode = grpCode?.trim()?.toUpperCase()
            setFmGrpCode(nGrpCode)
            formGrp.setFieldsValue({ grpCode: nGrpCode })
        }
    }

    const handleChangeGrupo = (value, agrp) => {
        const sgrp = agrp.find(p => p.key === value) || null
        setSelGrupo(sgrp)
    }

    const handleChangePren = (grp, param) => {
        if (param) {
            const { editTCambioRen, editTCambioOpe, ctaNacionalOpe } = param
            let nparam = null
            if (editTCambioRen || editTCambioOpe || ctaNacionalOpe)
                nparam = { editTCambioRen, editTCambioOpe, ctaNacionalOpe }
            const sgrp = { ...grp, paramRen: nparam, edit: true }
            setGrupos(prev => {
                const agrp = prev.filter(p => p.key !== grp.key)
                const ngrp = [...agrp, sgrp]
                ngrp.sort(sortGrp)
                return ngrp
            })
            setSelGrupo(sgrp)
            setGuardado(false)
            setOpenModalPren(false)
        }
    }

    const handleChangePcta = (grp, param) => {
        if (param) {
            const { egastoIMP, egastoCGO, egastoDTO, puenteCXP } = param
            let nparam = null
            if (egastoIMP || egastoCGO || egastoDTO || puenteCXP)
                nparam = { egastoIMP, egastoCGO, egastoDTO, puenteCXP }
            const sgrp = { ...grp, paramCta: nparam, edit: true }
            setGrupos(prev => {
                const agrp = prev.filter(p => p.key !== grp.key)
                const ngrp = [...agrp, sgrp]
                ngrp.sort(sortGrp)
                return ngrp
            })
            setSelGrupo(sgrp)
            setGuardado(false)
            setOpenModalPcta(false)
        }
    }

    const handleChangeTcta = (grp, tipo) => {
        const sgrp = { ...grp, tipoCta: (tipo ? { ...tipo } : null), edit: true }
        setGrupos(prev => {
            const agrp = prev.filter(p => p.key !== grp.key)
            const ngrp = [...agrp, sgrp]
            ngrp.sort(sortGrp)
            return ngrp
        })
        setSelGrupo(sgrp)
        setGuardado(false)
        setOpenModalTcta(false)
    }

    const handleAgregarGrupo = () => {
        let err = null

        if (!fmGrpCode || fmGrpCode.trim() === '')
            err = {
                message: `${msgType.registroNoField} código de grupo.`,
                oops: false
            }
        else if (!fmGrpItem || fmGrpItem.trim() === '')
            err = {
                message: `${msgType.registroNoField} nombre de grupo.`,
                oops: false
            }
        else if (!selGrupo && grupos.find(p => p.grpCode === fmGrpCode))
            err = {
                message: `${msgType.rpDataGrpCode}`,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        let maxKey = selGrupo ? selGrupo.key : (grupos.length > 0 ? Math.max.apply(null, grupos.map(p => p.key)) + 1 : 0)
        const sgrp = getGrp({
            grpCode: fmGrpCode.trim(),
            grpItem: fmGrpItem.trim(),
            paramRen: fmParamRen ? { ...fmParamRen } : null,
            paramCta: fmParamCta ? { ...fmParamCta } : null,
            tipoCta: fmTipoCta ? { ...fmTipoCta } : null,
        }, maxKey, (selGrupo?.db || false), true)
        setGrupos(prev => {
            const agrp = selGrupo ? prev.filter(p => p.key !== selGrupo.key) : prev.filter(p => true)
            const ngrp = [...agrp, sgrp]
            ngrp.sort(sortGrp)
            return ngrp
        })
        setSelGrupo(sgrp)
        setConfirmado(true)
    }

    const handleQuitarGrupo = () => {
        if (selGrupo) {
            const agrp = grupos.filter(p => p.key !== selGrupo.key)
            const sgrp = agrp.find(p => true) || null
            if (selGrupo.db)
                setNoGrupos(prev => [...prev, { grpCode: selGrupo.grpCode }])
            setGrupos(agrp)
            setSelGrupo(sgrp)
            setGuardado(false)
        }
    }

    const handleModReceptor = async () => {
        let err = null
        let data = null

        if (!receptor)
            err = {
                message: msgType.selectReceptor,
                oops: false
            }
        else if (!fmGrpName)
            err = {
                message: `${msgType.registroNoField} etiqueta de grupo.`,
                oops: false
            }
        else if (!confirmado)
            err = {
                message: msgType.noConfimGrupo,
                oops: false
            }

        if (err) {
            showNotify(err, notifyType.warning)
            return
        }

        setLoadModReceptor(true)

        const grpName = fmGrpName.trim()
        const addGrupos = grupos.length > 0 ? grupos.filter(p => p.edit).map(p => ({
            grpCode: p.grpCode,
            grpItem: p.grpItem,
            paramRen: p.paramRen,
            paramCta: p.paramCta,
            tipoCta: p.tipoCta,
            newEdit: !p.db
        })) : null
        const remGrupos = noGrupos.length > 0 ? noGrupos.map(p => ({ grpCode: p.grpCode })) : null

        const url = rctGrupoUrl(receptor.id)
        const body = { nombre: grpName, addGrupos, remGrupos }
        const [data_api, err_api] = await axiosTnaPut(url, body)
        data = data_api
        err = err_api

        if (data) {
            showNotify({
                message: `${msgType.receptorModificar} ${selReceptor.ruc}`,
                oops: false
            }, notifyType.success)
            setGuardado(true)
            const agrp = grupos.map(p => ({ ...p, db: true, edit: false }))
            const sgrp = agrp.find(p => p.key === selGrupo?.key) || null
            setNoGrupos([])
            setGrupos(agrp)
            setSelGrupo(sgrp)
            //setReceptor(await refreshRct(selReceptor.ruc))
        }
        if (err)
            showNotify(err, notifyType.error)

        setLoadModReceptor(false)
        setLastAccess()
    }

    return (
        <div className='flex flex-col ant-common-titania'>
            {
                openModalGrp &&
                <Modal
                    open={openModalGrp}
                    title={"Buscar grupo"}
                    onOk={() => setOpenModalGrp(false)}
                    onCancel={() => setOpenModalGrp(false)}
                    footer={[]}
                    width={700}>
                    <ListaGrp grupos={grupos} setGrupo={(grp) => { grp && handleChangeGrupo(grp.key, grupos) }} />
                </Modal>
            }
            {
                openModalPren &&
                <Modal
                    open={openModalPren}
                    title={`Configuración de rendición (${selGrupo?.grpCode})`}
                    onOk={() => setOpenModalPren(false)}
                    onCancel={() => setOpenModalPren(false)}
                    footer={[]}
                    width={400}>
                    <EditRctPren paramRen={fmParamRen} setParamRen={param => { param && handleChangePren(selGrupo, param) }} />
                </Modal>
            }
            {
                openModalPcta &&
                <Modal
                    open={openModalPcta}
                    title={`Parámetro de cuenta (${selGrupo?.grpCode})`}
                    onOk={() => setOpenModalPcta(false)}
                    onCancel={() => setOpenModalPcta(false)}
                    footer={[]}
                    width={400}>
                    <EditRctPcta paramCta={fmParamCta} setParamCta={param => { param && handleChangePcta(selGrupo, param) }} />
                </Modal>
            }
            {
                openModalTcta &&
                <Modal
                    open={openModalTcta}
                    title={`Tipo de cuenta (${selGrupo?.grpCode})`}
                    onOk={() => setOpenModalTcta(false)}
                    onCancel={() => setOpenModalTcta(false)}
                    footer={[]}
                    width={475}>
                    <EditRctTcta tipoCta={fmTipoCta} tiposTrib={tiposTrib} tiposDoc={tiposDoc} monedas={monedas} setTipoCta={tipo => { tipo && handleChangeTcta(selGrupo, tipo) }} />
                </Modal>
            }
            {
                loadPage &&
                <div className='flex mt-2 w-full justify-center'>
                    <Spin tip='Loading....' size='large' />
                </div>
            }
            {
                !loadPage &&
                <div className='flex items-center justify-center space-x-2'>
                    <span className='text-gray-600 text-xs'>{'Configuración de grupo'}</span>
                    {
                        !guardado &&
                        <span className='text-red-500 text-[0.6rem]'>[ Sin guardar ]</span>
                    }
                </div>
            }
            <div className='ant-form-titania'>
                <Form
                    className='mt-3'
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeDta}
                    disabled={loadData}
                    hidden={loadPage}
                    autoComplete="off"
                    form={formDta}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="grpName"
                                label="Etiqueta de grupo"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese etiqueta del grupo'
                                    size='middle'
                                    maxLength={50}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            {
                !loadPage &&
                <div className='mt-1'>
                    <div className='mb-1'>
                        <span>Lista de grupos</span>
                    </div>
                    <div className='flex space-x-2'>
                        <Select placeholder='Nuevo grupo' style={{ width: 280 }} value={selGrupo?.key}
                            onChange={(v) => handleChangeGrupo(v, grupos)} disabled={loadData} allowClear>
                            {
                                grupos.map(({ key, label }) => (
                                    <Select.Option key={key} value={key}>{label}</Select.Option>
                                ))
                            }
                        </Select>
                        {
                            (receptor && grupos.length > 0 && confirmado) &&
                            <Tooltip title="Buscar grupo" placement='top'>
                                <button onClick={() => setOpenModalGrp(true)} className='flex items-center'>
                                    <SearchOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            !selGrupo &&
                            <Tooltip title="Limpiar grupo" placement='top'>
                                <button onClick={() => setClearGrp(prev => !prev)} className='flex items-center'>
                                    <ClearOutlined className='text-gray-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (!selGrupo && !confirmado) &&
                            <Tooltip title="Nuevo grupo" placement='top'>
                                <button onClick={handleAgregarGrupo} className='flex items-center'>
                                    <PlusCircleOutlined className='text-green-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (selGrupo && !confirmado) &&
                            <Tooltip title="Modificar grupo" placement='top'>
                                <button onClick={handleAgregarGrupo} className='flex items-center'>
                                    <CheckCircleOutlined className='text-blue-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            (selGrupo && confirmado) &&
                            <Tooltip title="Quitar grupo" placement='top'>
                                <button onClick={handleQuitarGrupo} className='flex items-center'>
                                    <MinusCircleOutlined className='text-red-700' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                        {
                            !confirmado &&
                            <div className='flex items-center justify-center'>
                                <span className='text-yellow-700 text-[0.6rem]'>[ Sin confirmar ]</span>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className='ant-form-titania'>
                <Form
                    className='mt-4'
                    layout="vertical" requiredMark
                    onValuesChange={onValuesChangeGrp}
                    disabled={loadData}
                    hidden={loadPage}
                    autoComplete="off"
                    form={formGrp}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="grpCode"
                                label="Código de grupo"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese código'
                                    size='middle'
                                    maxLength={10}
                                    readOnly={selGrupo?.db || false}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="grpItem"
                                label="Nombre de grupo"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder='Ingrese nombre de grupo'
                                    size='middle'
                                    maxLength={100}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            {
                (!loadPage && selGrupo && confirmado) &&
                <>
                    <div className='flex space-x-2 mt-1 items-center'>
                        <span className='text-blue-600 text-xs'>{'Configuración de rendición'}</span>
                        <button onClick={() => setOpenModalPren(true)} className='flex items-center'>
                            <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                        </button>
                    </div>
                    <div className='flex space-x-2 mt-1 items-center'>
                        <span className='text-blue-600 text-xs'>{'Parámetro de cuenta'}</span>
                        <button onClick={() => setOpenModalPcta(true)} className='flex items-center'>
                            <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                        </button>
                    </div>
                    <div className='flex space-x-2 mt-1 items-center'>
                        <span className='text-blue-600 text-xs'>{'Tipo de cuenta'}</span>
                        <button onClick={() => setOpenModalTcta(true)} className='flex items-center'>
                            <ArrowRightOutlined className='text-indigo-700' style={{ fontSize: '0.9rem' }} />
                        </button>
                        {
                            fmTipoCta &&
                            <Tooltip title="Quitar configuración de cuenta" placement='top'>
                                <button onClick={() => handleChangeTcta(selGrupo, null)} className='flex items-center'>
                                    <DeleteFilled className='text-red-400' style={{ fontSize: '0.9rem' }} />
                                </button>
                            </Tooltip>
                        }
                    </div>
                </>
            }
            {
                !loadPage &&
                <div className="flex flex-row justify-end space-x-3 mx-1 mt-4">
                    <div className="flex items-center text-sm text-indigo-700">
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?${!guardado ? ' Configuración sin guardar.' : ''}`}
                            onConfirm={() => setReload(prev => !prev)}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Recargar configuración" placement='top'>
                                    <ReloadOutlined className="mr-1.5 text-green-600" aria-hidden="true" />
                                </Tooltip>
                            </div>
                        </Popconfirm>
                        <Popconfirm
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={`¿Confirma la operación?`}
                            onConfirm={handleModReceptor}
                            placement="left"
                            okText="Sí"
                            cancelText="No"
                            disabled={loadData}
                        >
                            <div className='flex items-center cursor-pointer'>
                                <Tooltip title="Guardar config. de grupo" placement='top'>
                                    <SaveOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                                </Tooltip>
                                Guardar
                            </div>
                        </Popconfirm>
                    </div>
                </div>
            }
        </div>
    )
}
