import { SearchOutlined, ZoomOutOutlined, QuestionCircleOutlined, DeleteFilled, SyncOutlined, DeploymentUnitOutlined, UserOutlined } from '@ant-design/icons'
import { ShieldCheckIcon } from '@heroicons/react/20/solid'
import { ClipboardIcon } from '@heroicons/react/24/outline'
import { Button, DatePicker, Input, message, Pagination, Popconfirm, Select, Spin, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { accEliminarUrl, accGetBPagUrl, accRegistrarUrl, rolGetUserAllUrl, segConnUrl, storageRUCUrl, usuGetNumUrl, usuRcptEmsrUrl } from '../../../api/apiUrl'
import { AppFooter } from '../../../components/AppFooter'
import { NotifyGreen } from '../../../components/Msg/NotifyGreen'
import { NotifyRed } from '../../../components/Msg/NotifyRed'
import { NotifyYellow } from '../../../components/Msg/NotifyYellow'
import { SelRcptEmsr } from '../../../components/Panel/SelRcptEmsr'
import { useAxiosCto } from '../../../hooks/useAxiosCto'
import { useAxiosTna } from '../../../hooks/useAxiosTna'
import { useForm } from '../../../hooks/useForm'
import { msgType } from '../../../types/msgType'
import { notifyType } from '../../../types/notifyType'
import { formatTime } from '../../../utils/formatUtil'
import { setLastAccess } from '../../../utils/lstorageUtil'

import './CodigoAcceso.css'

export const CodigoAcceso = ({ page: { code: resource } }) => {
  const dvFechaIni = moment().startOf('month')
  const dvFechaFin = moment().endOf('month')

  const { axiosTnaGet, axiosTnaPost, axiosTnaDelete } = useAxiosTna()
  const { axiosCtoGet } = useAxiosCto()
  const notifyRedRef = useRef()
  const notifyGreenRef = useRef()
  const notifyYellowRef = useRef()
  const [disablePage, setDisablePage] = useState(false)
  const [loadPage, setLoadPage] = useState(true)
  const [loadTokenConn, setLoadTokenConn] = useState(true)
  const [loadRcptEmsr, setLoadRcptEmsr] = useState(true)
  const [loadRol, setLoadRol] = useState(true)
  const [loadValidarRUC, setLoadValidarRUC] = useState(false)
  const [loadValidarUsuario, setLoadValidarUsuario] = useState(false)
  const [loadGenerar, setLoadGenerar] = useState(false)
  const [loadBusqAC, setLoadBusqAC] = useState(false)
  const [rcpt, setRcpt] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedRcpt, setSelectedRcpt] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [roles, setRoles] = useState([])
  const [selectedRol, setSelectedRol] = useState(null)
  const [{ emsrRUC, usuarioID }, handleInputChange, resetInputData] = useForm({ emsrRUC: '', usuarioID: '' })
  const [emsrNomRazSoc, setEmsrNomRazSoc] = useState('')
  const [accessCode, setAccessCode] = useState('')
  const [foFechaIni, setFoFechaIni] = useState(dvFechaIni)
  const [foFechaFin, setFoFechaFin] = useState(dvFechaFin)
  const [foAccess, setFoAccess] = useState(null)
  const [foRol, setFoRol] = useState(null)
  const [foEmisorID, setFoEmisorID] = useState(null)
  const [foSave, setFoSave] = useState(null)
  const [codes, setCodes] = useState([])
  const [acTotalDocuments, setAcTotalDocuments] = useState(0)
  const [acPageSize, setAcPageSize] = useState(10)
  const [acCurrentPage, setAcCurrentPage] = useState(1)
  const [currentID, setCurrentID] = useState(null)

  useEffect(() => {
    setLoadPage(loadTokenConn || loadRcptEmsr || loadRol)
  }, [loadTokenConn, loadRcptEmsr, loadRol])

  useEffect(() => {
    setDisablePage(loadBusqAC || currentID || loadValidarRUC || loadValidarUsuario || loadGenerar)
  }, [loadBusqAC, currentID, loadValidarRUC, loadValidarUsuario, loadGenerar])

  useEffect(() => {
    let isMounted = true

    const tokenConn = async () => {
      const url = segConnUrl()
      await axiosTnaGet(url)
      isMounted && setLoadTokenConn(false)
    }

    tokenConn()

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let isMounted = true

    const rcptEmsr = async () => {
      const url = usuRcptEmsrUrl(resource)
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setRcpt(data.rcpt)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadRcptEmsr(false)
    }

    const rolUserAll = async () => {
      const url = rolGetUserAllUrl()
      const [data, err] = await axiosTnaGet(url)

      if (data)
        isMounted && setRoles(data.detalle)
      if (err)
        notifyRedRef.current.handleOpen(err, notifyType.error)

      setLoadRol(false)
    }

    if (!loadTokenConn) {
      rcptEmsr()
      rolUserAll()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line
  }, [loadTokenConn])

  const sortAcc = (a, b) => {
    if (a.regDate > b.regDate)
      return 1
    else if (a.regDate < b.regDate)
      return -1
    else if (a.code > b.code)
      return 1
    else
      return -1
  }

  const getAcc = (cd, grps) => ({ ...cd, key: cd.id, grupo: { ...(cd.grupo), grpItem: grps?.find(p => p.grpCode === cd.grupo.grpCode)?.grpItem } })

  const clearFiltroAC = () => {
    setFoSave(null)
    setAccessCode('')
    setCodes([])
    setAcTotalDocuments(0)
  }

  const handleClickCopyCode = (code) => {
    navigator.clipboard.writeText(code)
    message.info(msgType.accCodeMsgCode, 1);
  }

  const handleSelectRcpt = useCallback((person) => {
    setSelectedRcpt(person)
    let grupos = []
    if (person && person.grupo.length > 0)
      grupos = person.grupo.map(p => ({ ...p, ruc: p.grpCode, name: p.grpItem }))
    setGroups(grupos)
    setSelectedGroup(null)
    setRoles(prev =>
      prev.map(p => ({
        ...p,
        show: (!p.ruc || p.ruc === person.ruc) && (!p.module || !person.modules || person.modules.find(q => q === p.module))
      })))
    setSelectedRol(null)
    setFoRol(null)
    clearFiltroAC()
  }, [setSelectedRcpt])

  const handleSelectGroup = useCallback((person) => {
    setSelectedGroup(person)
    clearFiltroAC()
  }, [setSelectedGroup])

  const handleChangeGroup = (value) => {
    const grupo = groups.find(p => p.ruc === value) || null
    setSelectedGroup(grupo)
    setAccessCode('')
  }

  const handleChangeRol = (value) => {
    const rol = roles.find(p => p.id === value) || null
    setSelectedRol(rol)
    resetInputData()
    setEmsrNomRazSoc('')
    setAccessCode('')
  }

  const handleValidateRUC = async () => {
    let err = null
    let data = null

    if (!emsrRUC)
      err = {
        message: msgType.accCodeNoRUC,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadValidarRUC(true)

    const url = storageRUCUrl(emsrRUC)
    const [data_api, err_api] = await axiosCtoGet(url)
    data = data_api
    err = err_api

    if (data)
      setEmsrNomRazSoc(data.nomRazSoc)
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadValidarRUC(false)
    setLastAccess()
  }

  const handleValidarUsuario = async () => {
    let err = null
    let data = null

    if (!usuarioID)
      err = {
        message: msgType.accCodeNoUsuario,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadValidarUsuario(true)

    const url = usuGetNumUrl(usuarioID)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      if (data.success)
        message.info(msgType.accCodeMsgUser1, 1)
      else
        message.warning(msgType.accCodeMsgUser2, 1)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadValidarUsuario(false)
    setLastAccess()
  }

  const handleBuscarACFecha = async () => {
    let err = null
    let data = null

    if (!selectedRcpt)
      err = {
        message: msgType.accCodeNoRcpt,
        oops: false
      }
    else if (!selectedGroup && !selectedRcpt.grpAll)
      err = {
        message: msgType.accCodeNoGroup,
        oops: false
      }
    else if (!foAccess && (!foFechaIni || !foFechaFin))
      err = {
        message: msgType.selectFecha,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadBusqAC(true)

    const grpCode = selectedRcpt.grpAll ? '' : selectedGroup.ruc
    const fechaIni = foFechaIni ? (foFechaIni.format().split('T')[0] + 'T00:00:00') : ''
    const fechaFin = foFechaFin ? (foFechaFin.format().split('T')[0] + 'T00:00:00') : ''
    const access = foAccess || ''
    const rol = foRol || ''
    const emisorID = foEmisorID || ''

    const url = accGetBPagUrl(selectedRcpt.ruc, grpCode, fechaIni, fechaFin, access, rol, emisorID, 1, acPageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: cds } = data
      cds.sort(sortAcc)
      setCodes(cds.map(cd => getAcc(cd, selectedRcpt.grupo)))
      setAcTotalDocuments(totalDocuments)
      setAcCurrentPage(1)
      setFoSave({
        svSelectedRcpt: selectedRcpt,
        svGrpCode: grpCode,
        svFechaIni: fechaIni,
        svFechaFin: fechaFin,
        svAccess: access,
        svRol: rol,
        svEmisorID: emisorID,
      })
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqAC(false)
    setLastAccess()
  }

  const handleChangePagAC = async (page, pageSize) => {
    if (!foSave)
      return

    const { svSelectedRcpt, svGrpCode, svFechaIni, svFechaFin, svAccess, svRol, svEmisorID } = foSave

    let err = null
    let data = null

    setLoadBusqAC(true)

    const url = accGetBPagUrl(svSelectedRcpt.ruc, svGrpCode, svFechaIni, svFechaFin, svAccess, svRol, svEmisorID, page, pageSize)
    const [data_api, err_api] = await axiosTnaGet(url)
    data = data_api
    err = err_api

    if (data) {
      const { totalDocuments, data: cds } = data
      cds.sort(sortAcc)
      setCodes(cds.map(cd => getAcc(cd, svSelectedRcpt.grupo)))
      setAcTotalDocuments(totalDocuments)
      setAcCurrentPage(page)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadBusqAC(false)
    setLastAccess()
  }

  const handleBuildCode = async () => {
    let err = null
    let data = null

    if (!selectedRcpt)
      err = {
        message: msgType.accCodeNoRcpt,
        oops: false
      }
    else if (!selectedGroup && !selectedRcpt.grpAll)
      err = {
        message: msgType.accCodeNoGroup,
        oops: false
      }
    else if (!selectedRol)
      err = {
        message: msgType.accCodeNoRol,
        oops: false
      }
    else if (!selectedRol.intExt && !emsrNomRazSoc)
      err = {
        message: msgType.accCodeNoRUC,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setLoadGenerar(true)
    setAccessCode('')

    const url = accRegistrarUrl()
    const body = {
      rol: selectedRol.rol, userID: usuarioID, rcptNumDoc: selectedRcpt.ruc, grupo: selectedGroup?.ruc,
      emisor: !selectedRol.intExt ? { numDoc: emsrRUC, nomRazSoc: emsrNomRazSoc } : null
    }
    const [data_api, err_api] = await axiosTnaPost(url, body)
    data = data_api
    err = err_api

    if (data) {
      setAccessCode(data.code)
      notifyGreenRef.current.handleOpen({
        message: msgType.accCodeRegOK,
        oops: false
      }, notifyType.success)
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setLoadGenerar(false)
    setLastAccess()
  }

  const handleDeleteCode = async (record) => {
    let err = null
    let data = null

    if (record.bloqueado)
      err = {
        message: `${msgType.accCodeBloqueado}`,
        oops: false
      }

    if (err) {
      notifyYellowRef.current.handleOpen(err, notifyType.warning)
      return
    }

    setCurrentID(record.id)

    const url = accEliminarUrl(record.id)
    const [data_api, err_api] = await axiosTnaDelete(url)
    data = data_api
    err = err_api

    if (data) {
      notifyGreenRef.current.handleOpen({
        message: msgType.accCodeDelOK,
        oops: false
      }, notifyType.success)
      setCodes(prev => {
        const dataO = prev.filter(ac => ac.id !== record.id)
        dataO.sort(sortAcc)
        return dataO
      })
    }
    if (err)
      notifyRedRef.current.handleOpen(err, notifyType.error)

    setCurrentID(null)
    setLastAccess()
  }

  const acColumns = [
    {
      title: 'Código',
      dataIndex: 'code',
      width: 110,
    },
    {
      title: 'Usuario',
      dataIndex: 'access',
      width: 250,
      render: (_, record) =>
        <div className='flex justify-start'>
          {
            record.registrado &&
            <div className='flex items-center mr-1'>
              <Tooltip title="Usuario registrado" placement="right">
                <button className='flex'>
                  <UserOutlined className=' text-blue-700' />
                </button>
              </Tooltip>
            </div>
          }
          <div className='flex items-center'>
            {
              record.code !== record.access ?
                <span>{record.access}</span> :
                <span></span>
            }
          </div>
        </div>
    },
    {
      title: 'Rol',
      dataIndex: 'rol',
      width: 100,
    },
    {
      title: 'Grupo',
      dataIndex: 'grupo',
      width: 130,
      render: (_, record) => !record.grupo.all ?
        <span>{`${record.grupo?.grpItem ?? record.grupo.grpCode}`}</span> :
        <span className='text-red-700'>ALL</span>,
    },
    {
      title: 'Emisor',
      dataIndex: 'emisor',
      render: (_, record) => !record.emisor.none ?
        <span>{`${record.emisor.ruc} - ${record.emisor.name}`}</span> :
        <span className='text-red-700'>NONE</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'regDate',
      width: 145,
      render: (_, record) => <span>{formatTime(record.regDate)}</span>
    },
    {
      title: '',
      dataIndex: 'delete',
      width: 40,
      render: (_, record) => <div className='flex items-center justify-center'>
        {
          record.id !== currentID && !record.bloqueado &&
          <>
            <Tooltip title="Eliminar acceso" placement="top">
              <Popconfirm placement="left"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title={`¿Confirma la operación?`}
                onConfirm={() => handleDeleteCode(record)}
                okText="Sí"
                cancelText="No"
              >
                <button>
                  <DeleteFilled className='text-red-400' />
                </button>
              </Popconfirm>
            </Tooltip>
          </>
        }
        {
          record.id === currentID &&
          <Spin size='small' className='mr-1' />
        }
      </div>
    },
  ]

  return (
    <div className="bg-white flex flex-col flex-grow">
      <NotifyRed ref={notifyRedRef} />
      <NotifyGreen ref={notifyGreenRef} />
      <NotifyYellow ref={notifyYellowRef} />
      {
        loadPage ?
          <div className='my-5 flex justify-center'>
            <Spin tip='Loading....' size='large' />
          </div> :
          <div className='flex-grow'>
            <div className='bg-gray-200 flex justify-start items-center space-x-4 px-3 py-3 ant-common-titania'>
              <SelRcptEmsr comboLabel="RECEPTOR" people={rcpt} setPerson={handleSelectRcpt} flex_basis='basis-96' />
              {
                !(selectedRcpt?.grpAll ?? true) &&
                <SelRcptEmsr comboLabel={selectedRcpt?.grpName ?? 'Grupo'} people={groups} setPerson={handleSelectGroup} flex_basis='basis-72' />
              }
            </div>

            <div className='p-3 flex flex-col ant-common-titania'>
              <div className="flex flex-row flex-wrap space-x-3 mb-3">
                <div className="flex items-center text-sm text-indigo-700">
                  {
                    !disablePage ?
                      <Popconfirm
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        title={`¿Confirma la operación?`}
                        onConfirm={handleBuildCode}
                        placement="right"
                        okText="Sí"
                        cancelText="No"
                      >
                        <div className='flex items-center cursor-pointer'>
                          <DeploymentUnitOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                          {`Generar`}
                        </div>
                      </Popconfirm> :
                      <div className='flex items-center cursor-not-allowed'>
                        <DeploymentUnitOutlined className="mr-1.5 text-gray-600" aria-hidden="true" />
                        {
                          loadGenerar &&
                          <svg aria-hidden="true" role="status" className="inline mr-2 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#00008B" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                          </svg>
                        }
                        {`Generar`}
                      </div>
                  }
                </div>
                {
                  (selectedRcpt?.grpAll ?? false) &&
                  <Select placeholder={selectedRcpt?.grpName ?? ''} value={selectedGroup?.ruc} allowClear style={{ width: 175 }} onChange={(v) => handleChangeGroup(v || null)}>
                    {
                      groups.map(({ ruc, name }) => (
                        <Select.Option key={ruc} value={ruc}>{name}</Select.Option>
                      ))
                    }
                  </Select>
                }
                <Select placeholder='Rol' value={selectedRol?.id} allowClear style={{ width: 140 }} onChange={(v) => handleChangeRol(v || null)}>
                  {
                    roles.filter(p => p.show).map(({ id, rol }) => (
                      <Select.Option key={id} value={id}>{rol}</Select.Option>
                    ))
                  }
                </Select>
                {
                  selectedRol && !selectedRol.intExt &&
                  <div className="flex">
                    <span className="inline-flex items-center p-1 text-xs rounded-l rounded-r-none border border-r-0 border-gray-300 bg-gray-100 px-3 text-gray-500">
                      RUC
                    </span>
                    <input
                      type="text"
                      name="emsrRUC"
                      id="emsrRUC"
                      autoComplete='emsrRUC'
                      className="block text-xs pl-1 mr-1 w-28 rounded-l-none rounded-r border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                      value={emsrRUC}
                      onChange={handleInputChange}
                      readOnly={emsrNomRazSoc || disablePage}
                    />
                    {
                      !loadValidarRUC &&
                      <button
                        className="flex items-center rounded-md bg-white font-medium text-indigo-600"
                        disabled={disablePage}
                        onClick={handleValidateRUC}
                      >
                        <Tooltip title="Validar RUC">
                          <ShieldCheckIcon className="h-5 w-5" aria-hidden="true" />
                        </Tooltip>
                      </button>
                    }
                    {
                      loadValidarRUC &&
                      <div className='flex items-center text-indigo-600'>
                        <SyncOutlined spin />
                      </div>
                    }
                    <span className="inline-flex items-center w-72 p-1 ml-1 text-xs rounded border border-gray-300 bg-gray-100 px-3 text-gray-500">
                      {emsrNomRazSoc}
                    </span>
                  </div>
                }
                <div className='flex'>
                  <input
                    type="text"
                    name="usuarioID"
                    id="usuarioID"
                    autoComplete='usuarioID'
                    className="block pl-2  mr-1  border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    value={usuarioID}
                    onChange={handleInputChange}
                    placeholder="Usuario" style={{ width: 240 }}
                  />
                  {
                    !loadValidarUsuario &&
                    <button
                      className="flex items-center rounded-md bg-white font-medium text-indigo-600"
                      disabled={disablePage}
                      onClick={handleValidarUsuario}
                    >
                      <Tooltip title="Validar usuario">
                        <ShieldCheckIcon className="h-5 w-5" aria-hidden="true" />
                      </Tooltip>
                    </button>
                  }
                  {
                    loadValidarUsuario &&
                    <div className='flex items-center text-indigo-600'>
                      <SyncOutlined spin />
                    </div>
                  }
                </div>
                {
                  accessCode &&
                  <div className='flex items-center'>
                    <span className="inline-flex items-center w-24 p-1 mr-0.5 text-xs rounded border border-gray-300 bg-gray-100 px-3 text-gray-500">
                      {accessCode}
                    </span>
                    <button onClick={() => handleClickCopyCode(accessCode)}>
                      <Tooltip title="Copiar código" placement='right'>
                        <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
                      </Tooltip>
                    </button>
                  </div>
                }
              </div>

              <p className="text-xs text-gray-700 mb-2">
                Busca un código de acceso usando los filtros de fecha de registro.
              </p>
              <div className='flex items-center space-x-2'>
                <DatePicker placeholder='Fecha inicio' value={foFechaIni} onChange={(d,) => setFoFechaIni(d ?? null)} />
                <DatePicker placeholder='Fecha fin' value={foFechaFin} onChange={(d,) => setFoFechaFin(d ?? null)} />
                <div className='hidden lg:flex lg:items-center lg:space-x-2'>
                  <Input placeholder="Usuario" style={{ width: 240 }} onChange={(e) => setFoAccess(e.target.value)} />
                  <Select placeholder='Rol' value={foRol?.id} allowClear style={{ width: 140 }} onChange={(v) => setFoRol(v || null)}>
                    {
                      roles.filter(p => p.show).map(({ id, rol }) => (
                        <Select.Option key={id} value={rol}>{rol}</Select.Option>
                      ))
                    }
                  </Select>
                  <Input placeholder="RUC Emisor" style={{ width: 130 }} onChange={(e) => setFoEmisorID(e.target.value)} />
                </div>
                {
                  !disablePage ?
                    <Tooltip title="Buscar código de acceso">
                      <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleBuscarACFecha} />
                    </Tooltip> :
                    <Button type="primary" shape="circle" icon={<ZoomOutOutlined />} />
                }
              </div>

              <div className='lg:max-w-6xl'>
                {
                  loadBusqAC ?
                    <div className='my-5 flex justify-center'>
                      <Spin tip='Loading....' size='large' />
                    </div> :
                    <>
                      <div className='mt-3 ant-table-titania ant-table-rowsel-titania ant-table-cfcodacc'>
                        <Table
                          columns={acColumns}
                          dataSource={codes}
                          pagination={false}
                          scroll={{ y: 400 }}
                          bordered
                          size='small'
                        />
                      </div>
                      <div className='mt-2 flex justify-end ant-pagination-titania'>
                        <Pagination
                          defaultCurrent={acCurrentPage}
                          total={acTotalDocuments}
                          showSizeChanger
                          showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} accesos`}
                          defaultPageSize={acPageSize}
                          onChange={handleChangePagAC}
                          onShowSizeChange={(_, size) => setAcPageSize(size)}
                          disabled={disablePage} />
                      </div>
                    </>
                }
              </div>
            </div>
          </div>
      }
      <div className='w-full'>
        <AppFooter />
      </div>
    </div>
  )
}
